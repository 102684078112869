import React from "react";

import { Row } from "reactstrap";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import AdminBranchesList from "../components/AdminBranchesList";

const AdminBranches = () => {
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <AdminBranchesList />
        </Row>
      </div>
    </>
  );
};

export default AdminBranches;
