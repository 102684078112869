import React from "react";
import {useHistory} from "react-router-dom";

import "./ImpersonationBanner.css";

const ImpersonationBanner = ({name, loggedInUser}) => {
  const history = useHistory();

  const stopImpersonation = () => {
    if (loggedInUser.authTier === "Retail Admin") {
      history.push({
        pathname: "/retail-admin/fast-facts",
        state: {
          initialEmail: loggedInUser.email,
          initialName: loggedInUser.name,
          photoURL: loggedInUser.photoURL,
        },
      });
    } else if (loggedInUser.authTier === "Correspondent Admin") {
      history.push({
        pathname: "/correspondent-admin/fast-facts",
        state: {
          initialEmail: loggedInUser.email,
          initialName: loggedInUser.name,
          photoURL: loggedInUser.photoURL,
        },
      });
    } else if (loggedInUser.authTier === "Regional Manager") {
      history.push({
        pathname: "/regional-manager/fast-facts",
        state: {
          initialEmail: loggedInUser.email,
          initialName: loggedInUser.name,
          photoURL: loggedInUser.photoURL,
        },
      });
    } else if (loggedInUser.authTier === "Branch Manager") {
      history.push({
        pathname: "/branch-manager/fast-facts",
        state: {
          initialEmail: loggedInUser.email,
          initialName: loggedInUser.name,
          photoURL: loggedInUser.photoURL,
        },
      });
    }
  };

  return (
    <div className="impersonation-banner">
      {/* eslint-disable-next-line */}
      <a href="#" class="close-spin" onClick={() => stopImpersonation()}></a>

      <p>{`Impersonating ${name}`}</p>
    </div>
  );
};

export default ImpersonationBanner;
