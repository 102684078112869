import React, {useState, useEffect} from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const AdminTopLoanOfficersCard = () => {
  const history = useHistory();

  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getRankings?timeframe=ytd`,
        );

        const json = await response.json();

        setRankings(json.ytdSalesTotals);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRankings();
  }, []);

  return (
    <Col md="12">
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          Top 3 Loan Officers YTD
        </CardHeader>
        <CardBody>
          <Row>
            {rankings.slice(0, 3).map((loanOfficer) => (
              <Col md="4" key={loanOfficer.name}>
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">{loanOfficer.name}</h3>

                    <h6 className="stats-title">
                      {`${loanOfficer.count} Loans Closed`}
                    </h6>
                    <h6 className="stats-title">
                      {`(${loanOfficer.amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })})`}
                    </h6>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/retail-admin/sales/encompass-metrics");
            }}
          >
            See More Rankings
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default AdminTopLoanOfficersCard;
