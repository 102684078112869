import React, {useState, useEffect} from "react";
import {Row} from "reactstrap";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import AdminEncompassMetricsHighlights from "../components/AdminEncompassMetricsHighlights";
import AdminMyPipelineHighlights from "../components/AdminMyPipelineHighlights";
import AdminEncompassMetricsCurrentMonthHighlights from "../components/AdminEncompassMetricsCurrentMonthHighlights";
import AdminEncompassMetricsNextMonthHighlights from "../components/AdminEncompassMetricsNextMonthHighlights";
import AdminEncompassMetricsYTDMetrics from "../components/AdminEncompassMetricsYTDMetrics";
import AdminEncompassMetricsTopZips from "../components/AdminEncompassMetricsTopZips";
import AdminEncompassMetricsRankingsTable from "../components/AdminEncompassMetricsRankingsTable";

const AdminEncompassMetrics = ({currentYearData}) => {
  const [timeFrame, setTimeFrame] = useState(currentYearData.ytdMetrics);

  const [timeFrameLabel, setTimeFrameLabel] = useState("YTD");

  const [allPipelineLoans, setAllPipelineLoans] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeFrame(currentYearData.ytdMetrics);
    setTimeFrameLabel("YTD");
  }, [currentYearData.ytdMetrics]);

  useEffect(() => {
    const fetchAllPipelineLoans = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyPipelineData`,
        );
        const json = await response.json();
        setAllPipelineLoans(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAllPipelineLoans();
  }, []);

  const handleTimeFrameChange = async (year) => {
    try {
      const response = await fetch(
        `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${year}`,
      );
      const json = await response.json();
      setTimeFrame(json.ytdMetrics);
      setTimeFrameLabel(year.toString());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <AdminEncompassMetricsHighlights
            timeFrame={timeFrame}
            timeFrameLabel={timeFrameLabel}
            handleTimeFrameChange={handleTimeFrameChange}
          />
        </Row>

        <Row>
          <AdminMyPipelineHighlights
            allPipelineLoans={allPipelineLoans}
            loading={loading}
          />
        </Row>

        <Row>
          <AdminEncompassMetricsCurrentMonthHighlights
            currentYearData={currentYearData}
          />
          <AdminEncompassMetricsNextMonthHighlights
            allPipelineLoans={allPipelineLoans}
          />
        </Row>

        <Row>
          <AdminEncompassMetricsYTDMetrics
            timeFrame={timeFrame}
            timeFrameLabel={timeFrameLabel}
          />
        </Row>

        <Row>
          <AdminEncompassMetricsTopZips currentYearData={currentYearData} />
        </Row>

        <Row>
          <AdminEncompassMetricsRankingsTable />
        </Row>
      </div>
    </>
  );
};

export default AdminEncompassMetrics;
