import React from "react";

import {Row} from "reactstrap";
import CorrespondentAdminFastFactsChart from "../components/CorrespondentAdminFastFactsChart";
import CorrespondentAdminAnnualSalesHighlights from "../components/CorrespondentAdminAnnualSalesHighlights";

import CorrespondentAdminPreviousMonthSalesHighlights from "../components/CorrespondentAdminPreviousMonthSalesHighlights";
import CorrespondentAdminCurrentMonthSalesHighlights from "../components/CorrespondentAdminCurrentMonthSalesHighlights";

const CorrespondentAdminFastFacts = ({currentYearData}) => {
  return (
    <>
      <CorrespondentAdminFastFactsChart currentYearData={currentYearData} />
      <div className="content">
        <Row>
          <CorrespondentAdminAnnualSalesHighlights
            currentYearData={currentYearData}
          />
        </Row>
        <Row>
          <CorrespondentAdminPreviousMonthSalesHighlights
            currentYearData={currentYearData}
          />
          <CorrespondentAdminCurrentMonthSalesHighlights
            currentYearData={currentYearData}
          />
        </Row>
      </div>
    </>
  );
};

export default CorrespondentAdminFastFacts;
