import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupText,
} from "reactstrap";

const LoanOfficerSalesGoalConfirmationModal = ({
  currentLoanOfficer,
  commissionGoal = 0,
  selfGeneratedCommissionPercent = 0,
  companyGeneratedCommissionPercent = 0,
  setModalOpen,
  modalOpen,
  additionalCommissions = [],
  productionGoal = 0,
  handleGoalSetChange,
  setCalculatorOpen,
}) => {
  const [
    fallBackSelfGeneratedCommissionPercent,
    setFallBackSelfGeneratedCommissionPercent,
  ] = useState(0.75);

  const [
    fallBackCompanyGeneratedCommissionPercent,
    setFallBackCompanyGeneratedCommissionPercent,
  ] = useState(0.5);

  const handleSelfGeneratedCommissionPercentChange = (value) => {
    if (value === undefined || value >= 0) {
      setFallBackSelfGeneratedCommissionPercent(value);
    }
  };

  const handleCompanyGeneratedCommissionPercentChange = (value) => {
    if (value === undefined || value >= 0) {
      setFallBackCompanyGeneratedCommissionPercent(value);
    }
  };

  const handleConfirm = async () => {
    const email = currentLoanOfficer.email;

    const calculatedProductionGoal =
      productionGoal === 0
        ? Number.parseInt(
            commissionGoal / (selfGeneratedCommissionPercent / 100)
          )
        : productionGoal;

    const calculatedCommissionGoal =
      commissionGoal === 0
        ? Number.parseInt(
            productionGoal * (fallBackSelfGeneratedCommissionPercent / 100)
          )
        : commissionGoal;

    const calculatedSelfGeneratedCommissionPercent =
      selfGeneratedCommissionPercent === 0
        ? fallBackSelfGeneratedCommissionPercent
        : selfGeneratedCommissionPercent;

    const calculatedCompanyGeneratedCommissionPercent =
      companyGeneratedCommissionPercent === 0
        ? fallBackCompanyGeneratedCommissionPercent
        : companyGeneratedCommissionPercent;

    const formattedAdditionalCommissions = additionalCommissions
      .filter((loanOfficer) => loanOfficer.commissionPercent > 0)
      .map((loanOfficer) => ({
        email: loanOfficer.email,
        commissionPercent: loanOfficer.commissionPercent,
        name: loanOfficer.name,
      }));

    console.log({
      email,
      calculatedCommissionGoal,
      calculatedSelfGeneratedCommissionPercent,
      calculatedCompanyGeneratedCommissionPercent,
      calculatedProductionGoal,
      formattedAdditionalCommissions,
    });

    try {
      await fetch(
        "https://us-central1-essexdashboard.cloudfunctions.net/setSalesGoal",
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify({
            email: email,
            commissionGoal: calculatedCommissionGoal,
            productionGoal: calculatedProductionGoal,
            selfGeneratedCommissionPercent:
              calculatedSelfGeneratedCommissionPercent,
            companyGeneratedCommissionPercent:
              calculatedCompanyGeneratedCommissionPercent,
            additionalCommissions: formattedAdditionalCommissions,
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
    handleGoalSetChange(
      calculatedCommissionGoal,
      calculatedSelfGeneratedCommissionPercent,
      calculatedCompanyGeneratedCommissionPercent,
      formattedAdditionalCommissions,
      calculatedProductionGoal
    );
    setModalOpen(false);
    setCalculatorOpen(false);
  };

  const renderAdditionalCommissions = () => {
    return additionalCommissions
      .filter((loanOfficer) => loanOfficer.commissionPercent > 0)
      .map((loanOfficer) => {
        return (
          <div
            style={{ marginTop: "8px" }}
          >{`${loanOfficer.name} at ${loanOfficer.commissionPercent}%`}</div>
        );
      });
  };
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Confirm Goal Submission</ModalHeader>
      <ModalBody>
        Please Confirm the following items below are accurate. Once an annual
        goal is set it cannot be modified until the following year.
        {productionGoal === 0 ? (
          <>
            <h6 style={{ marginTop: "8px" }}>Annual Commission Goal</h6>
            {Number.parseInt(commissionGoal).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            {` (Production Needed: ${Number.parseInt(
              commissionGoal / (selfGeneratedCommissionPercent / 100)
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })})`}
          </>
        ) : (
          <>
            <h6 style={{ marginTop: "8px" }}>Annual Production Goal</h6>
            {Number.parseInt(productionGoal).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            {` (Commission Estimated: ${Number.parseInt(
              productionGoal * (fallBackSelfGeneratedCommissionPercent / 100)
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })})`}
          </>
        )}
        {selfGeneratedCommissionPercent !== 0 ? (
          <>
            <h6 style={{ marginTop: "8px" }}>My Commission Percents</h6>
            {`Self Generated: ${selfGeneratedCommissionPercent}%`}
            <br />
            {`Company Generated: ${companyGeneratedCommissionPercent}%`}
          </>
        ) : (
          <div style={{ marginTop: "2vh" }}>
            Please enter your commission percents for future sales tracking.
            <div style={{ width: "75%", marginTop: "1vh" }}>
              <FormGroup>
                <Label for="self-percent-change">
                  Self Generated Commission Percent
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    step={0.01}
                    style={{
                      borderRadius: "5px",
                    }}
                    id="self-percent-change"
                    placeholder=""
                    value={fallBackSelfGeneratedCommissionPercent}
                    onChange={(e) =>
                      handleSelfGeneratedCommissionPercentChange(e.target.value)
                    }
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
                <Label for="company-percent-change">
                  Company Generated Commission Percent
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    step={0.01}
                    style={{
                      borderRadius: "5px",
                    }}
                    id="company-percent-change"
                    placeholder=""
                    value={fallBackCompanyGeneratedCommissionPercent}
                    onChange={(e) =>
                      handleCompanyGeneratedCommissionPercentChange(
                        e.target.value
                      )
                    }
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
              </FormGroup>
            </div>
          </div>
        )}
        {additionalCommissions.some(
          (loanOfficer) => loanOfficer.commissionPercent > 0
        ) && <h6 style={{ marginTop: "8px" }}>Additional Commissions</h6>}
        {renderAdditionalCommissions()}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
        <Button style={{ backgroundColor: "#061f54" }} onClick={handleConfirm}>
          Submit Goal
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LoanOfficerSalesGoalConfirmationModal;
