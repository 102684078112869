import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";

import AdminMonthlyLoanTypeCard from "../components/AdminMonthlyLoanTypeCard";
import AdminMonthlyLoanPurposeCard from "../components/AdminMonthlyLoanPurposeCard";
import AdminMonthlyLoanChannelCard from "./AdminMonthlyLoanChannelCard";

const AdminSalesComparisonCard = () => {
  const yearOptions = [
    { value: 2019, label: "2019" },
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
  ];

  const thisYear = new Date().getFullYear();
  const lastYear = thisYear - 1;

  const [leftPeriod, setLeftPeriod] = useState({
    currentYear: lastYear,
    currentMonth: 100,
  });
  const [rightPeriod, setRightPeriod] = useState({
    currentYear: thisYear,
    currentMonth: 100,
  });

  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("Loan Types");

  let leftMonthOptions =
    leftPeriod.currentYear === new Date().getFullYear()
      ? [
          { value: 100, label: "YTD" },
          { value: 0, label: "January" },
          { value: 1, label: "February" },
          { value: 2, label: "March" },
          { value: 3, label: "April" },
          { value: 4, label: "May" },
          { value: 5, label: "June" },
          { value: 6, label: "July" },
          { value: 7, label: "August" },
          { value: 8, label: "September" },
          { value: 9, label: "October" },
          { value: 10, label: "November" },
          { value: 11, label: "December" },
        ].slice(0, new Date().getMonth() + 2)
      : [
          { value: 100, label: "YTD" },
          { value: 0, label: "January" },
          { value: 1, label: "February" },
          { value: 2, label: "March" },
          { value: 3, label: "April" },
          { value: 4, label: "May" },
          { value: 5, label: "June" },
          { value: 6, label: "July" },
          { value: 7, label: "August" },
          { value: 8, label: "September" },
          { value: 9, label: "October" },
          { value: 10, label: "November" },
          { value: 11, label: "December" },
        ];

  let rightMonthOptions =
    rightPeriod.currentYear === new Date().getFullYear()
      ? [
          { value: 100, label: "YTD" },
          { value: 0, label: "January" },
          { value: 1, label: "February" },
          { value: 2, label: "March" },
          { value: 3, label: "April" },
          { value: 4, label: "May" },
          { value: 5, label: "June" },
          { value: 6, label: "July" },
          { value: 7, label: "August" },
          { value: 8, label: "September" },
          { value: 9, label: "October" },
          { value: 10, label: "November" },
          { value: 11, label: "December" },
        ].slice(0, new Date().getMonth() + 2)
      : [
          { value: 100, label: "YTD" },
          { value: 0, label: "January" },
          { value: 1, label: "February" },
          { value: 2, label: "March" },
          { value: 3, label: "April" },
          { value: 4, label: "May" },
          { value: 5, label: "June" },
          { value: 6, label: "July" },
          { value: 7, label: "August" },
          { value: 8, label: "September" },
          { value: 9, label: "October" },
          { value: 10, label: "November" },
          { value: 11, label: "December" },
        ];

  const [leftSalesObject, setLeftSalesObject] = useState({
    loanChannels: {
      brokeredLoans: { count: 0, amount: 0 },
      correspondentLoans: { count: 0, amount: 0 },
      retailLoans: { count: 0, amount: 0 },
      wholesaleLoans: { count: 0, amount: 0 },
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: { amount: 0, count: 0 },
        noCashRefinance: { amount: 0, count: 0 },
        cashRefinance: { amount: 0, count: 0 },
        otherPurpose: { amount: 0, count: 0 },
        conventional: { amount: 0, count: 0 },
        fha: { amount: 0, count: 0 },
        va: { amount: 0, count: 0 },
        usda: { amount: 0, count: 0 },
        reverse: { amount: 0, count: 0 },
        otherType: { amount: 0, count: 0 },
        total: { amount: 0, count: 0 },
      },
      monthlySalesTotals: [
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
      ],
    },
  });
  const [rightSalesObject, setRightSalesObject] = useState({
    loanChannels: {
      brokeredLoans: { count: 0, amount: 0 },
      correspondentLoans: { count: 0, amount: 0 },
      retailLoans: { count: 0, amount: 0 },
      wholesaleLoans: { count: 0, amount: 0 },
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: { amount: 0, count: 0 },
        noCashRefinance: { amount: 0, count: 0 },
        cashRefinance: { amount: 0, count: 0 },
        otherPurpose: { amount: 0, count: 0 },
        conventional: { amount: 0, count: 0 },
        fha: { amount: 0, count: 0 },
        va: { amount: 0, count: 0 },
        usda: { amount: 0, count: 0 },
        reverse: { amount: 0, count: 0 },
        otherType: { amount: 0, count: 0 },
        total: { amount: 0, count: 0 },
      },
      monthlySalesTotals: [
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
      ],
    },
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changePeriod = (value, name) => {
    if (name === "leftCurrentMonth") {
      setLeftPeriod((prevState) => ({
        ...prevState,
        currentMonth: parseInt(value),
      }));
    } else if (name === "rightCurrentMonth") {
      setRightPeriod((prevState) => ({
        ...prevState,
        currentMonth: parseInt(value),
      }));
    } else if (name === "leftCurrentYear") {
      setLeftPeriod({
        currentYear: parseInt(value),
        currentMonth: 100,
      });
    } else if (name === "rightCurrentYear") {
      setRightPeriod({
        currentYear: parseInt(value),
        currentMonth: 100,
      });
    }
  };

  const renderYearOptions = () => {
    return yearOptions.map((year) => (
      <option value={Number.parseInt(year.value)}>{year.label}</option>
    ));
  };

  const renderLeftMonthOptions = () => {
    return leftMonthOptions.map((month) => (
      <option value={Number.parseInt(month.value)}>{month.label}</option>
    ));
  };

  const renderRightMonthOptions = () => {
    return rightMonthOptions.map((month) => (
      <option value={Number.parseInt(month.value)}>{month.label}</option>
    ));
  };

  useEffect(() => {
    const fetchCompanyWideData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${leftPeriod.currentYear}`
        );
        const json = await response.json();
        setLeftSalesObject(json);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideData();
    setLoading(false);
  }, [leftPeriod.currentYear]);

  useEffect(() => {
    const fetchCompanyWideData = async () => {
      setLoading(true);

      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${rightPeriod.currentYear}`
        );
        const json = await response.json();
        setRightSalesObject(json);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideData();
    setLoading(false);
  }, [rightPeriod.currentYear]);

  return (
    !loading && (
      <Col lg="12">
        <Card>
          <CardHeader className="card-category">
            Compare Sales Periods
          </CardHeader>
          <Row style={{ margin: "30px 0" }}>
            <Col md={{ size: 4, offset: 0 }} style={{ paddingLeft: "30px" }}>
              <Label for="leftCurrentMonth">Left Month</Label>

              <Input
                className="primary"
                name="leftCurrentMonth"
                type="select"
                value={leftPeriod.currentMonth}
                onChange={(e) => changePeriod(e.target.value, e.target.name)}
              >
                {renderLeftMonthOptions()}
              </Input>

              <Label for="leftCurrentYear" style={{ marginTop: "10px" }}>
                Left Year
              </Label>

              <Input
                type="select"
                name="leftCurrentYear"
                value={leftPeriod.currentYear}
                onChange={(e) => changePeriod(e.target.value, e.target.name)}
              >
                {renderYearOptions()}
              </Input>
            </Col>

            <Col md={{ size: 4, offset: 2 }}>
              <Label for="rightCurrentMonth">Right Month</Label>
              <Input
                className="primary"
                name="rightCurrentMonth"
                type="select"
                value={rightPeriod.currentMonth}
                onChange={(e) => changePeriod(e.target.value, e.target.name)}
              >
                {renderRightMonthOptions()}
              </Input>

              <Label for="rightCurrentYear" style={{ marginTop: "10px" }}>
                Right Year
              </Label>
              <Input
                type="select"
                name="rightCurrentYear"
                value={rightPeriod.currentYear}
                onChange={(e) => changePeriod(e.target.value, e.target.name)}
              >
                {renderYearOptions()}
              </Input>
            </Col>
          </Row>

          <CardBody>
            <Nav
              pills
              className="nav-pills-danger"
              style={{ marginBottom: "5vh" }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "Loan Purposes",
                  })}
                  onClick={() => {
                    toggle("Loan Purposes");
                  }}
                >
                  Loan Purposes
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "Loan Types" })}
                  onClick={() => {
                    toggle("Loan Types");
                  }}
                >
                  Loan Types
                </NavLink>
              </NavItem>
              {leftPeriod.currentMonth === 100 &&
                rightPeriod.currentMonth === 100 && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "Loan Channels",
                      })}
                      onClick={() => {
                        toggle("Loan Channels");
                      }}
                    >
                      Loan Channels
                    </NavLink>
                  </NavItem>
                )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="Loan Purposes">
                <Row>
                  <AdminMonthlyLoanPurposeCard
                    secondYearData={leftSalesObject}
                    currentYear={leftPeriod.currentYear}
                    currentMonth={leftPeriod.currentMonth}
                  />
                  <AdminMonthlyLoanPurposeCard
                    secondYearData={rightSalesObject}
                    currentYear={rightPeriod.currentYear}
                    currentMonth={rightPeriod.currentMonth}
                  />
                </Row>
              </TabPane>
              <TabPane tabId="Loan Types">
                <Row>
                  <AdminMonthlyLoanTypeCard
                    secondYearData={leftSalesObject}
                    currentYear={leftPeriod.currentYear}
                    currentMonth={leftPeriod.currentMonth}
                  />
                  <AdminMonthlyLoanTypeCard
                    secondYearData={rightSalesObject}
                    currentYear={rightPeriod.currentYear}
                    currentMonth={rightPeriod.currentMonth}
                  />
                </Row>
              </TabPane>
              <TabPane tabId="Loan Channels">
                <Row>
                  <AdminMonthlyLoanChannelCard
                    secondYearData={leftSalesObject}
                  />
                  <AdminMonthlyLoanChannelCard
                    secondYearData={rightSalesObject}
                  />
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    )
  );
};

export default AdminSalesComparisonCard;
