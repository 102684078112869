import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

const LoanOfficerBrokeredOutLoans = ({
  currentLoanOfficer,
  currentMonth,
  currentYear,
}) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentSalesObject =
    currentMonth === 100
      ? currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ).annualSalesTotals
      : currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ).monthlySalesTotals[currentMonth];

  const loName = currentLoanOfficer.name
    ? `${
        currentLoanOfficer.name.endsWith("s")
          ? `${currentLoanOfficer.name}'`
          : `${currentLoanOfficer.name}'s`
      } `
    : "";

  return (
    <Col lg={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {loName}
          {currentMonth === 100
            ? currentYear
            : `${MONTHS[currentMonth]} ${currentYear} Brokered Loans`}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {currentSalesObject.brokered.count}
                  </h3>
                  <h6 className="stats-title">Brokered Out Loans</h6>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {Number.parseFloat(
                      currentSalesObject.brokered.amount,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">Brokered Out Loan Amount</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerBrokeredOutLoans;
