import React from "react";
import {Row} from "reactstrap";
import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import CorrespondentAdminEncompassMetricsAccountExecutivesRankingsTable from "../components/CorrespondentAdminEncompassMetricsAccountExecutivesRankingsTable";
import CorrespondentAdminEncompassMetricsSourcesRankingsTable from "../components/CorrespondentAdminEncompassMetricsSourcesRankingsTable";

const CorrespondentAdminEncompassMetrics = () => {
  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <CorrespondentAdminEncompassMetricsAccountExecutivesRankingsTable />
          <CorrespondentAdminEncompassMetricsSourcesRankingsTable />
        </Row>
      </div>
    </>
  );
};

export default CorrespondentAdminEncompassMetrics;
