import AdminFastFacts from "../fast-facts/admin/pages/AdminFastFacts";
import AdminSalesTimeline from "../sales/admin/pages/AdminSalesTimeline";
import AdminEncompassMetrics from "../sales/admin/pages/AdminEncompassMetrics";
import AdminBranches from "../sales/admin/pages/AdminBranches";
import LoanLookup from "../admin-tools/pages/LoanLookup";

let adminRoutes = [
  {
    path: "/fast-facts",
    name: "Fast Facts",
    icon: "now-ui-icons design_app",
    component: AdminFastFacts,
    layout: "/retail-admin",
  },
  {
    collapse: true,
    name: "Sales",
    icon: "now-ui-icons business_chart-bar-32",
    state: "salesOpen",
    views: [
      {
        path: "/sales/sales-timeline",
        name: "Sales Timeline",
        mini: "ST",
        component: AdminSalesTimeline,
        layout: "/retail-admin",
      },

      {
        path: "/sales/encompass-metrics",
        name: "Encompass Metrics",
        mini: "EM",
        component: AdminEncompassMetrics,
        layout: "/retail-admin",
      },
      {
        path: "/sales/branches",
        name: "Branches",
        mini: "EM",
        component: AdminBranches,
        layout: "/retail-admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Admin Tools",
    icon: "now-ui-icons objects_key-25",
    state: "adminToolsOpen",
    views: [
      {
        path: "/admin-tools/loan-lookup",
        name: "Loan Lookup",
        mini: "LL",
        component: LoanLookup,
        layout: "/retail-admin",
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: "Marketing",
  //   icon: "now-ui-icons media-1_album",
  //   state: "marketingOpen",
  //   views: [
  //     {
  //       path: "/marketing/emerging-markets",
  //       name: "Emerging Markets",
  //       mini: "EM",
  //       component: EmergingMarkets,
  //       layout: "/admin"
  //     }
  //   ]
  // }
  //   {
  //     collapse: true,
  //     path: "/pages",
  //     name: "Pages",
  //     state: "openPages",
  //     icon: "now-ui-icons design_image",
  //     views: [
  //       {
  //         path: "/timeline-page",
  //         name: "Timeline Page",
  //         mini: "TP",
  //         component: TimelinePage,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/user-page",
  //         name: "User Profile",
  //         mini: "UP",
  //         component: UserPage,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/rtl-support",
  //         name: "RTL Support",
  //         mini: "RS",
  //         component: RTL,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/register-page",
  //         name: "Register Page",
  //         short: "Register",
  //         mini: "RP",
  //         component: RegisterPage,
  //         layout: "/auth"
  //       },
  //       {
  //         path: "/login-page",
  //         name: "Login Page",
  //         short: "Login",
  //         mini: "LP",
  //         component: LoginPage,
  //         layout: "/auth"
  //       },
  //       {
  //         path: "/pricing-page",
  //         name: "Pricing Page",
  //         short: "Pricing",
  //         mini: "PP",
  //         component: PricingPage,
  //         layout: "/auth"
  //       },
  //       {
  //         path: "/lock-screen-page",
  //         name: "Lock Screen Page",
  //         short: "Lock",
  //         mini: "LSP",
  //         component: LockScreenPage,
  //         layout: "/auth"
  //       }
  //     ]
  //   },
];

export default adminRoutes;
