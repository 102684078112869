import React from "react";
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";
import Select from "react-select";

import MoneyBag from "../../../assets/icons/Essex_Icons_Money Bag.svg";
import Family from "../../../assets/icons/Essex_Icons_Family.svg";
import House from "../../../assets/icons/Essex_Icons_House.svg";

const AdminEncompassMetricsHighlights = ({
  timeFrame,
  timeFrameLabel,
  handleTimeFrameChange,
}) => {
  const selectOptions = [
    {value: "2021", label: "2021"},
    {value: "2020", label: "2020"},
    {value: "2019", label: "2019"},
  ];

  return (
    <Col xs={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{display: "inline-block"}}>
              Company Wide Encompass Metrics
            </p>
            <div style={{width: "12vw"}}>
              <Select
                style={{color: "red"}}
                className="primary"
                placeholder={`Time Frame: ${timeFrameLabel}`}
                name="singleSelect"
                value={timeFrameLabel}
                options={selectOptions}
                onChange={(value) => handleTimeFrameChange(value.value)}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={House} alt="money" />
                  </div>
                  <h3 className="info-title">
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.total,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">Average Loan Amount</h6>
                </div>
              </div>
            </Col>

            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={MoneyBag} alt="money" />
                  </div>
                  <h3 className="info-title">
                    {Number.parseFloat(
                      timeFrame.totalMonthlyIncome * 12,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">
                    Average Combined Annual Income
                  </h6>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={MoneyBag} alt="money" />
                  </div>
                  <h3 className="info-title">
                    {Number.parseFloat(
                      timeFrame.totalMonthlyIncome,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">
                    Average Combined Monthly Income
                  </h6>
                </div>
              </div>
            </Col>

            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={Family} alt="family" />
                  </div>
                  <h3 className="info-title">{timeFrame.averageAge}</h3>
                  <h6 className="stats-title">Average Borrower Age</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminEncompassMetricsHighlights;
