import React, {useState} from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupText,
  Col,
} from "reactstrap";

import DefaultHeadshot from "../../../assets/images/default_headshot.png";

const LoanOfficerAdditionalCommissionsInput = ({
  loanOfficer,
  additionalCommissions,
  setAdditionalCommissions,
}) => {
  const [commissionPercent, setCommissionPercent] = useState(0);
  const [include, setInclude] = useState(true);

  const handlePercentChange = (value, name) => {
    const newArr = [...additionalCommissions];
    const updateIndex = newArr.findIndex(
      (loanOfficer) => loanOfficer.name === name,
    );
    newArr[updateIndex].commissionPercent = Number.parseFloat(value);
    setAdditionalCommissions(newArr);
    setCommissionPercent(value);
  };

  const styles = {color: "#2c2c2c"};

  return (
    <Col md="4">
      <Card>
        <CardBody style={{display: "flex"}}>
          <img
            alt={`${loanOfficer.name} headshot`}
            style={{
              maxWidth: "125px",
              maxHeight: "125px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={
              loanOfficer.headshotURL === ""
                ? DefaultHeadshot
                : loanOfficer.headshotURL
            }
          />
          <div style={{marginLeft: "40px"}}>
            <h6 style={styles}>{loanOfficer.name}</h6>
            <p>
              {`Previous Year Total: ${loanOfficer.prevYearSalesTotal.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                },
              )}`}
            </p>
            <FormGroup>
              <InputGroup>
                <Input
                  type="checkbox"
                  value={include}
                  onChange={() => setInclude(!include)}
                />
                <Label check>Include in my commission goal</Label>
              </InputGroup>
              <Label for="percent-change">Commission Percent</Label>
              <InputGroup>
                <Input
                  disabled={include}
                  type="number"
                  step={0.01}
                  style={{
                    borderRadius: "5px",
                  }}
                  id="percent-change"
                  placeholder=""
                  value={commissionPercent}
                  onChange={(e) => {
                    handlePercentChange(e.target.value, loanOfficer.name);
                  }}
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerAdditionalCommissionsInput;
