import React from "react";
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";

const AdminEncompassMetricsNextMonthHighlights = ({allPipelineLoans}) => {
  const nextMonthIndex =
    new Date().getMonth() === 11 ? 0 : new Date().getMonth() + 1;

  const thisYear =
    nextMonthIndex === 0
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();

  const nextMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][nextMonthIndex];

  const loansExpectedToCloseNextMonth = allPipelineLoans.filter(
    (loan) =>
      new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() === thisYear &&
      new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === nextMonthIndex,
  );

  return (
    <Col xs={6}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${nextMonth} Projections`}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseNextMonth.length}
                  </h3>
                  <h6 className="stats-title">{`Loans Estimated to Fund in ${nextMonth}`}</h6>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseNextMonth
                      .filter(
                        (loan) => loan.fields["Loan.TotalLoanAmount"] !== "",
                      )
                      .reduce(
                        (previousValue, currentValue) =>
                          previousValue +
                          Number.parseInt(
                            currentValue.fields["Loan.TotalLoanAmount"],
                          ),
                        0,
                      )
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </h3>
                  <h6 className="stats-title">{`Loans $ Estimated to Fund in ${nextMonth}`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminEncompassMetricsNextMonthHighlights;
