import React from "react";
import {Col, Card, CardHeader, CardBody, Table} from "reactstrap";

const AdminEncompassMetricsYTDMetrics = ({timeFrame, timeFrameLabel}) => {
  return (
    <Col xs={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${timeFrameLabel} Metrics`}
        </CardHeader>
        <CardBody>
          <Col md={12}>
            <Table responsive>
              <thead className="text-center">
                <th>Loan Type / Purpose</th>
                <th>Average Loan Amount</th>
                <th>Average Days to Fund</th>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td>Purchase</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.purchases,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.purchase * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>No Cash Out Refinance</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.noCashRefinance,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.noCashRefinance * 100) /
                      100}
                  </td>
                </tr>
                <tr>
                  <td>Cash Out Refinance</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.cashRefinance,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.cashRefinance * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>Other Purpose</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.otherPurpose,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.otherPurpose * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>Conventional</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.conventional,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.conventional * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>FHA</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.fha,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>{Math.round(timeFrame.daysToFund.fha * 100) / 100}</td>
                </tr>
                <tr>
                  <td>VA</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.va,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>{Math.round(timeFrame.daysToFund.va * 100) / 100}</td>
                </tr>
                <tr>
                  <td>USDA</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.usda,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>{Math.round(timeFrame.daysToFund.usda * 100) / 100}</td>
                </tr>
                <tr>
                  <td>Reverse</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.reverse,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.reverse * 100) / 100}
                  </td>
                </tr>
                <tr>
                  <td>Other Type</td>
                  <td>
                    {Number.parseFloat(
                      timeFrame.averageLoanAmounts.otherType,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td>
                    {Math.round(timeFrame.daysToFund.otherType * 100) / 100}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminEncompassMetricsYTDMetrics;
