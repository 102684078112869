import React, {useState, useEffect} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import ReactTable from "../../../components/NowUI/ReactTable/ReactTable";
import Loading from "../../../components/NowUI/Loading/Loading";

const CorrespondentAdminEncompassMetricsAccountExecutivesRankingsTable = () => {
  const [timeframe, setTimeframe] = useState("ytd");
  const [metric, setMetric] = useState("sales");
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getAccountExecutiveTotals?timeframe=${timeframe}`,
        );
        let json = await response.json();

        json = json.filter(
          (accountExecutive) => accountExecutive.name !== "Leslie Vasquez",
        );

        if (metric === "sales") {
          const sortedRankings = json.sort(
            (a, b) => b.total.amount - a.total.amount,
          );
          setTableHeaders(
            sortedRankings.map((accountExecutive, index) => {
              return {
                rank: index + 1,
                name: `${accountExecutive.name}`,
                salesAmount: accountExecutive.total.amount.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                ),
                salesCount: accountExecutive.total.count,
              };
            }),
          );
          setTableColumns([
            {
              Header: "Rank",
              accessor: "rank",
              disableFilters: true,
            },
            {
              Header: "Name",
              accessor: "name",
              // disableSortBy: true,
              // canSort: false
            },
            {
              Header: "Production Amount",
              accessor: "salesAmount",
              disableFilters: true,
              // canSort: false,
            },
            {
              Header: "Loan Count",
              accessor: "salesCount",
              disableFilters: true,
            },
          ]);
        } else if (metric === "credit") {
          const sortedRankings = json.sort(
            (a, b) =>
              b.encompassMetrics.ytdMetrics.averageCreditScore -
              a.encompassMetrics.ytdMetrics.averageCreditScore,
          );
          setTableHeaders(
            sortedRankings.map((accountExecutive, index) => {
              return {
                rank: index + 1,
                name: `${accountExecutive.name}`,
                creditYtd:
                  accountExecutive.encompassMetrics.ytdMetrics
                    .averageCreditScore,
                credit30:
                  accountExecutive.encompassMetrics.last30DaysMetrics
                    .averageCreditScore,
              };
            }),
          );
          setTableColumns([
            {
              Header: "Rank",
              accessor: "rank",
              disableFilters: true,
            },
            {
              Header: "Name",
              accessor: "name",
              // disableSortBy: true,
              // canSort: false
            },
            {
              Header: "YTD Credit Average",
              accessor: "creditYtd",
              disableFilters: true,
              // canSort: false,
            },
            {
              Header: "Last 30 Days Credit Average",
              accessor: "credit30",
              disableFilters: true,
            },
          ]);
        } else if (metric === "loanAmounts") {
          const sortedRankings = json.sort(
            (a, b) =>
              b.encompassMetrics.ytdMetrics.averageLoanAmount -
              a.encompassMetrics.ytdMetrics.averageLoanAmount,
          );
          setTableHeaders(
            sortedRankings.map((accountExecutive, index) => {
              return {
                rank: index + 1,
                name: `${accountExecutive.name}`,
                loanAmountYtd:
                  accountExecutive.encompassMetrics.ytdMetrics.averageLoanAmount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  ),
                loanAmount30:
                  accountExecutive.encompassMetrics.last30DaysMetrics.averageLoanAmount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  ),
              };
            }),
          );
          setTableColumns([
            {
              Header: "Rank",
              accessor: "rank",
              disableFilters: true,
            },
            {
              Header: "Name",
              accessor: "name",
              // disableSortBy: true,
              // canSort: false
            },
            {
              Header: "YTD Loan Amount Average",
              accessor: "loanAmountYtd",
              disableFilters: true,
              // canSort: false,
            },
            {
              Header: "Last 30 Days Loan Amount Average",
              accessor: "loanAmount30",
              disableFilters: true,
            },
          ]);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchRankings();
  }, [metric, timeframe]);

  return (
    <Col md="12">
      <Card>
        <CardHeader className="card-category">
          {`Account Executives Production Rankings`}
        </CardHeader>
        <CardBody>
          <div
            style={{
              display: "flex",
              margin: "1vh 0 3vh",
              justifyContent: "space-between",
            }}
          >
            <div style={{justifySelf: "flex-start"}}>
              <h6 className="card-category" style={{marginLeft: "15px"}}>
                Metric
              </h6>
              <Nav
                pills
                className="nav-pills-danger"
                style={{margin: "1vh 0 ", display: "flex", gap: "10px"}}
              >
                <NavItem>
                  <NavLink
                    style={{cursor: "pointer"}}
                    className={classnames({
                      active: metric === "sales",
                    })}
                    onClick={() => {
                      setMetric("sales");
                      setTimeframe("ytd");
                    }}
                  >
                    Sales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor: "pointer"}}
                    className={classnames({
                      active: metric === "credit",
                    })}
                    onClick={() => {
                      setMetric("credit");
                      setTimeframe("ytd");
                    }}
                  >
                    Credit
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{cursor: "pointer"}}
                    className={classnames({
                      active: metric === "loanAmounts",
                    })}
                    onClick={() => {
                      setMetric("loanAmounts");
                      setTimeframe("ytd");
                    }}
                  >
                    Loan Amount
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            {metric === "sales" && (
              <div style={{justifySelf: "flex-end"}}>
                <h6 className="card-category" style={{marginLeft: "15px"}}>
                  Timeframe
                </h6>
                <Nav
                  pills
                  className="nav-pills-danger"
                  style={{margin: "1vh 0 ", display: "flex", gap: "20px"}}
                >
                  <NavItem>
                    <NavLink
                      style={{cursor: "pointer"}}
                      className={classnames({
                        active: timeframe === "2020",
                      })}
                      onClick={() => {
                        setTimeframe("2020");
                      }}
                    >
                      2020
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{cursor: "pointer"}}
                      className={classnames({
                        active: timeframe === "2021",
                      })}
                      onClick={() => {
                        setTimeframe("2021");
                      }}
                    >
                      2021
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{cursor: "pointer"}}
                      className={classnames({
                        active: timeframe === "previousMonth",
                      })}
                      onClick={() => {
                        setTimeframe("previousMonth");
                      }}
                    >
                      Previous Month
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{cursor: "pointer"}}
                      className={classnames({
                        active: timeframe === "currentMonth",
                      })}
                      onClick={() => {
                        setTimeframe("currentMonth");
                      }}
                    >
                      Current Month
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{cursor: "pointer"}}
                      className={classnames({
                        active: timeframe === "ytd",
                      })}
                      onClick={() => {
                        setTimeframe("ytd");
                      }}
                    >
                      YTD
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            )}
          </div>

          {loading ? (
            <Loading />
          ) : (
            <ReactTable
              defaultSortColumn="rank"
              data={tableHeaders}
              columns={tableColumns}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CorrespondentAdminEncompassMetricsAccountExecutivesRankingsTable;
