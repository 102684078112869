import React, {useState, useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router-dom";

import Spinner from "../../components/NowUI/Spinner/Spinner";
import Sidebar from "../../components/NowUI/Sidebar/Sidebar";
import correspondentAdminRoutes from "../../routes/correspondentAdminRoutes";
import CorrespondentAdminNavbar from "../../components/NowUI/Navbars/CorrespondentAdminNavbar/CorrespondentAdminNavbar";
import LogoutModal from "../shared/LogoutModal/LogoutModal";

const CorrespondentAdmin = (props) => {
  const history = useHistory();
  const location = useLocation();
  const mostRecentYear = new Date().getFullYear();

  const [sideDrawerIsMini, setSideDrawerIsMini] = useState(false);
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);

  // const [searchByBranchModalOpen, setSearchByModalOpen] = useState(false);
  const [currentAccountExecutive, setCurrentAccountExecutive] = useState({
    encompassMetrics: {
      lifetimeMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      threeMonthMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      sixMonthMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      oneYearMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      ytdMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      encompassSales: [
        {
          year: 2020,
          annualSalesTotals: {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
          },
          monthlySalesTotals: [
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
          ],
        },
        {
          year: 2021,
          annualSalesTotals: {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
          },
          monthlySalesTotals: [
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
            {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
            },
          ],
        },
      ],
    },
    thumbnailURL: "",
  });
  const [currentYearData, setCurrentYearData] = useState({
    loanChannels: {
      brokeredLoans: {count: 0, amount: 0},
      correspondentLoans: {count: 0, amount: 0},
      retailLoans: {count: 0, amount: 0},
      wholesaleLoans: {count: 0, amount: 0},
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: {amount: 0, count: 0},
        noCashRefinance: {amount: 0, count: 0},
        cashRefinance: {amount: 0, count: 0},
        otherPurpose: {amount: 0, count: 0},
        conventional: {amount: 0, count: 0},
        fha: {amount: 0, count: 0},
        va: {amount: 0, count: 0},
        usda: {amount: 0, count: 0},
        reverse: {amount: 0, count: 0},
        otherType: {amount: 0, count: 0},
        total: {amount: 0, count: 0},
        attached: {amount: 0, count: 0},
        condo: {amount: 0, count: 0},
        cooperative: {amount: 0, count: 0},
        detached: {amount: 0, count: 0},
        highRiseCondo: {amount: 0, count: 0},
        manufactured: {amount: 0, count: 0},
        pud: {amount: 0, count: 0},
        detachedCondo: {amount: 0, count: 0},
        otherPropertyType: {amount: 0, count: 0},
        mhSelect: {amount: 0, count: 0},
        mhAdvantage: {amount: 0, count: 0},
      },
      monthlySalesTotals: [
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
      ],
    },
    lastUpdated: "",
    ytdMetrics: {
      averageAge: "0",
      averageLoanAmounts: {
        purchases: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
        total: "0",
      },
      top25Referrals: [],
      top25Zips: [],
      totalMonthlyIncome: "0",
      daysToFund: {
        purchase: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
      },
    },
  });
  const [loggedInUser, setLoggedInUser] = useState({
    name: "",
    email: "",
  });
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  console.log(`sideDrawerIsMini`, sideDrawerIsMini);
  const mainPanel = React.createRef();

  useEffect(() => {
    document.documentElement.className += " perfect-scrollbar-on";
    document.documentElement.classList.remove("perfect-scrollbar-off");
    let ps = new PerfectScrollbar(mainPanel.current);

    return () => {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    };
  }, [mainPanel]);

  // Logout Modal timers
  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setLogoutModalOpen(true);
    }, 60 * 60 * 1000);

    return () => {
      clearTimeout(currentTimeout);
    };
  }, [logoutModalOpen]);

  const toggleSideDrawerSize = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSideDrawerIsMini(false);
    } else {
      setSideDrawerIsMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const changeAccountExecutive = async (name) => {
    history.push({
      pathname: "/account-executive/fast-facts",
      state: {
        name: name,
        returnAs: {
          ...loggedInUser,
        },
      },
    });
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/correspondent-admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => (
              <prop.component
                currentYearData={currentYearData}
                currentAccountExecutive={currentAccountExecutive}
                setCurrentAccountExecutive={setCurrentAccountExecutive}
                setLoggedInUser={setLoggedInUser}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path,
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  // Fetch company wide data
  useEffect(() => {
    const fetchCompanyWideCorrespondentData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${mostRecentYear}&channel=Correspondent`,
        );
        const json = await response.json();
        setCurrentYearData(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideCorrespondentData();
  }, [mostRecentYear]);

  useEffect(() => {
    if (location.state) {
      if (location.state.initialEmail) {
        // Initial login
        setLoggedInUser({
          name: location.state.initialName,
          email: location.state.initialEmail,
          authTier: "Correspondent Admin",
          photoURL: location?.state?.photoURL,
        });

        setInit(true);
      }
    }
  }, [location.state, init]);

  useEffect(() => {
    console.log(`location.state`, location.state);
  }, [location.state]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        name={loggedInUser.name}
        routes={correspondentAdminRoutes}
        backgroundColor="blue"
        minimizeSidebar={toggleSideDrawerSize}
        photoURL={loggedInUser.photoURL}
      />
      <div
        className="main-panel"
        ref={mainPanel}
        style={{position: "relative"}}
      >
        <CorrespondentAdminNavbar
          {...props}
          brandText={getActiveRoute(correspondentAdminRoutes)}
          // toggleSearchByBranchModal={toggleSearchByBranchModal}
          currentAccountExecutive={currentAccountExecutive}
          changeAccountExecutive={changeAccountExecutive}
          name={loggedInUser.name}
        />

        {logoutModalOpen && (
          <LogoutModal
            logoutModalOpen={logoutModalOpen}
            setLogoutModalOpen={setLogoutModalOpen}
          />
        )}

        {loading && <Spinner title="Fetching Company Wide Sales Data" />}

        <Switch>{getRoutes(correspondentAdminRoutes)}</Switch>
      </div>
    </div>
  );
};

export default CorrespondentAdmin;
