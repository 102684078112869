import React from "react";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

const RegionalManagerTopZipsCard = ({ currentLoanOfficer }) => {
  const history = useHistory();
  const renderTopZips = () => {
    return currentLoanOfficer.encompassMetrics.ytdMetrics.top3Zips.length >
      0 ? (
      currentLoanOfficer.encompassMetrics.ytdMetrics.top3Zips
        .slice(0, 3)
        .map((zip) => {
          return (
            <Col md="4" key={zip.zip}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{zip.zip}</h3>

                  <h6 className="stats-title">{`${zip.count} Loans Closed`}</h6>
                  <h6 className="stats-title">
                    {`(${zip.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })})`}
                  </h6>
                </div>
              </div>
            </Col>
          );
        })
    ) : (
      <div style={{ width: "100%", margin: "42px auto" }}>
        <h6 style={{ textAlign: "center" }}>No sales data</h6>
      </div>
    );
  };
  return (
    <Col md="12">
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${
            currentLoanOfficer.name.endsWith("s")
              ? `${currentLoanOfficer.name}'`
              : `${currentLoanOfficer.name}'s`
          } Top Zip Codes YTD`}
        </CardHeader>
        <CardBody>
          <Row>{renderTopZips()}</Row>
        </CardBody>
        <CardFooter>
          <h6
            className="text-right"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#061f54"
            }}
            onClick={() => {
              history.push("/regional-manager/sales/encompass-metrics");
            }}
          >
            See more metrics
          </h6>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default RegionalManagerTopZipsCard;
