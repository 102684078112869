import React, { useState, useEffect } from "react";
import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import { Line } from "react-chartjs-2";
import { Col, Card, CardHeader, CardBody, Row } from "reactstrap";
import Select from "react-select";
import Loading from "../../../components/NowUI/Loading/Loading";

const LoanOfficerMyBranch = ({ currentLoanOfficer }) => {
  const [myBranch, setMyBranch] = useState({
    branchSales: [],
    branchName: "",
    branchCode: ""
  });
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [loading, setLoading] = useState(true);

  const yearOptions = [
    { value: 2019, label: "2019" },
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" }
  ];

  const monthLabel = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ][month];

  useEffect(() => {
    const fetchMyBranch = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getBranchData?branchCode=${currentLoanOfficer.branchCode}`
        );
        const json = await response.json();
        setMyBranch(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMyBranch();
  }, [currentLoanOfficer.branchCode]);

  const annualSalesAmounts = myBranch.branchSales.find(
    (element) => element.year === year
  )
    ? myBranch.branchSales
        .find((element) => element.year === year)
        .monthlySalesTotals.map((element) => element.total.amount)
    : [];

  const annualSalesCounts = myBranch.branchSales.find(
    (element) => element.year === year
  )
    ? myBranch.branchSales
        .find((element) => element.year === year)
        .monthlySalesTotals.map((element) => element.total.count)
    : [];

  const ytdTotal = myBranch.branchSales.find((element) => element.year === year)
    ? myBranch.branchSales.find((element) => element.year === year)
        .annualSalesTotals.total.amount
    : 0;

  const ytdCount = myBranch.branchSales.find((element) => element.year === year)
    ? myBranch.branchSales.find((element) => element.year === year)
        .annualSalesTotals.total.count
    : 0;

  const thisMonthTotal = myBranch.branchSales.find(
    (element) => element.year === year
  )
    ? myBranch.branchSales.find((element) => element.year === year)
        .monthlySalesTotals[month].total.amount
    : 0;

  const thisMonthCount = myBranch.branchSales.find(
    (element) => element.year === year
  )
    ? myBranch.branchSales.find((element) => element.year === year)
        .monthlySalesTotals[month].total.count
    : 0;

  const branchCardChart = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ],
        datasets: [
          {
            label: "Total Sales: ",
            borderColor: chartColor,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2c2c2c",
            pointHoverBackgroundColor: "#2c2c2c",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: annualSalesAmounts
          }
        ]
      };
    },
    options: {
      onClick: (event, element) => {
        if (element.length > 0) {
          const index = element[0]._index;
          setMonth(index);
        }
      },
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },

      layout: {},
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#333",
        titleFontSize: 16,
        bodyFontColor: "#666",
        bodyFontSize: 16,
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const dollarValue = parseInt(tooltipItem.value).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD"
              }
            );
            const loanCount = annualSalesCounts[tooltipItem.index];

            return `${label} ${dollarValue}
          Count: ${loanCount}`;
          }
        }
      },
      legend: {
        position: "bottom",
        fillStyle: "#FFF",
        display: false
      },

      scales: {
        yAxes: [
          {
            ticks: {
              display: true,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 4,
              padding: 10,
              callback: (value, index, values) => {
                return value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                });
              }
            },
            gridLines: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent"
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "rgba(255,255,255,0.1)"
            },
            ticks: {
              padding: 10,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold"
            }
          }
        ]
      }
    }
  };
  return (
    <>
      <PanelHeader
        size="lg"
        content={
          <Line data={branchCardChart.data} options={branchCardChart.options} />
        }
      />
      <div className="content">
        <Row>
          <Col md={12}>
            <Card className="card-stats card-raised">
              <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{ display: "inline-block" }}
                    className="card-category"
                  >
                    {`${myBranch.branchName} (${myBranch.branchCode})`}
                  </p>
                  <div style={{ width: "12vw" }}>
                    <Select
                      className="primary"
                      placeholder={year}
                      name="yearSelect"
                      value={year}
                      options={yearOptions}
                      onChange={(value) => setYear(parseInt(value.value))}
                    />
                  </div>
                </div>
              </CardHeader>
              {loading ? (
                <Loading />
              ) : (
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">
                            {ytdTotal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                            })}
                          </h3>
                          <h6 className="stats-title">YTD Sales Total</h6>
                        </div>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{ytdCount}</h3>
                          <h6 className="stats-title">YTD Loan Count</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">
                            {thisMonthTotal.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0
                            })}
                          </h3>
                          <h6 className="stats-title">{`${monthLabel} ${year} Total Production`}</h6>
                        </div>
                      </div>
                    </Col>

                    <Col md="3">
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{thisMonthCount}</h3>
                          <h6 className="stats-title">{`${monthLabel} ${year} Loan Count`}</h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoanOfficerMyBranch;
