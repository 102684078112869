import React from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const LoanOfficerTop3Zips = ({ currentLoanOfficer, zips, count = 3 }) => {
  return (
    <Col md="12">
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${
            currentLoanOfficer.name.endsWith("s")
              ? `${currentLoanOfficer.name}'`
              : `${currentLoanOfficer.name}'s`
          } Your Top Zip Codes YTD`}
        </CardHeader>
        <CardBody>
          <Row>
            {zips.slice(0, count).map((zip) => {
              return (
                <Col md="4" key={zip.zip}>
                  <div className="statistics">
                    <div className="info">
                      <h3 className="info-title">{zip.zip}</h3>

                      <h6 className="stats-title">
                        {`${zip.count} Loans Closed`}
                      </h6>
                      <h6 className="stats-title">
                        {`(${zip.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        })})`}
                      </h6>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerTop3Zips;
