import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Row,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";

const LoanOfficerSalesGoalsHighlights = ({
  currentLoanOfficer,
  withRedirect = false,
}) => {
  const history = useHistory();
  const sales = currentLoanOfficer.encompassMetrics.encompassSales.find(
    (salesObj) => salesObj.year === new Date().getFullYear()
  );

  const salesGoal = currentLoanOfficer?.salesGoals?.[new Date().getFullYear()];
  console.log(salesGoal);

  return (
    <Card className="card-stats card-raised">
      <CardHeader className="card-category">{`${
        currentLoanOfficer.name.endsWith("s")
          ? `${currentLoanOfficer.name}'`
          : `${currentLoanOfficer.name}'s`
      } Sales Goals`}</CardHeader>
      <CardBody>
        {salesGoal !== undefined ? (
          <>
            <Row>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.parseInt(salesGoal.productionGoal).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                    </h3>
                    <h6 className="stats-title">Production Goal</h6>
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {`${
                        salesGoal.productionGoal === 0 ||
                        salesGoal.commissionGoal === 0
                          ? 100
                          : Math.round(
                              (sales.annualSalesTotals.total.amount /
                                salesGoal.productionGoal) *
                                100 *
                                100
                            ) / 100
                      }%`}
                    </h3>
                    <h6 className="stats-title">Percent of Goal Achieved</h6>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.isNaN(
                        salesGoal.productionGoal /
                          currentLoanOfficer.encompassMetrics.oneYearMetrics
                            .averageLoanAmount
                      ) ||
                      !Number.isFinite(
                        salesGoal.productionGoal /
                          currentLoanOfficer.encompassMetrics.oneYearMetrics
                            .averageLoanAmount
                      )
                        ? "-"
                        : Math.ceil(
                            salesGoal.productionGoal /
                              currentLoanOfficer.encompassMetrics.oneYearMetrics
                                .averageLoanAmount
                          )}
                    </h3>
                    <h6 className="stats-title">Loans Needed to meet goal</h6>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {sales.annualSalesTotals.total.count}
                    </h3>
                    <h6 className="stats-title">
                      Totals Loans Funded this year
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.isNaN(salesGoal.productionGoal / 12)
                        ? 0
                        : Number.parseFloat(
                            salesGoal.productionGoal / 12
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                    </h3>
                    <h6 className="stats-title">
                      Production needed each month
                    </h6>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.isNaN(
                        salesGoal.productionGoal /
                          currentLoanOfficer.encompassMetrics.oneYearMetrics
                            .averageLoanAmount
                      ) ||
                      !Number.isFinite(
                        salesGoal.productionGoal /
                          currentLoanOfficer.encompassMetrics.oneYearMetrics
                            .averageLoanAmount
                      )
                        ? "-"
                        : Math.round(
                            (salesGoal.productionGoal /
                              currentLoanOfficer.encompassMetrics.oneYearMetrics
                                .averageLoanAmount /
                              12) *
                              100
                          ) / 100}
                    </h3>
                    <h6 className="stats-title">Loans needed each month</h6>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {
                        sales.monthlySalesTotals[new Date().getMonth()].total
                          .count
                      }
                    </h3>
                    <h6 className="stats-title">Loans Funded this month</h6>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <h6 style={{ textAlign: "center" }}>
            Please set your sales goal{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#061f54",
              }}
              onClick={() => {
                history.push("/loan-officer/sales/sales-goals");
              }}
            >
              here.
            </span>
          </h6>
        )}
      </CardBody>
      {withRedirect && (
        <CardFooter
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/loan-officer/sales/sales-goals");
            }}
          >
            See My Sales Goals
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default LoanOfficerSalesGoalsHighlights;
