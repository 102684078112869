import React from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Row } from "reactstrap";
import Spinner from "../../../components/NowUI/Spinner/Spinner";
import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import BranchManagerMyPipelineHighlights from "../components/BranchManagerMyPipelineHighlights";
import BranchManagerMyBranchRankings from "../components/BranchManagerMyBranchRankings";
import BranchManagerLoanOfficersList from "../components/BranchManagerLoanOfficersList";
const BranchManagerMyBranch = ({ initLoading, branchData }) => {
  const auth = useAuth();

  // Redirect when logged out
  // useEffect(() => {
  //   if (auth.user === false) {
  //     history.push("/login");
  //   }
  // }, [auth.signedIn, auth.user, history]);

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <BranchManagerMyPipelineHighlights branchData={branchData} />
            </Row>
            <Row>
              <BranchManagerLoanOfficersList branchData={branchData} />
            </Row>
            <Row>
              <BranchManagerMyBranchRankings branchData={branchData} />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default BranchManagerMyBranch;
