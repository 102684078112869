import React from "react";
import { Line } from "react-chartjs-2";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";

const LoanOfficerSalesTimelineMainChart = ({
  currentLoanOfficer,
  setCurrentMonth,
  currentYear
}) => {
  const annualSalesAmounts = currentLoanOfficer.encompassMetrics.encompassSales
    .find((element) => element.year === currentYear)
    .monthlySalesTotals.map((element) => element.total.amount);

  const monthsAbbreviations = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];

  const chartOptions = {
    onClick: (event, element) => {
      if (element.length > 0) {
        const index = element[0]._index;
        setCurrentMonth(index);
      }
    },

    onHover: function (e) {
      var point = this.getElementAtEvent(e);
      if (point.length) e.target.style.cursor = "pointer";
      else e.target.style.cursor = "default";
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0
      }
    },
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      titleFontSize: 16,
      bodyFontColor: "#666",
      bodyFontSize: 16,
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const dollarValue = parseInt(tooltipItem.value).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD"
            }
          );
          return label + dollarValue;
        }
      }
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: false
    },
    title: {
      display: true,
      text: "Annual Sales",
      fontStyle: "bold"
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 4,
            padding: 10,
            callback: (value, index, values) => {
              return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              });
            }
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(255,255,255,0.1)"
          },
          ticks: {
            padding: 10,
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold"
          }
        }
      ]
    }
  };

  const salesTimelinePanelChart = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: monthsAbbreviations,
        datasets: [
          {
            label: "Total Sales: ",
            borderColor: chartColor,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2c2c2c",
            pointHitRadius: 50,
            pointHoverBackgroundColor: "#2c2c2c",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 1,
            data: annualSalesAmounts
          }
        ]
      };
    },
    options: chartOptions
  };
  return (
    <PanelHeader
      size="lg"
      content={
        <Line
          key={currentYear}
          data={salesTimelinePanelChart.data}
          options={salesTimelinePanelChart.options}
        />
      }
    />
  );
};

export default LoanOfficerSalesTimelineMainChart;
