import React, {useState} from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";
import LoanOfficerSalesGoalConfirmationModal from "./LoanOfficerSalesGoalConfirmationModal";

const LoanOfficerProductionCalculator = ({
  currentLoanOfficer,
  prevYearsAnnualSalesTotals,
  avgLoanAmount,
  handleGoalSetChange,
  setCalculatorOpen,
  goalSet,
}) => {
  const [productionGoal, setProductionGoal] = useState(0);
  const [productionGoalPercent, setProductionGoalPercent] = useState(
    (
      ((productionGoal -
        prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
          .amount) /
        Math.abs(
          prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
            .amount,
        )) *
      100
    ).toFixed(2),
  );
  const [modalOpen, setModalOpen] = useState(false);

  const handleProductionGoalChange = (value) => {
    setProductionGoalPercent(
      !value
        ? -100
        : (
            ((value -
              prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
                .amount) /
              Math.abs(
                prevYearsAnnualSalesTotals[
                  prevYearsAnnualSalesTotals.length - 1
                ].amount,
              )) *
            100
          ).toFixed(2),
    );
    setProductionGoal(value);
  };

  const handleProductionPercentGoalChange = (value) => {
    setProductionGoalPercent(value);
    setProductionGoal(
      Number.parseInt(
        prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
          .amount +
          prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
            .amount *
            (value / 100),
      ),
    );
  };

  const handleGoalSet = () => {
    setModalOpen(true);
    // handleGoalSetChange((prevState) => !prevState);
  };

  const renderPrevYearsProduction = () => {
    return prevYearsAnnualSalesTotals.map((salesObj) => {
      return (
        <Col key={salesObj.year}>
          <div className="statistics">
            <div className="info">
              <h6 className="stats-title">{salesObj.year} Total Production</h6>
              <h3 className="info-title">
                {salesObj.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </h3>
              <div>{salesObj.count} loans</div>
              {salesObj.salesAmountPercentChange ? (
                <div>{`${salesObj.salesAmountPercentChange} ${
                  salesObj.salesAmountPercentChange >= 0
                    ? "production decrease"
                    : "production increase"
                }`}</div>
              ) : (
                <br />
              )}
            </div>
          </div>
        </Col>
      );
    });
  };

  return (
    <Card style={{boxShadow: "none"}} className="card-stats card-raised">
      <CardBody>
        <Row md="12">
          {renderPrevYearsProduction()}

          <Col>
            <div className="statistics">
              <div className="info">
                <h6 className="stats-title">
                  Previous Year Average Loan Amount
                </h6>
                <h3 className="info-title">
                  {Number.parseInt(avgLoanAmount).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </h3>
                <br />
                <br />
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            padding: "4vh 0",
            margin: "4vh 1vw",
            border: "solid 3px #012c4b",
            boxShadow: "0 1px 10px 1px #012c4b",
          }}
        >
          <Col md={4}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(productionGoal / avgLoanAmount) ||
                  !Number.isFinite(productionGoal / avgLoanAmount)
                    ? "-"
                    : Math.ceil(productionGoal / avgLoanAmount)}
                </h3>
                <h6 className="stats-title">Loans Needed to meet goal</h6>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(productionGoal / 12)
                    ? 0
                    : Number.parseFloat(productionGoal / 12).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                </h3>
                <h6 className="stats-title">Production needed each month</h6>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(productionGoal / avgLoanAmount) ||
                  !Number.isFinite(productionGoal / avgLoanAmount)
                    ? "-"
                    : Math.round((productionGoal / avgLoanAmount / 12) * 100) /
                      100}
                </h3>
                <h6 className="stats-title">Loans needed each month</h6>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{marginTop: "50px"}}>
          <Col md="2">
            <FormGroup>
              <Label for="production-goal">
                <h6>Annual Production Goal</h6>
              </Label>
              <CurrencyInput
                className="form-control"
                style={{borderRadius: "5px"}}
                id="production-goal"
                prefix="$"
                placeholder=""
                value={productionGoal}
                defaultValue={0}
                decimalsLimit={0}
                onValueChange={(value) => handleProductionGoalChange(value)}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="percent-change">
                <h6>Percent Change from Last Year</h6>
              </Label>
              <InputGroup>
                <Input
                  type="number"
                  step={0.1}
                  style={{
                    borderRadius: "5px",
                  }}
                  id="percent-change"
                  placeholder=""
                  value={productionGoalPercent}
                  onChange={(e) =>
                    handleProductionPercentGoalChange(e.target.value)
                  }
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {goalSet ? (
            <Button
              disabled={goalSet}
              style={{
                cursor: "not-allowed",
                backgroundColor: "#ff2211",
                margin: "1vh 15px 1vh auto",
              }}
            >
              Goal Already Set
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#061f54",
                margin: "1vh 15px 1vh auto",
              }}
              onClick={handleGoalSet}
            >
              Review Goal
            </Button>
          )}
        </Row>
        <LoanOfficerSalesGoalConfirmationModal
          currentLoanOfficer={currentLoanOfficer}
          productionGoal={productionGoal}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          handleGoalSetChange={handleGoalSetChange}
          setCalculatorOpen={setCalculatorOpen}
        />
      </CardBody>
    </Card>
  );
};

export default LoanOfficerProductionCalculator;
