import AccountExecutiveFastFacts from "../fast-facts/account-executive/pages/AccountExecutiveFastFacts";
import AccountExecutiveSalesTimeline from "../sales/account-executive/pages/AccountExecutiveSalesTimeline";

let accountExecutiveRoutes = [
  {
    path: "/fast-facts",
    name: "Fast Facts",
    icon: "now-ui-icons design_app",
    component: AccountExecutiveFastFacts,
    layout: "/account-executive",
  },
  {
    collapse: true,
    name: "Sales",
    icon: "now-ui-icons business_chart-bar-32",
    state: "salesOpen",
    views: [
      {
        path: "/sales/sales-timeline",
        name: "Sales Timeline",
        mini: "ST",
        component: AccountExecutiveSalesTimeline,
        layout: "/account-executive",
      },
    ],
  },
];

export default accountExecutiveRoutes;
