import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

import "./Loading.css";

const Loading = ({
  color = "#061f54",
  width = 10,
  height = 25,
  margin = 15
}) => {
  return (
    <div className="loading-wrapper">
      <FadeLoader color={color} height={height} width={width} margin={margin} />
    </div>
  );
};

export default Loading;
