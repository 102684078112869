import React from "react";
import {Card, CardBody, Col} from "reactstrap";

const RegionalManagerAdditionalCommissionCard = ({loanOfficer}) => {
  return (
    <Col md="3">
      <Card>
        <CardBody style={{display: "flex"}}>
          <div style={{margin: "10px 0 0 40px"}}>
            <h6>{loanOfficer.name}</h6>
            <p>{`YTD Production: ${loanOfficer.thisYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            )}`}</p>
            <p>{`My Commission: ${loanOfficer.thisYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            )} x ${loanOfficer.commissionPercent}% = ${Number.parseInt(
              (loanOfficer.commissionPercent / 100) *
                loanOfficer.thisYearSalesTotal,
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegionalManagerAdditionalCommissionCard;
