import React from "react";

import "./SearchResultItem.css";

const CorrespondentAdminSearchResultItem = ({
  name,
  changeAccountExecutive,
  setIsOpen,
  setSearchTerm,
  currentIndex,
  givenIndex,
}) => {
  const handleClick = () => {
    changeAccountExecutive(name);
    setIsOpen(false);
    setSearchTerm(name);
  };
  return (
    <div
      className={
        givenIndex === currentIndex
          ? "search-result-item search-result-item__hover"
          : "search-result-item"
      }
      onClick={handleClick}
    >
      {name}
    </div>
  );
};

export default CorrespondentAdminSearchResultItem;
