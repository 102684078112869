import React, {useState, useEffect} from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const CorrespondentAdminPreviousMonthSalesHighlights = ({currentYearData}) => {
  const history = useHistory();
  const [lastYearData, setLastYearData] = useState({});

  const previousMonthIndex =
    new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;

  useEffect(() => {
    const fetchLastYearData = async () => {
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${
            new Date().getFullYear() - 1
          }`,
        );
        const json = await response.json();
        setLastYearData(json);
      } catch (error) {
        console.log(error);
      }
    };

    if (previousMonthIndex === 11) {
      fetchLastYearData();
    }
  }, [previousMonthIndex, setLastYearData]);

  const yearDataObject =
    previousMonthIndex === 11 ? lastYearData : currentYearData;

  const monthlySalesTotal = yearDataObject.encompassSales
    ? yearDataObject.encompassSales.monthlySalesTotals[previousMonthIndex].total
        .amount
    : 0;

  const monthlySalesCount = yearDataObject.encompassSales
    ? yearDataObject.encompassSales.monthlySalesTotals[previousMonthIndex].total
        .count
    : 0;

  const previousMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][previousMonthIndex];

  return (
    <Col xs={12} md={6}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${previousMonth} Sales`}</CardHeader>

        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {monthlySalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${previousMonth} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{monthlySalesCount}</h3>
                  <h6 className="stats-title">{`${previousMonth} Sales Count`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/correspondent-admin/sales/encompass-metrics");
            }}
          >
            See More Sales
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default CorrespondentAdminPreviousMonthSalesHighlights;
