import React from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Row } from "reactstrap";
import Spinner from "../../../components/NowUI/Spinner/Spinner";
import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import RegionalManagerBranchesList from "../components/RegionalManagerBranchesList";
import RegionalManagerMyRegionRankings from "../components/RegionalManagerMyRegionRankings";
import RegionalManagerMyPipelineHighlights from "../components/RegionalManagerMyPipelineHighlights";
const RegionalManagerMyRegion = ({
  initLoading,
  currentLoanOfficer,
  currentYearData,
  regionData,
  branchData
}) => {
  const auth = useAuth();

  // Redirect when logged out
  // useEffect(() => {
  //   if (auth.user === false) {
  //     history.push("/login");
  //   }
  // }, [auth.signedIn, auth.user, history]);

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <PanelHeader size="sm" />
          <div className="content">
            <Row>
              <RegionalManagerMyPipelineHighlights regionData={regionData} />
            </Row>
            <Row>
              <RegionalManagerBranchesList regionData={regionData} />
            </Row>
            <Row>
              <RegionalManagerMyRegionRankings regionData={regionData} />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default RegionalManagerMyRegion;
