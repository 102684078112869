import React from "react";
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";
import Select from "react-select";

const LoanOfficerEncompassMetricsHighlights = ({
  currentLoanOfficer,
  timeFrame,
  timeFrameLabel,
  handleTimeFrameChange,
  setSelectedZipIndex,
}) => {
  const selectOptions = [
    {value: "YTD", label: "YTD"},
    {value: "3 Months", label: "3 Months"},
    {value: "6 Months", label: "6 Months"},
    {value: "1 Year", label: "1 Year"},
    {value: "Lifetime", label: "Lifetime"},
  ];

  const renderZips = () => {
    return timeFrame.top3Zips.length > 0
      ? timeFrame.top3Zips.slice(0, 3).map((zip, index) => {
          return (
            <p
              style={{
                display: "inline",
                padding: "0 10px",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "25px",
              }}
              key={zip.zip}
              onClick={() => setSelectedZipIndex(index)}
            >
              {zip.zip}
            </p>
          );
        })
      : "-";
  };

  return (
    <Col xs={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <p style={{display: "inline-block"}}>
              {`${
                currentLoanOfficer.name.endsWith("s")
                  ? `${currentLoanOfficer.name}'`
                  : `${currentLoanOfficer.name}'s`
              } Encompass Metrics`}
            </p>
            <div style={{width: "12vw"}}>
              <Select
                className="primary"
                placeholder={`Time Frame: ${timeFrameLabel}`}
                name="singleSelect"
                value={timeFrameLabel}
                options={selectOptions}
                onChange={(value) => handleTimeFrameChange(value.value)}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {Number.parseFloat(
                      timeFrame.totalMonthlyIncome,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">
                    Your Average Combined Monthly Income
                  </h6>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {Number.parseFloat(
                      timeFrame.averageLoanAmount,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">Your Average Loan Amount</h6>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{timeFrame.averageAge}</h3>
                  <h6 className="stats-title">Your Average Borrower Age</h6>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{renderZips()}</h3>
                  <h6 className="stats-title">Your Most Popular Zip Codes</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerEncompassMetricsHighlights;
