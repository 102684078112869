import React from "react";
import {useAuth} from "../../../hooks/use-auth";
import {Row} from "reactstrap";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import AccountExecutiveFastFactsChart from "../components/AccountExecutiveFastFactsChart";
import AccountExecutiveCompanyWideAnnualSalesHighlights from "../components/AccountExecutiveCompanyWideSalesHighlights";
import AccountExecutiveAnnualSalesHighlights from "../components/AccountExecutiveAnnualSalesHighlights";
import AccountExecutivePreviousMonthSalesHighlights from "../components/AccountExecutivePreviousMonthSalesHighlights";
import AccountExecutiveCurrentMonthSalesHighlights from "../components/AccountExecutiveCurrentMonthSalesHighlights";
// import AccountExecutiveNextMonthSalesHighlights from "../components/AccountExecutiveNextMonthSalesHighlights";

const AccountExecutiveFastFacts = ({
  currentAccountExecutive,
  currentYearData,
}) => {
  const auth = useAuth();

  const mostRecentYear = new Date().getFullYear();

  const mostRecentMonth = new Date().getMonth() + 1;

  return (
    <>
      {auth.user === null && <Spinner />}
      <AccountExecutiveFastFactsChart
        currentAccountExecutive={currentAccountExecutive}
        mostRecentYear={mostRecentYear}
        mostRecentMonth={mostRecentMonth}
      />
      <div className="content">
        <Row>
          <AccountExecutiveCompanyWideAnnualSalesHighlights
            currentYearData={currentYearData}
          />
        </Row>
        <Row>
          <AccountExecutiveAnnualSalesHighlights
            currentAccountExecutive={currentAccountExecutive}
          />
        </Row>
        <Row>
          <AccountExecutivePreviousMonthSalesHighlights
            currentAccountExecutive={currentAccountExecutive}
          />
          <AccountExecutiveCurrentMonthSalesHighlights
            currentAccountExecutive={currentAccountExecutive}
          />
        </Row>
        <Row>
          {/* <AccountExecutiveNextMonthSalesHighlights
            currentAccountExecutive={currentAccountExecutive}
          /> */}
        </Row>
      </div>
    </>
  );
};

export default AccountExecutiveFastFacts;
