import React from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../../hooks/use-auth.js";

const LogoutButton = () => {
  const history = useHistory();
  const auth = useAuth();

  return (
    <li>
      <a
        href="/"
        onClick={async (e) => {
          e.preventDefault();
          await auth.signout();
          history.push("/login");
        }}
      >
        <span className="sidebar-mini-icon">LO</span>
        <span className="sidebar-normal">Log out</span>
      </a>
    </li>
  );
};

export default LogoutButton;
