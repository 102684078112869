import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import LoanOfficerEncompassMetricsComparisonCard from "../components/LoanOfficerEncompassMetricsComparisonCard";
import LoanOfficerEncompassMetricsHighlights from "../components/LoanOfficerEncompassMetricsHighlights";
import LoanOfficerTop3Zips from "../components/LoanOfficerTop3Zips";
// import LoanOfficerZipSalesCard from "../components/LoanOfficerZipSalesCard";
import LoanOfficerTop5Referrals from "../components/LoanOfficerTop5Referrals";
import LoanOfficerMyPipelineTable from "../components/LoanOfficerMyPipelineTable";

const LoanOfficerEncompassMetrics = ({ currentLoanOfficer }) => {
  const [timeFrame, setTimeFrame] = useState(
    currentLoanOfficer.encompassMetrics.ytdMetrics
  );

  const [timeFrameLabel, setTimeFrameLabel] = useState("YTD");

  const [selectedZipIndex, setSelectedZipIndex] = useState(0);

  useEffect(() => {
    setTimeFrame(currentLoanOfficer.encompassMetrics.ytdMetrics);
    setTimeFrameLabel("YTD");
    setSelectedZipIndex(0);
  }, [currentLoanOfficer]);

  const handleTimeFrameChange = (value) => {
    switch (value) {
      case "YTD": {
        setTimeFrame(currentLoanOfficer.encompassMetrics.ytdMetrics);
        setTimeFrameLabel("YTD");
        break;
      }
      case "3 Months": {
        setTimeFrame(currentLoanOfficer.encompassMetrics.threeMonthMetrics);
        setTimeFrameLabel("3 Months");
        break;
      }
      case "6 Months": {
        setTimeFrame(currentLoanOfficer.encompassMetrics.sixMonthMetrics);
        setTimeFrameLabel("6 Months");
        break;
      }
      case "1 Year": {
        setTimeFrame(currentLoanOfficer.encompassMetrics.oneYearMetrics);
        setTimeFrameLabel("1 Year");
        break;
      }
      case "Lifetime": {
        setTimeFrame(currentLoanOfficer.encompassMetrics.lifetimeMetrics);
        setTimeFrameLabel("Lifetime");
        break;
      }
      default: {
      }
    }
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <LoanOfficerEncompassMetricsHighlights
            currentLoanOfficer={currentLoanOfficer}
            timeFrame={timeFrame}
            timeFrameLabel={timeFrameLabel}
            handleTimeFrameChange={handleTimeFrameChange}
            setSelectedZipIndex={setSelectedZipIndex}
          />
        </Row>
        <Row>
          <LoanOfficerMyPipelineTable currentLoanOfficer={currentLoanOfficer} />
        </Row>
        <Row>
          <LoanOfficerTop5Referrals
            currentLoanOfficer={currentLoanOfficer}
            referrals={timeFrame.top5Referrals}
            count={6}
            withLink={false}
          />
        </Row>
        <Row>
          <LoanOfficerTop3Zips
            currentLoanOfficer={currentLoanOfficer}
            zips={timeFrame.top3Zips}
          />
        </Row>
        <Row>
          <LoanOfficerEncompassMetricsComparisonCard
            zips={timeFrame.top3Zips}
            selectedZipIndex={selectedZipIndex}
          />
        </Row>
        {/* <Row>
          <LoanOfficerZipSalesCard
            zips={timeFrame.top3Zips}
            selectedZipIndex={selectedZipIndex}
          />
        </Row> */}
      </div>
    </>
  );
};

export default LoanOfficerEncompassMetrics;
