import React from "react";
import {Card, CardBody, CardHeader, Row, Col, Label, Input} from "reactstrap";

import MoneyBag from "../../../assets/icons/Essex_Icons_Money Bag.svg";
import Closing from "../../../assets/icons/Essex_Icons_Closing.svg";

const BranchManagerSalesTimelineControls = ({
  currentLoanOfficer,
  currentMonth,
  currentYear,
  setCurrentMonth,
  setCurrentYear,
  dataFor,
  branchData,
}) => {
  let yearOptions = [];
  let monthOptions =
    currentYear === new Date().getFullYear()
      ? [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ].slice(0, new Date().getMonth() + 2)
      : [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ];

  let currentYearSales = [];
  let dataForLabel = "";

  switch (dataFor) {
    case "Branch": {
      currentYearSales = branchData.branchSales.find(
        (element) => element.year === currentYear,
      );
      dataForLabel = "My Branch";
      yearOptions = branchData.branchSales
        .filter((salesObject) => salesObject.year !== 0)
        .map((salesObject) => {
          return {
            value: salesObject.year,
            label: salesObject.year.toString(),
          };
        });
      break;
    }
    case "Individual": {
      currentYearSales =
        currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        );
      dataForLabel = currentLoanOfficer.name.endsWith("s")
        ? `${currentLoanOfficer.name}'`
        : `${currentLoanOfficer.name}'s`;
      yearOptions = currentLoanOfficer.encompassMetrics.encompassSales
        .filter((salesObject) => salesObject.year !== 0)
        .map((salesObject) => {
          return {
            value: salesObject.year,
            label: salesObject.year.toString(),
          };
        });
      break;
    }
    default:
  }

  const changeYear = (year) => {
    setCurrentMonth(100);
    setCurrentYear(Number.parseInt(year));
  };

  const annualSalesTotal =
    currentYearSales === undefined
      ? 0
      : currentYearSales.annualSalesTotals.total.amount;

  const monthlySalesTotal =
    currentMonth === 100 || currentYearSales === undefined
      ? 0
      : currentYearSales.monthlySalesTotals[currentMonth].total.amount;

  const renderYearOptions = () => {
    return yearOptions.map((year) => (
      <option value={Number.parseInt(year.value)}>{year.label}</option>
    ));
  };

  const renderMonthOptions = () => {
    return monthOptions.map((month) => (
      <option value={Number.parseInt(month.value)}>{month.label}</option>
    ));
  };

  return (
    <>
      <Col lg={12}>
        <Card className="card-stats card-raised">
          <CardHeader className="card-category">
            {dataForLabel} {currentMonth === 100 ? "Annual" : "Monthly"} Sales
            for{" "}
            {monthOptions.find((month) => month.value === currentMonth).label}{" "}
            {currentYear}
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="2">
                <Label for="monthSelect">Month</Label>
                <Input
                  className="primary"
                  type="select"
                  value={currentMonth}
                  onChange={(e) =>
                    setCurrentMonth(Number.parseInt(e.target.value))
                  }
                >
                  {renderMonthOptions()}
                </Input>

                <Label for="yearSelect">Year</Label>

                <Input
                  type="select"
                  value={currentYear}
                  onChange={(e) => changeYear(e.target.value)}
                >
                  {renderYearOptions()}
                </Input>
              </Col>
              <Col md="5">
                <div className="statistics">
                  <div className="info">
                    <div className="icon icon-info">
                      <img src={MoneyBag} alt="money" />
                    </div>
                    <h3 className="info-title">
                      {annualSalesTotal.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h3>
                    <h6 className="stats-title">{currentYear} Sales Total</h6>
                  </div>
                </div>
              </Col>
              {currentMonth !== 100 && (
                <Col md="5">
                  <div className="statistics">
                    <div className="info">
                      <div className="icon icon-info">
                        <img src={Closing} alt="money" />
                      </div>
                      <h3 className="info-title">
                        {monthlySalesTotal.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </h3>
                      <h6 className="stats-title">
                        {`${
                          monthOptions.find(
                            (month) => month.value === currentMonth,
                          ).label
                        } ${currentYear}
                        Sales Total`}
                      </h6>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default BranchManagerSalesTimelineControls;
