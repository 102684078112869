import React from "react";
import {Line} from "react-chartjs-2";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";

const CorrespondentAdminSalesTimelineMainChart = ({
  currentYearData,
  secondYearData,
}) => {
  const sameYear =
    currentYearData.encompassSales && secondYearData.encompassSales
      ? currentYearData.encompassSales.year ===
        secondYearData.encompassSales.year
      : false
      ? true
      : false;

  const annualSalesAmounts = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals.map(
        (element) => element.total.amount,
      )
    : [];

  const annualSalesCounts = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals.map(
        (element) => element.total.count,
      )
    : [];

  const secondYearAnnualSalesAmounts = secondYearData.encompassSales
    ? secondYearData.encompassSales.monthlySalesTotals.map(
        (element) => element.total.amount,
      )
    : [];

  const secondYearAnnualSalesCounts = secondYearData.encompassSales
    ? secondYearData.encompassSales.monthlySalesTotals.map(
        (element) => element.total.count,
      )
    : [];

  const monthsAbbreviations = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const chartOptions = {
    // onClick: (event, element) => {
    //   if (element.length > 0) {
    //     const index = element[0]._index;
    //     setCurrentMonth(index);
    //   }
    // },

    onHover: function (e) {
      var point = this.getElementAtEvent(e);
      if (point.length) e.target.style.cursor = "pointer";
      else e.target.style.cursor = "default";
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0,
      },
    },

    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "#fff",
      titleFontColor: "#333",
      titleFontSize: 16,
      bodyFontColor: "#666",
      bodyFontSize: 16,
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const dollarValue = parseInt(tooltipItem.value).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "USD",
            },
          );
          const loanCount =
            tooltipItem.datasetIndex === 0
              ? annualSalesCounts[tooltipItem.index]
              : secondYearAnnualSalesCounts[tooltipItem.index];
          return `${label} ${dollarValue}
          Count: ${loanCount}`;
        },
      },
    },
    legend: {
      position: "bottom",
      fillStyle: "#FFF",
      display: true,
      fontStyle: "bold",
      fontColor: "#eee",
    },
    title: {
      display: true,
      text: "Correspondent Annual Sales",
      fontStyle: "bold",
      fontColor: "#eee",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 4,
            padding: 10,
            callback: (value, index, values) => {
              return value.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
            },
          },
          gridLines: {
            drawTicks: true,
            drawBorder: false,
            display: true,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(255,255,255,0.1)",
          },
          ticks: {
            padding: 10,
            fontColor: "rgba(255,255,255,0.4)",
            fontStyle: "bold",
          },
        },
      ],
    },
  };

  const salesTimelinePanelChart = {
    data: (canvas) => {
      //   const ctx = canvas.getContext("2d");
      //   var chartColor = "#FFFFFF";
      //   var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      //   gradientStroke.addColorStop(0, "#80b6f4");
      //   gradientStroke.addColorStop(1, chartColor);
      //   var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      //   gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      //   gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: monthsAbbreviations,
        datasets: sameYear
          ? [
              {
                label: `${
                  currentYearData.encompassSales
                    ? currentYearData.encompassSales.year
                    : ""
                } Total Sales `,
                borderColor: "#fff",
                pointBorderColor: "#fff",
                pointBackgroundColor: "#fff",
                pointHitRadius: 25,
                pointHoverBackgroundColor: "#2c2c2c",
                pointHoverBorderColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                fill: false,
                backgroundColor: "#fff",
                borderWidth: 1,
                data: annualSalesAmounts,
              },
            ]
          : [
              {
                label: `${
                  currentYearData.encompassSales
                    ? currentYearData.encompassSales.year
                    : ""
                } Total Sales `,
                borderColor: "#fff",
                pointBorderColor: "#fff",
                pointBackgroundColor: "#fff",
                pointHitRadius: 25,
                pointHoverBackgroundColor: "#2c2c2c",
                pointHoverBorderColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                fill: false,
                backgroundColor: "#fff",
                borderWidth: 2,
                data: annualSalesAmounts,
              },
              {
                label: `${
                  secondYearData.encompassSales
                    ? secondYearData.encompassSales.year
                    : ""
                } Total Sales `,
                borderColor: "#3480eb",
                pointBorderColor: "#3480eb",
                pointBackgroundColor: "#3480eb",
                pointHitRadius: 25,
                pointHoverBackgroundColor: "#3480eb",
                pointHoverBorderColor: "#fff",
                pointBorderWidth: 2,
                pointHoverRadius: 7,
                pointHoverBorderWidth: 2,
                pointRadius: 6,
                fill: false,
                backgroundColor: "#3480eb",
                borderWidth: 2,
                borderStyle: "dashed",
                pointStyle: "triangle",
                data: secondYearAnnualSalesAmounts,
              },
            ],
      };
    },
    options: chartOptions,
  };
  return (
    <PanelHeader
      size="lg"
      content={
        <Line
          //   key={}
          data={salesTimelinePanelChart.data}
          options={salesTimelinePanelChart.options}
        />
      }
    />
  );
};

export default CorrespondentAdminSalesTimelineMainChart;
