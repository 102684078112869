import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Card,
  CardImg,
  CardTitle,
  CardBody
} from "reactstrap";

import DefaultHeadshot from "../../../../assets/images/default_headshot.png";
import "./MyBranch.css";

const MyBranch = ({
  currentLoanOfficer,
  myBranchModalOpen,
  changeCurrentLoanOfficer,
  toggleMyBranchModal
}) => {
  const handleClick = (email) => {
    changeCurrentLoanOfficer(email);
    toggleMyBranchModal();
  };

  const listMyBranchLoanOfficers = () => {
    if (currentLoanOfficer.branchMembers) {
      return currentLoanOfficer.branchMembers.map((loanOfficer) => {
        return (
          <Card className="my-branch-card" key={loanOfficer.name}>
            <CardImg
              top
              src={
                !loanOfficer.headshotURL
                  ? DefaultHeadshot
                  : loanOfficer.headshotURL
              }
              alt="headshot"
            />
            <CardBody>
              <CardTitle className="my-branch-card-name">
                {loanOfficer.name}
              </CardTitle>
              <CardTitle className="my-branch-card-title">
                {loanOfficer.title}
              </CardTitle>
              {loanOfficer.authTier !== "Regional Manager" && (
                <Button
                  className="my-branch-button"
                  onClick={() => handleClick(loanOfficer.email)}
                >
                  View as {loanOfficer.name}
                </Button>
              )}
            </CardBody>
          </Card>
        );
      });
    }
  };
  return (
    <Modal isOpen={myBranchModalOpen} toggle={toggleMyBranchModal} size="lg">
      <ModalHeader toggle={toggleMyBranchModal}>My Branch</ModalHeader>
      <ModalBody>
        <div className="my-branch-flex-wrapper">
          {listMyBranchLoanOfficers()}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MyBranch;
