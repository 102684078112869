import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";

import {ProvideAuth} from "./hooks/use-auth";
import Login from "./login/pages/Login";
import Admin from "./layouts/Admin/Admin";
import CorrespondentAdmin from "./layouts/CorrespondentAdmin/CorrespondentAdmin";
import LoanOfficer from "./layouts/LoanOfficer/LoanOfficer";
import RegionalManager from "./layouts/RegionalManager/RegionalManager";
import BranchManager from "./layouts/BranchManager/BranchManager";
import AccountExecutive from "./layouts/AccountExecutive/AccountExecutive";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/now-ui-dashboard.css";
import "./index.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter history={hist}>
      <ProvideAuth>
        <Switch>
          <Route
            path="/login"
            component={(props) => {
              return <Login {...props} />;
            }}
          />
          <Route
            path="/retail-admin"
            component={(props) => {
              return <Admin {...props} />;
            }}
          />
          <Route
            path="/correspondent-admin"
            component={(props) => {
              return <CorrespondentAdmin {...props} />;
            }}
          />
          <Route
            path="/regional-manager"
            component={(props) => {
              return <RegionalManager {...props} />;
            }}
          />
          <Route
            path="/branch-manager"
            component={(props) => {
              return <BranchManager {...props} />;
            }}
          />
          <Route
            path="/loan-officer"
            component={(props) => {
              return <LoanOfficer {...props} />;
            }}
          />
          <Route
            path="/account-executive"
            component={(props) => {
              return <AccountExecutive {...props} />;
            }}
          />
        </Switch>
        <Redirect to="/login" />
      </ProvideAuth>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
