import React from "react";
import {useAuth} from "../../../hooks/use-auth";
import {Row} from "reactstrap";

import LoanOfficerFastFactsChart from "../components/LoanOfficerFastFactsChart";
import LoanOfficerCompanyWideAnnualSalesHighlights from "../components/LoanOfficerCompanyWideAnnualSalesHighlights";
import LoanOfficerAnnualSalesHighlights from "../components/LoanOfficerAnnualSalesHighlights";
import LoanOfficerPreviousMonthSalesHighlights from "../components/LoanOfficerPreviousMonthSalesHighlights";
import LoanOfficerCurrentMonthSalesHighlights from "../components/LoanOfficerCurrentMonthSalesHighlights";
import LoanOfficerNextMonthSalesHighlights from "../components/LoanOfficerNextMonthSalesHighlights";
import Spinner from "../../../components/NowUI/Spinner/Spinner";
import LoanOfficerTopZipsCard from "../components/LoanOfficerTopZipsCard";
import LoanOfficerTop5Referrals from "../../../sales/loan-officer/components/LoanOfficerTop5Referrals";
import LoanOfficerSalesGoalsHighlights from "../../../sales/loan-officer/components/LoanOfficerSalesGoalsHighlights";

const LoanOfficerFastFacts = ({currentLoanOfficer, currentYearData}) => {
  const auth = useAuth();

  const mostRecentYear =
    !currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear(),
    )
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();

  const mostRecentMonth = 7;

  return (
    <>
      {auth.user === null && <Spinner />}
      <LoanOfficerFastFactsChart
        currentLoanOfficer={currentLoanOfficer}
        mostRecentYear={mostRecentYear}
        mostRecentMonth={mostRecentMonth}
      />
      <div className="content">
        <Row>
          <LoanOfficerCompanyWideAnnualSalesHighlights
            currentYearData={currentYearData}
          />
        </Row>
        <LoanOfficerSalesGoalsHighlights
          currentLoanOfficer={currentLoanOfficer}
          withRedirect
        />
        <Row>
          <LoanOfficerAnnualSalesHighlights
            currentLoanOfficer={currentLoanOfficer}
          />
        </Row>
        <Row>
          <LoanOfficerPreviousMonthSalesHighlights
            currentLoanOfficer={currentLoanOfficer}
          />
        </Row>
        <Row>
          <LoanOfficerCurrentMonthSalesHighlights
            currentLoanOfficer={currentLoanOfficer}
          />
        </Row>
        <Row>
          <LoanOfficerNextMonthSalesHighlights
            currentLoanOfficer={currentLoanOfficer}
          />
          <LoanOfficerTopZipsCard currentLoanOfficer={currentLoanOfficer} />
        </Row>
        <Row>
          <LoanOfficerTop5Referrals
            currentLoanOfficer={currentLoanOfficer}
            referrals={
              currentLoanOfficer.encompassMetrics.ytdMetrics.top5Referrals
            }
            count={3}
          />
        </Row>
      </div>
    </>
  );
};

export default LoanOfficerFastFacts;
