import React from "react";
import { Row } from "reactstrap";

import LoanOfficerSalesGoalsHighlights from "../components/LoanOfficerSalesGoalsHighlights";
import LoanOfficerSalesGoalCommissionBreakdown from "./LoanOfficerSalesGoalCommissionBreakdown";
import LoanOfficerSalesGoalAdditionalCommissionsTracker from "./LoanOfficerSalesGoalAdditionalCommissionsTracker";

const LoanOfficerSalesGoalTracker = ({ currentLoanOfficer }) => {
  return (
    <>
      <Row>
        <LoanOfficerSalesGoalsHighlights
          currentLoanOfficer={currentLoanOfficer}
        />
      </Row>
      <Row>
        <LoanOfficerSalesGoalCommissionBreakdown
          currentLoanOfficer={currentLoanOfficer}
        />
      </Row>
      {currentLoanOfficer.title !== "Loan Officer" &&
        currentLoanOfficer.salesGoals[new Date().getFullYear()]
          .additionalCommissions.length > 0 && (
          <Row>
            <LoanOfficerSalesGoalAdditionalCommissionsTracker
              currentLoanOfficer={currentLoanOfficer}
            />
          </Row>
        )}
    </>
  );
};

export default LoanOfficerSalesGoalTracker;
