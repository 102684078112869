import React from "react";
import { Card, CardBody, CardHeader, CardFooter, Col } from "reactstrap";

import ReactTable from "../../../components/NowUI/ReactTable/ReactTable";

const LoanOfficerMyPipelineTable = ({ currentLoanOfficer }) => {
  const loanMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ][new Date().getMonth()];

  const pipelineTotalLoanAmount = currentLoanOfficer.encompassMetrics
    .pipelineLoans
    ? currentLoanOfficer.encompassMetrics.pipelineLoans
        .filter((loan) => loan.fields["Loan.TotalLoanAmount"] > 0)
        .reduce(
          (previousValue, currentValue) =>
            previousValue +
            Number.parseInt(currentValue.fields["Loan.TotalLoanAmount"]),
          0
        )
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    : 0;

  const closedTotalLoanAmount = currentLoanOfficer.encompassMetrics
    .loansClosedThisMonth
    ? currentLoanOfficer.encompassMetrics.loansClosedThisMonth
        .filter((loan) => loan.fields["Loan.TotalLoanAmount"] > 0)
        .reduce(
          (previousValue, currentValue) =>
            previousValue +
            Number.parseInt(currentValue.fields["Loan.TotalLoanAmount"]),
          0
        )
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    : 0;

  const renderPipelineLoans = () => {
    if (currentLoanOfficer.encompassMetrics.pipelineLoans) {
      if (currentLoanOfficer.encompassMetrics.pipelineLoans.length > 0) {
        return (
          <ReactTable
            data={currentLoanOfficer.encompassMetrics.pipelineLoans.map(
              (loan, index) => {
                return {
                  borrower: loan.fields["Loan.BorrowerName"],
                  loanAmount: Number.isNaN(
                    Number.parseInt(loan.fields["Loan.TotalLoanAmount"])
                  )
                    ? "No loan amount yet."
                    : Number.parseInt(
                        loan.fields["Loan.TotalLoanAmount"]
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }),

                  lastModified: loan.fields["Loan.LastModified"],
                  lastFinishedMilestone:
                    loan.fields["Loan.CurrentMilestoneName"],
                  daysRemainingRateLock:
                    new Date(loan.fields["Fields.762"]).toLocaleDateString(
                      "en-US"
                    ) !== "Invalid Date"
                      ? new Date(loan.fields["Fields.762"]).toLocaleDateString(
                          "en-US"
                        )
                      : "No rate lock yet.",
                  estimatedClosingDate: new Date(
                    loan.fields["Fields.CX.PDEXFUND"]
                  ).toLocaleDateString("en-US")
                };
              }
            )}
            columns={[
              {
                Header: "Borrower",
                accessor: "borrower",
                disableFilters: false
              },
              {
                Header: "Total Loan Amount",
                accessor: "loanAmount",
                disableFilters: true
              },
              {
                Header: "Last Modified",
                accessor: "lastModified",
                disableFilters: true
              },
              {
                Header: "Current Milestone",
                accessor: "lastFinishedMilestone",
                disableFilters: true
              },
              {
                Header: "Rate Lock Expiration Date",
                accessor: "daysRemainingRateLock",
                disableFilters: true
              },
              {
                Header: "Estimated Funding Date",
                accessor: "estimatedClosingDate",
                disableFilters: true
              }
            ]}
          />
        );
      }
    } else {
      return (
        <h6 style={{ marginBottom: "25px", textAlign: "center" }}>
          No loans found in Encompass Pipeline.
        </h6>
      );
    }
  };

  const renderClosedLoans = () => {
    if (currentLoanOfficer.encompassMetrics.loansClosedThisMonth) {
      if (currentLoanOfficer.encompassMetrics.loansClosedThisMonth.length > 0) {
        return (
          <ReactTable
            data={currentLoanOfficer.encompassMetrics.loansClosedThisMonth.map(
              (loan, index) => {
                return {
                  borrower: loan.fields["Loan.BorrowerName"],
                  loanAmount: Number.parseInt(
                    loan.fields["Loan.TotalLoanAmount"]
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }),
                  appDate: new Date(
                    loan.fields["Fields.745"]
                  ).toLocaleDateString("en-US"),
                  closingDate: new Date(
                    loan.fields["Fields.748"]
                  ).toLocaleDateString("en-US"),
                  fundedDate: new Date(
                    loan.fields["Fields.MS.FUN"]
                  ).toLocaleDateString("en-US"),
                  typePurpose: `${loan.fields["Loan.LoanType"]} ${loan.fields["Loan.LoanPurpose"]}`,
                  daysToFund: Math.floor(
                    (new Date(loan.fields["Fields.MS.FUN"]) -
                      new Date(loan.fields["Fields.745"])) /
                      86400000
                  )
                };
              }
            )}
            columns={[
              {
                Header: "Borrower",
                accessor: "borrower",
                disableFilters: false
              },
              {
                Header: "Total Loan Amount",
                accessor: "loanAmount",
                disableFilters: true
              },
              {
                Header: "Application Date",
                accessor: "appDate",
                disableFilters: true
              },
              {
                Header: "Closing Date",
                accessor: "closingDate",
                disableFilters: true
              },
              {
                Header: "Funded Date",
                accessor: "fundedDate",
                disableFilters: true
              },
              {
                Header: "Loan Type / Purpose",
                accessor: "typePurpose",
                disableFilters: true
              },
              {
                Header: "Days To Fund",
                accessor: "daysToFund",
                disableFilters: true
              }
            ]}
          />
        );
      }
    } else {
      return (
        <h6 style={{ marginBottom: "25px", textAlign: "center" }}>
          No closed loans found in Encompass for this month.
        </h6>
      );
    }
  };

  return (
    <Col>
      <Card>
        <CardHeader className="card-category">{`${
          currentLoanOfficer.name.endsWith("s")
            ? `${currentLoanOfficer.name}'`
            : `${currentLoanOfficer.name}'s`
        } My Pipeline`}</CardHeader>
        <CardBody>
          <h6>Loans in My Pipeline</h6>
          {renderPipelineLoans()}
          <h6>{`Loans Funded in ${loanMonth}`}</h6>
          {renderClosedLoans()}
        </CardBody>
        <CardFooter>
          {currentLoanOfficer.encompassMetrics.pipelineLoans && (
            <div style={{ display: "flex" }}>
              <p className="text-muted">
                Last Updated
                <br /> {currentLoanOfficer.encompassMetrics.lastUpdated}
              </p>
              <div style={{ marginLeft: "auto" }}>
                <p style={{ marginBottom: "5px" }}>
                  {`Total Funded Loan Amount in ${loanMonth}`}
                </p>
                <h6>{closedTotalLoanAmount}</h6>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <p style={{ marginBottom: "5px" }}>
                  Total Loan Amount in My Pipeline
                </p>
                <h6>{pipelineTotalLoanAmount}</h6>
              </div>
            </div>
          )}
        </CardFooter>
      </Card>
    </Col>
  );
};

export default LoanOfficerMyPipelineTable;
