import React from "react";
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";

import Loading from "../../../components/NowUI/Loading/Loading";

const AdminMyPipelineHighlights = ({allPipelineLoans, loading}) => {
  const thisMonth = new Date().getMonth();

  const thisYear = new Date().getFullYear();

  const previousMonth = thisMonth === 0 ? 11 : thisMonth - 1;

  const previousYear = thisMonth === 0 ? thisYear - 1 : thisYear;

  const nextMonth = thisMonth === 11 ? 0 : thisMonth + 1;

  const nextYear = thisMonth === 11 ? thisYear + 1 : thisYear;

  // Only include loans that are expected to close previous, this, or next month
  const pipelineLoansReduced = allPipelineLoans.filter(
    (loan) =>
      (new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
        previousYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() ===
          previousMonth) ||
      (new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() === thisYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === thisMonth) ||
      (new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() === nextYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === nextMonth),
  );

  const totalLoanCount = pipelineLoansReduced.length;

  const loansExpectedToCloseThisMonth = pipelineLoansReduced.filter(
    (loan) =>
      new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() === thisYear &&
      new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === thisMonth,
  );

  const loansPastExpectedClosingDate = pipelineLoansReduced.filter(
    (loan) => new Date(loan.fields["Fields.CX.PDEXFUND"]) < new Date(),
  );

  return (
    <Col xs={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">Company Wide Pipeline</CardHeader>
        <CardBody>
          {loading ? (
            <Loading />
          ) : (
            <Row>
              <Col md="4">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">{totalLoanCount}</h3>
                    <h6 className="stats-title">Total Loans in My Pipeline</h6>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {loansExpectedToCloseThisMonth
                        .filter(
                          (loan) => loan.fields["Loan.TotalLoanAmount"] !== "",
                        )
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue +
                            Number.parseInt(
                              currentValue.fields["Loan.TotalLoanAmount"],
                            ),
                          0,
                        )
                        .toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </h3>
                    <h6 className="stats-title">
                      {`Loan $ Remaining Estimated To Close By EOM`}
                    </h6>
                    <h6 className="stats-title">
                      {`(${loansExpectedToCloseThisMonth.length} loans)`}
                    </h6>
                  </div>
                </div>
              </Col>

              <Col md="4">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {loansPastExpectedClosingDate
                        .filter(
                          (loan) => loan.fields["Loan.TotalLoanAmount"] !== "",
                        )
                        .reduce(
                          (previousValue, currentValue) =>
                            previousValue +
                            Number.parseInt(
                              currentValue.fields["Loan.TotalLoanAmount"],
                            ),
                          0,
                        )
                        .toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                    </h3>
                    <h6 className="stats-title">
                      {`Loan Amount in My Pipeline Past Expected To Close
                    Date`}
                    </h6>
                    <h6 className="stats-title">
                      {`(${loansPastExpectedClosingDate.length} loans)`}
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminMyPipelineHighlights;
