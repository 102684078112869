import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const LoanOfficerNextMonthSalesHighlights = ({currentLoanOfficer}) => {
  const history = useHistory();

  const nextMonthIndex =
    new Date().getMonth() === 11 ? 0 : new Date().getMonth() + 1;

  const thisYear =
    nextMonthIndex === 0
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();

  const monthLabel = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][nextMonthIndex];

  const loansExpectedToCloseNextMonth =
    currentLoanOfficer.encompassMetrics.pipelineLoans.filter(
      (loan) =>
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
          thisYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() ===
          nextMonthIndex,
    );

  return (
    <Col xs={6} md={6}>
      <Card className="card-stats card-raised" style={{minHeight: "30vh"}}>
        <CardHeader className="card-category">{`${
          currentLoanOfficer.name.endsWith("s")
            ? `${currentLoanOfficer.name}'`
            : `${currentLoanOfficer.name}'s`
        } ${monthLabel} Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseNextMonth
                      .filter(
                        (loan) => loan.fields["Loan.TotalLoanAmount"] !== "",
                      )
                      .reduce(
                        (previousValue, currentValue) =>
                          previousValue +
                          Number.parseInt(
                            currentValue.fields["Loan.TotalLoanAmount"],
                          ),
                        0,
                      )
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </h3>
                  <h6 className="stats-title">{`Sales Amount Estimated to fund in ${monthLabel}`}</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseNextMonth.length}
                  </h3>
                  <h6 className="stats-title">{`Loans Estimated to fund in ${monthLabel}`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "22px",
          }}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/loan-officer/sales/encompass-metrics");
            }}
          >
            See My Pipeline
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default LoanOfficerNextMonthSalesHighlights;
