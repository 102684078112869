import React, { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import BranchManagerSalesTimelineMainChart from "../components/BranchManagerSalesTimelineMainChart";
import BranchManagerSalesTimelineControls from "../components/BranchManagerSalesTimelineControls";
import BranchManagerMonthlyLoanPurposeCard from "../components/BranchManagerMonthlyLoanPurposeCard";
import BranchManagerMonthlyLoanTypeCard from "../components/BranchManagerMonthlyLoanTypeCard";
import BranchManagerBrokeredOutLoans from "../components/BranchManagerBrokeredOutLoans";
import BranchManagerSalesComparisonCard from "../components/BranchManagerSalesComparisonCard";

const BranchManagerSalesTimeline = ({
  initLoading,
  dataFor,
  currentLoanOfficer,
  branchData
}) => {
  const auth = useAuth();
  const location = useLocation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  useEffect(() => {
    if (location.state) {
      setCurrentMonth(location.state.redirectMonthIndex);
    }
  }, [location.state]);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
    setCurrentMonth(100);
  }, [dataFor]);

  // Redirect when logged out
  // useEffect(() => {
  //   if (auth.user === false) {
  //     history.push("/login");
  //   }
  // }, [auth.signedIn, auth.user, history]);

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <BranchManagerSalesTimelineMainChart
            currentLoanOfficer={currentLoanOfficer}
            setCurrentMonth={setCurrentMonth}
            currentYear={currentYear}
            dataFor={dataFor}
            branchData={branchData}
          />
          <div className="content">
            <Row>
              <BranchManagerSalesTimelineControls
                currentLoanOfficer={currentLoanOfficer}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
                dataFor={dataFor}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerMonthlyLoanPurposeCard
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                branchData={branchData}
              />
              <BranchManagerMonthlyLoanTypeCard
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerBrokeredOutLoans
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerSalesComparisonCard
                currentLoanOfficer={currentLoanOfficer}
                dataFor={dataFor}
                branchData={branchData}
              />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default BranchManagerSalesTimeline;
