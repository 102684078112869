import React from "react";
import {Col, Card, CardHeader, CardBody, CardFooter, Table} from "reactstrap";
import {Doughnut} from "react-chartjs-2";

const RegionalManagerMonthlyLoanPurposeCard = ({
  currentLoanOfficer,
  currentMonth,
  currentYear,
  dataFor,
  regionData,
  branchData,
}) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let currentSalesObject = {
    purchase: {amount: 0, count: 0},
    noCashRefinance: {amount: 0, count: 0},
    cashRefinance: {amount: 0, count: 0},
    otherPurpose: {amount: 0, count: 0},
    conventional: {amount: 0, count: 0},
    fha: {amount: 0, count: 0},
    va: {amount: 0, count: 0},
    usda: {amount: 0, count: 0},
    reverse: {amount: 0, count: 0},
    brokered: {amount: 0, count: 0},
    otherType: {amount: 0, count: 0},
    total: {amount: 0, count: 0},
  };
  let dataForLabel = "";
  switch (dataFor) {
    case "Region": {
      currentSalesObject =
        currentMonth === 100
          ? regionData.regionSales.find(
              (element) => element.year === currentYear,
            ).annualSalesTotals
          : regionData.regionSales.find(
              (element) => element.year === currentYear,
            ).monthlySalesTotals[currentMonth];
      dataForLabel = "My Region";
      break;
    }
    case "Branch": {
      currentSalesObject =
        currentMonth === 100
          ? branchData.branchSales.find(
              (element) => element.year === currentYear,
            ).annualSalesTotals
          : branchData.branchSales.find(
              (element) => element.year === currentYear,
            ).monthlySalesTotals[currentMonth];
      dataForLabel = "My Branch";
      break;
    }
    case "Individual": {
      if (
        currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ) !== undefined
      ) {
        currentSalesObject =
          currentMonth === 100
            ? currentLoanOfficer.encompassMetrics.encompassSales.find(
                (element) => element.year === currentYear,
              ).annualSalesTotals
            : currentLoanOfficer.encompassMetrics.encompassSales.find(
                (element) => element.year === currentYear,
              ).monthlySalesTotals[currentMonth];
      }
      dataForLabel = currentLoanOfficer.name.endsWith("s")
        ? `${currentLoanOfficer.name}'`
        : `${currentLoanOfficer.name}'s`;
      break;
    }
    default:
  }

  const dashboardMonthlyLoanPurposeDoughnutChart = {
    data: {
      labels: [
        "Purchase",
        "No Cash Out Refinance",
        "Cash Out Refinance",
        "Other Purpose",
      ],
      datasets: [
        {
          label: "Loan Purpose Sales Amount",
          data: [
            currentSalesObject.purchase.amount,
            currentSalesObject.noCashRefinance.amount,
            currentSalesObject.cashRefinance.amount,
            currentSalesObject.otherPurpose.amount,
          ],
          backgroundColor: [
            "#212d64",
            "#66a7dd",
            "#b33633",
            "#9ecf61",
            "#535759",
            "#303b4f",
          ],
          borderColor: ["#fff"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // const label = data.datasets[tooltipItem.datasetIndex].label;
            const label = data.labels[tooltipItem.index];

            const dollarValue = parseInt(
              data.datasets[0].data[tooltipItem.index],
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
            return `${label}: ${dollarValue}`;
          },
        },
      },
      legend: {
        onHover: function (e) {
          e.target.style.cursor = "pointer";
        },
        onLeave: function (e) {
          e.target.style.cursor = "default";
        },
        position: "right",
        labels: {
          fontSize: 15,
        },
      },
    },
  };

  const toDollarString = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <Col xs={12} md={6}>
      <Card className="card-chart" style={{minHeight: "90vh"}}>
        <CardHeader className="card-category">
          {dataForLabel}{" "}
          {currentMonth === 100
            ? currentYear
            : `${MONTHS[currentMonth]} ${currentYear}`}{" "}
          Loan Purposes
        </CardHeader>
        <CardBody>
          <Doughnut
            key={currentMonth}
            data={dashboardMonthlyLoanPurposeDoughnutChart.data}
            options={dashboardMonthlyLoanPurposeDoughnutChart.options}
          />
          <Table responsive>
            <tbody>
              {currentSalesObject.purchase.count > 0 && (
                <tr>
                  <td>Purchase</td>
                  <td className="text-right">
                    {currentSalesObject.purchase.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.purchase.amount)}
                  </td>
                </tr>
              )}

              {currentSalesObject.noCashRefinance.count > 0 && (
                <tr>
                  <td>No Cash Refinance</td>
                  <td className="text-right">
                    {currentSalesObject.noCashRefinance.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.noCashRefinance.amount)}
                  </td>
                </tr>
              )}

              {currentSalesObject.cashRefinance.count > 0 && (
                <tr>
                  <td>Cash Out Refinance</td>
                  <td className="text-right">
                    {currentSalesObject.cashRefinance.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.cashRefinance.amount)}
                  </td>
                </tr>
              )}

              {currentSalesObject.total.count > 0 && (
                <tr>
                  <td>Total</td>
                  <td className="text-right">
                    {currentSalesObject.total.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.total.amount)}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Col>
  );
};

export default RegionalManagerMonthlyLoanPurposeCard;
