import React from "react";

import "./SearchResultItem.css";

const SearchResultItem = ({
  name,
  email,
  changeCurrentLoanOfficer,
  setIsOpen,
  setSearchTerm,
  currentIndex,
  givenIndex,
}) => {
  const handleClick = () => {
    changeCurrentLoanOfficer(email);
    setIsOpen(false);
    setSearchTerm(name);
  };
  return (
    <div
      className={
        givenIndex === currentIndex
          ? "search-result-item search-result-item__hover"
          : "search-result-item"
      }
      onClick={handleClick}
    >
      {name}
    </div>
  );
};

export default SearchResultItem;
