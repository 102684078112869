import React, {useState, useEffect} from "react";
import SearchResultItem from "../SearchResultItem/SearchResultItem";

import "./SearchResultsList.css";

const SearchResultsList = ({
  matchedLoanOfficers,
  changeCurrentLoanOfficer,
  setSearchTerm,
  currentIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasMatch = matchedLoanOfficers.length > 0 ? true : false;

    setIsOpen(hasMatch);
  }, [matchedLoanOfficers]);

  const renderList = () => {
    return (
      <div className="search-results-container fade-in">
        {matchedLoanOfficers.map((loanOfficer, index) => (
          <SearchResultItem
            key={loanOfficer.email}
            name={loanOfficer.name}
            email={loanOfficer.email}
            changeCurrentLoanOfficer={changeCurrentLoanOfficer}
            setSearchTerm={setSearchTerm}
            setIsOpen={setIsOpen}
            currentIndex={currentIndex}
            givenIndex={index}
          />
        ))}
      </div>
    );
  };
  return <>{isOpen && renderList()}</>;
};

export default SearchResultsList;
