import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import Spinner from "../../components/NowUI/Spinner/Spinner";
import {useAuth} from "../../hooks/use-auth";

import EssexLogo from "../../assets/images/Essex_Color.png";
import GoogleLoginButton from "../../assets/images/btn_google_signin_light_pressed_web@2x.png";
import "./Login.css";

const Login = () => {
  const history = useHistory();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [noAuth, setNoAuth] = useState(false);

  useEffect(() => {
    const getAuth = async () => {
      setLoading(true);
      const response = await fetch(
        `https://us-central1-essexdashboard.cloudfunctions.net/getAuthLevel?email=${auth.user.email}`,
      );
      const json = await response.json();

      setLoading(false);
      if (json.authLevel === "Retail Admin") {
        history.push({
          pathname: "/retail-admin/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "Correspondent Admin") {
        history.push({
          pathname: "/correspondent-admin/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "Regional Manager") {
        history.push({
          pathname: "/regional-manager/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "Branch Manager") {
        history.push({
          pathname: "/branch-manager/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "Loan Officer") {
        history.push({
          pathname: "/loan-officer/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "Account Executive") {
        history.push({
          pathname: "/account-executive/fast-facts",
          state: {
            initialEmail: auth.user.email,
            initialName: auth.user.displayName,
            photoURL: auth?.user?.photoURL,
          },
        });
      } else if (json.authLevel === "No Auth") {
        setLoading(false);
        setNoAuth(true);
      }
    };

    if (auth.user) {
      getAuth(auth);
    }
  }, [auth.user, history, auth]);

  return (
    <div className="login__background">
      <div className="login__card">
        <img src={EssexLogo} alt="Essex Logo" />
        {noAuth === false ? (
          <>
            <h1 className="login__card-title">Welcome to Essex Path</h1>
            <h3 className="login__card-quote">Which path will you take?</h3>
            <img
              src={GoogleLoginButton}
              className="login__google-button"
              onClick={auth.signin}
              alt="Login with Google"
            />
            {(auth.user === null || loading) && (
              <Spinner title="Authenticating" />
            )}
          </>
        ) : (
          <>
            <h3>No Account Found</h3>
            <p>Request access from ztapia@essexmortgage.com</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
