import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const RegionalManagerNextMonthSalesHighlights = ({
  dataFor,
  regionData,
  branchData,
  currentLoanOfficer,
}) => {
  const history = useHistory();

  const nextMonthIndex =
    new Date().getMonth() === 11 ? 0 : new Date().getMonth() + 1;

  const thisYear =
    nextMonthIndex === 0
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();

  let loansExpectedToCloseNextMonth =
    currentLoanOfficer.encompassMetrics.pipelineLoans.filter(
      (loan) =>
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
          thisYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() ===
          nextMonthIndex,
    );

  let loanTotalExpectedToCloseNextMonth = 0;
  let dataForLabel = "";
  switch (dataFor) {
    case "Region": {
      loanTotalExpectedToCloseNextMonth =
        regionData.encompassMetrics.loansExpectedToCloseNextMonth.amount;
      loansExpectedToCloseNextMonth =
        regionData.encompassMetrics.loansExpectedToCloseNextMonth.count;

      dataForLabel = "My Region";
      break;
    }
    case "Branch": {
      loanTotalExpectedToCloseNextMonth =
        branchData.encompassMetrics.loansExpectedToCloseNextMonth.amount;
      loansExpectedToCloseNextMonth =
        branchData.encompassMetrics.loansExpectedToCloseNextMonth.count;
      dataForLabel = "My Branch";
      break;
    }
    case "Individual": {
      loanTotalExpectedToCloseNextMonth = loansExpectedToCloseNextMonth
        .filter((loan) => loan.fields["Loan.TotalLoanAmount"] !== "")
        .reduce(
          (previousValue, currentValue) =>
            previousValue +
            Number.parseInt(currentValue.fields["Loan.TotalLoanAmount"]),
          0,
        );

      loansExpectedToCloseNextMonth =
        currentLoanOfficer.encompassMetrics.pipelineLoans.filter(
          (loan) =>
            new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
              thisYear &&
            new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() ===
              nextMonthIndex,
        ).length;

      dataForLabel = currentLoanOfficer.name.endsWith("s")
        ? `${currentLoanOfficer.name}'`
        : `${currentLoanOfficer.name}'s`;
      break;
    }
    default:
  }
  const monthLabel = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][nextMonthIndex];

  return (
    <Col md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${dataForLabel} ${monthLabel} Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loanTotalExpectedToCloseNextMonth.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`Sales Amount Estimated to fund in ${monthLabel}`}</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseNextMonth}
                  </h3>
                  <h6 className="stats-title">{`Loans Estimated to fund in ${monthLabel}`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/regional-manager/sales/encompass-metrics");
            }}
          >
            See My Pipeline
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default RegionalManagerNextMonthSalesHighlights;
