import React, { useState } from "react";
import { Col, Card, CardHeader, CardBody, Row, Label } from "reactstrap";
// import AdminBranchCard from "./AdminBranchCard";
import Select from "react-select";
import BranchManagerLoanOfficerCard from "./BranchManagerLoanOfficerCard";

const RegionalManagerBranchesList = ({ branchData }) => {
  const allLoanOfficers = branchData.branchMembers;
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  const renderLoanOfficers = () => {
    return allLoanOfficers.map((loanOfficer) => {
      return (
        <BranchManagerLoanOfficerCard
          loanOfficer={loanOfficer}
          month={month}
          setMonth={setMonth}
          year={year}
          key={loanOfficer.name}
        />
      );
    });
  };

  const yearOptions = [
    { value: 2019, label: "2019" },
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
  ];

  return (
    <Col md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`My Branch`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <Label for="yearSelect">Year</Label>
              <Select
                className="primary"
                placeholder={year}
                name="yearSelect"
                value={year}
                options={yearOptions}
                onChange={(value) => setYear(parseInt(value.value))}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>{renderLoanOfficers()}</Row>
    </Col>
  );
};

export default RegionalManagerBranchesList;
