import React, {useState, useEffect} from "react";
import {Row} from "reactstrap";
import {useLocation} from "react-router-dom";

import AccountExecutiveSalesTimelineMainChart from "../components/AccountExecutiveSalesTimelineMainChart";
import AccountExecutiveSalesTimelineControls from "../components/AccountExecutiveSalesTimelineControls";
import AccountExecutiveMonthlyLoanPurposeCard from "../components/AccountExecutiveMonthlyLoanPurposeCard";
import AccountExecutiveMonthlyLoanTypeCard from "../components/AccountExecutiveMonthlyLoanTypeCard";
import AccountExecutiveSalesComparisonCard from "../components/AccountExecutiveSalesComparisonCard";
import AccountExecutiveMonthlyPropertyTypeCard from "../components/AccountExecutiveMonthlyPropertyTypeCard";

const SalesTimeline = ({currentAccountExecutive}) => {
  const location = useLocation();

  const [currentYear, setCurrentYear] = useState(
    currentAccountExecutive.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear(),
    ) === undefined
      ? new Date().getFullYear() - 1
      : new Date().getFullYear(),
  );

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  useEffect(() => {
    if (location.state) {
      setCurrentMonth(location.state.redirectMonthIndex);
    }
  }, [location.state]);

  return (
    <>
      <AccountExecutiveSalesTimelineMainChart
        currentAccountExecutive={currentAccountExecutive}
        setCurrentMonth={setCurrentMonth}
        currentYear={currentYear}
      />
      <div className="content">
        <Row>
          <AccountExecutiveSalesTimelineControls
            currentAccountExecutive={currentAccountExecutive}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </Row>
        <Row>
          <AccountExecutiveMonthlyLoanPurposeCard
            currentAccountExecutive={currentAccountExecutive}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
          <AccountExecutiveMonthlyLoanTypeCard
            currentAccountExecutive={currentAccountExecutive}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
        </Row>
        <Row>
          <AccountExecutiveMonthlyPropertyTypeCard
            currentAccountExecutive={currentAccountExecutive}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
        </Row>

        <Row>
          <AccountExecutiveSalesComparisonCard
            currentAccountExecutive={currentAccountExecutive}
          />
        </Row>
      </div>
    </>
  );
};

export default SalesTimeline;
