import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const LoanOfficerCurrentMonthSalesHighlights = ({currentLoanOfficer}) => {
  const history = useHistory();

  const thisMonth = new Date().getMonth();

  const thisYear = new Date().getFullYear();

  const currentYearSales =
    currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear(),
    );

  const currentMonthSales =
    currentYearSales.monthlySalesTotals[new Date().getMonth()];

  const monthlySalesTotal = currentMonthSales.total.amount;
  const monthlySalesCount = currentMonthSales.total.count;

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][new Date().getMonth()];

  const loansExpectedToCloseThisMonth =
    currentLoanOfficer.encompassMetrics.pipelineLoans.filter(
      (loan) =>
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
          thisYear &&
        new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === thisMonth,
    );

  const toOrdinal = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num;
  };

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${
          currentLoanOfficer.name.endsWith("s")
            ? `${currentLoanOfficer.name}'`
            : `${currentLoanOfficer.name}'s`
        } ${month} Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {monthlySalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${month} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{monthlySalesCount}</h3>
                  <h6 className="stats-title">{`${month} Loan Count`}</h6>
                </div>
              </div>
            </Col>

            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {currentLoanOfficer.rankings
                      ? toOrdinal(currentLoanOfficer.rankings.currentMonth)
                      : "-"}
                  </h3>
                  <h6 className="stats-title">{`${month} Production Ranking`}</h6>
                </div>
              </div>
            </Col>

            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {loansExpectedToCloseThisMonth
                      .filter(
                        (loan) => loan.fields["Loan.TotalLoanAmount"] !== "",
                      )
                      .reduce(
                        (previousValue, currentValue) =>
                          previousValue +
                          Number.parseInt(
                            currentValue.fields["Loan.TotalLoanAmount"],
                          ),
                        0,
                      )
                      .toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                  </h3>
                  <h6 className="stats-title">{`Remaining Sales Estimated to fund in ${month}`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/loan-officer/sales/encompass-metrics");
            }}
          >
            See My Pipeline
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default LoanOfficerCurrentMonthSalesHighlights;
