import React, {useState, useEffect} from "react";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";

const AdminEncompassMetricsTopZips = ({currentYearData}) => {
  const [top3ByZip, setTop3ByZip] = useState([]);

  useEffect(() => {
    const fetchTopLoanOfficers = async () => {
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getTopLoanOfficersForZip?zips=${currentYearData.ytdMetrics.top25Zips
            .slice(0, 3)
            .map((zip) => zip.zip)
            .join()}`,
        );

        const json = await response.json();

        setTop3ByZip(json);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTopLoanOfficers();
  }, [currentYearData.ytdMetrics]);

  const renderRows = (zip) => {
    const zipObj = top3ByZip.find((element) => element.zip === zip);
    if (zipObj) {
      return zipObj.top3.map((loanOfficer) => {
        return (
          <tr key={loanOfficer.name}>
            <td>{loanOfficer.name}</td>
            <td>{`${loanOfficer.count} loans`}</td>
            <td>
              {Number.parseFloat(loanOfficer.amount).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <Col md="12">
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`Company Wide Top Zip Codes ${new Date().getFullYear()}`}
        </CardHeader>
        <CardBody>
          <Row>
            {currentYearData.ytdMetrics
              ? currentYearData.ytdMetrics.top25Zips.slice(0, 3).map((zip) => {
                  return (
                    <Col md="4" key={zip.zip}>
                      <div className="statistics">
                        <div className="info">
                          <h3 className="info-title">{zip.zip}</h3>

                          <h6 className="stats-title">
                            {`${zip.count} Loans Closed`}
                          </h6>
                          <h6 className="stats-title">
                            {`(${zip.amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })})`}
                          </h6>
                        </div>
                      </div>

                      <Table responsive>
                        {/* <thead>
                          <th>Loan Officer</th>
                          <th>Loan Count</th>
                          <th>Loan Amount Total</th>
                        </thead> */}
                        <tbody>{renderRows(zip.zip)}</tbody>
                      </Table>
                    </Col>
                  );
                })
              : null}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminEncompassMetricsTopZips;
