import React from "react";
import {Col, Card, CardHeader, CardBody, CardFooter, Table} from "reactstrap";
import {Doughnut} from "react-chartjs-2";

const LoanOfficerMonthlyLoanTypeCard = ({
  currentLoanOfficer,
  currentMonth = 11,
  currentYear = 2020,
}) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentSalesObject =
    currentMonth === 100
      ? currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ).annualSalesTotals
      : currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ).monthlySalesTotals[currentMonth];

  const dashboardMonthlyLoanTypeDoughnutChart = {
    data: {
      labels: ["Conventional", "FHA", "VA", "USDA", "Other Type", "Reverse"],
      datasets: [
        {
          label: "Loan Type Sales Amount",
          data: [
            currentSalesObject.conventional.amount,
            currentSalesObject.fha.amount,
            currentSalesObject.va.amount,
            currentSalesObject.usda.amount,
            currentSalesObject.otherType.amount,
            currentSalesObject.reverse.amount,
          ],
          backgroundColor: [
            "#212d64",
            "#66a7dd",
            "#b33633",
            "#9ecf61",
            "#535759",
            "#303b4f",
          ],
          borderColor: ["#fff"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];

            const dollarValue = parseInt(
              data.datasets[0].data[tooltipItem.index],
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
            return `${label}: ${dollarValue}`;
          },
        },
      },
      legend: {
        onHover: function (e) {
          e.target.style.cursor = "pointer";
        },
        onLeave: function (e) {
          e.target.style.cursor = "default";
        },
        position: "right",
        labels: {
          fontSize: 15,
        },
      },
    },
  };

  const toDollarString = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const loName = currentLoanOfficer.name
    ? `${
        currentLoanOfficer.name.endsWith("s")
          ? `${currentLoanOfficer.name}'`
          : `${currentLoanOfficer.name}'s`
      } `
    : "";

  return (
    <Col xs={12} md={6}>
      <Card className="card-chart" style={{minHeight: "90vh"}}>
        <CardHeader className="card-category">
          {loName}
          {currentMonth === 100
            ? currentYear
            : `${MONTHS[currentMonth]} ${currentYear}`}{" "}
          Loan Types
        </CardHeader>
        <CardBody>
          <Doughnut
            key={currentMonth}
            data={dashboardMonthlyLoanTypeDoughnutChart.data}
            options={dashboardMonthlyLoanTypeDoughnutChart.options}
          />

          <Table responsive>
            <tbody>
              {currentSalesObject.conventional.count > 0 && (
                <tr>
                  <td>Conventional</td>
                  <td className="text-right">
                    {currentSalesObject.conventional.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.conventional.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.fha.count > 0 && (
                <tr>
                  <td>FHA</td>
                  <td className="text-right">{currentSalesObject.fha.count}</td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.fha.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.va.count > 0 && (
                <tr>
                  <td>VA</td>
                  <td className="text-right">{currentSalesObject.va.count}</td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.va.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.usda.count > 0 && (
                <tr>
                  <td>USDA</td>
                  <td className="text-right">
                    {currentSalesObject.usda.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.usda.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.reverse.count > 0 && (
                <tr>
                  <td>Reverse</td>
                  <td className="text-right">
                    {currentSalesObject.reverse.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.reverse.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.otherType.count > 0 && (
                <tr>
                  <td>Other Type</td>
                  <td className="text-right">
                    {currentSalesObject.otherType.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.otherType.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.total.count > 0 && (
                <tr>
                  <td>Total</td>
                  <td className="text-right">
                    {currentSalesObject.total.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.total.amount)}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Col>
  );
};

export default LoanOfficerMonthlyLoanTypeCard;
