import React from "react";
import {useAuth} from "../../../hooks/use-auth";
import {Row} from "reactstrap";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import BranchManagerFastFactsChart from "../components/BranchManagerFastFactsChart";
import BranchManagerCompanyWideSalesHighlights from "../components/BranchManagerCompanyWideSalesHighlights";
import BranchManagerAnnualSalesHighlights from "../components/BranchManagerAnnualSalesHighlights";
import BranchManagerPreviousMonthSalesHighlights from "../components/BranchManagerPreviousMonthSalesHighlights";
import BranchManagerCurrentMonthSalesHighlights from "../components/BranchManagerCurrentMonthSalesHighlights";
import BranchManagerNextMonthSalesHighlights from "../components/BranchManagerNextMonthSalesHighlights";
import BranchManagerTopZipsCard from "../components/BranchManagerTopZipsCard";
import BranchManagerTop5Referrals from "../components/BranchManagerTop5Referrals";

const BranchManagerFastFacts = ({
  initLoading,
  dataFor,
  currentLoanOfficer,
  currentYearData,
  branchData,
}) => {
  const auth = useAuth();

  const mostRecentYear =
    !currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear(),
    )
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();

  const mostRecentMonth = 7;

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <BranchManagerFastFactsChart
            dataFor={dataFor}
            currentLoanOfficer={currentLoanOfficer}
            branchData={branchData}
            mostRecentYear={mostRecentYear}
            mostRecentMonth={mostRecentMonth}
          />
          <div className="content">
            <Row>
              <BranchManagerCompanyWideSalesHighlights
                currentYearData={currentYearData}
              />
            </Row>

            <Row>
              <BranchManagerAnnualSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerPreviousMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerCurrentMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                branchData={branchData}
              />
            </Row>
            <Row>
              <BranchManagerNextMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                branchData={branchData}
              />
            </Row>
            {dataFor === "Individual" && (
              <>
                <Row>
                  <BranchManagerTopZipsCard
                    dataFor={dataFor}
                    currentLoanOfficer={currentLoanOfficer}
                    branchData={branchData}
                  />
                </Row>
                <Row>
                  <BranchManagerTop5Referrals
                    currentLoanOfficer={currentLoanOfficer}
                    referrals={
                      currentLoanOfficer.encompassMetrics.ytdMetrics
                        .top5Referrals
                    }
                    count={3}
                  />
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BranchManagerFastFacts;
