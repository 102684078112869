import React, { useState } from "react";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import { Row } from "reactstrap";
import RegionalManagerSalesGoalCalculator from "../components/RegionalManagerSalesGoalCalculator";
import RegionalManagerSalesGoalTracker from "../components/RegionalManagerSalesGoalTracker";

const RegionalManagerSalesGoals = ({
  regionData,
  currentLoanOfficer,
  setCurrentLoanOfficer,
}) => {
  const [goalSet, setGoalSet] = useState(
    currentLoanOfficer?.salesGoals?.[new Date().getFullYear()] === undefined
      ? false
      : true
  );

  const handleGoalSetChange = (
    commissionGoal,
    selfGeneratedCommissionPercent,
    companyGeneratedCommissionPercent,
    additionalCommissions,
    productionGoal
  ) => {
    setCurrentLoanOfficer((prevState) => ({
      ...prevState,
      salesGoals: {
        [new Date().getFullYear()]: {
          commissionGoal,
          selfGeneratedCommissionPercent,
          companyGeneratedCommissionPercent,
          additionalCommissions,
          productionGoal,
        },
      },
    }));
    setGoalSet(true);
  };

  return (
    <>
      <PanelHeader size="sm" />

      <div className="content">
        <Row>
          <RegionalManagerSalesGoalCalculator
            regionData={regionData}
            currentLoanOfficer={currentLoanOfficer}
            handleGoalSetChange={handleGoalSetChange}
            goalSet={goalSet}
          />
        </Row>

        {goalSet && (
          <RegionalManagerSalesGoalTracker
            regionData={regionData}
            currentLoanOfficer={currentLoanOfficer}
          />
        )}
      </div>
    </>
  );
};

export default RegionalManagerSalesGoals;
