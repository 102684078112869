import React from "react";

import {Row} from "reactstrap";
import AdminFastFactsChart from "../components/AdminFastFactsChart";
import AdminAnnualSalesHighlights from "../components/AdminAnnualSalesHighlights";
import AdminPreviousMonthSalesHighlights from "../components/AdminPreviousMonthSalesHighlights";
import AdminCurrentMonthSalesHighlights from "../components/AdminCurrentMonthSalesHighlights";
import AdminTopZipsCard from "../components/AdminTopZipsCard";
import AdminTopLoanOfficersCard from "../components/AdminTopLoanOfficersCard";

const AdminFastFacts = ({currentYearData}) => {
  return (
    <>
      <AdminFastFactsChart currentYearData={currentYearData} />

      <div className="content">
        <Row>
          <AdminAnnualSalesHighlights currentYearData={currentYearData} />
        </Row>
        <Row>
          <AdminPreviousMonthSalesHighlights
            currentYearData={currentYearData}
          />
          <AdminCurrentMonthSalesHighlights currentYearData={currentYearData} />
        </Row>

        <Row>
          <AdminTopZipsCard currentYearData={currentYearData} />
        </Row>
        <Row>
          <AdminTopLoanOfficersCard />
        </Row>
      </div>
    </>
  );
};

export default AdminFastFacts;
