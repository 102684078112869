import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import { useLocation } from "react-router-dom";

import Spinner from "../../components/NowUI/Spinner/Spinner";
import Sidebar from "../../components/NowUI/Sidebar/Sidebar";
import LoanOfficerNavbar from "../../components/NowUI/Navbars/LoanOfficerNavbar/LoanOfficerNavbar";
import loanOfficerRoutes from "../../routes/loanOfficerRoutes";

import LogoutModal from "../shared/LogoutModal/LogoutModal";
import MyBranch from "./components/MyBranch/MyBranch";

const LoanOfficer = (props) => {
  const location = useLocation();
  const mostRecentYear = new Date().getFullYear();

  const [sideDrawerIsMini, setSideDrawerIsMini] = useState(false);
  const [loadingCompanyWide, setLoadingCompanyWide] = useState(true);
  const [loadingLoanOfficer, setLoadingLoanOfficer] = useState(true);
  const [init, setInit] = useState(false);
  const [currentLoanOfficer, setCurrentLoanOfficer] = useState({
    title: "Loan Officer",
    encompassMetrics: {
      pipelineLoans: [],
      lifetimeMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      threeMonthMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      sixMonthMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      oneYearMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      ytdMetrics: {
        averageAge: 0,
        averageLoanAmount: 0,
        top3Zips: [],
        totalMonthlyIncome: 0,
      },
      encompassSales: [
        {
          year: 2020,
          annualSalesTotals: {
            purchase: { amount: 0, count: 0 },
            noCashRefinance: { amount: 0, count: 0 },
            cashRefinance: { amount: 0, count: 0 },
            otherPurpose: { amount: 0, count: 0 },
            conventional: { amount: 0, count: 0 },
            fha: { amount: 0, count: 0 },
            va: { amount: 0, count: 0 },
            usda: { amount: 0, count: 0 },
            reverse: { amount: 0, count: 0 },
            brokered: { amount: 0, count: 0 },

            otherType: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          },
          monthlySalesTotals: [
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
          ],
        },
        {
          year: 2021,
          annualSalesTotals: {
            purchase: { amount: 0, count: 0 },
            noCashRefinance: { amount: 0, count: 0 },
            cashRefinance: { amount: 0, count: 0 },
            otherPurpose: { amount: 0, count: 0 },
            conventional: { amount: 0, count: 0 },
            fha: { amount: 0, count: 0 },
            va: { amount: 0, count: 0 },
            usda: { amount: 0, count: 0 },
            reverse: { amount: 0, count: 0 },
            brokered: { amount: 0, count: 0 },
            otherType: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          },
          monthlySalesTotals: [
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
          ],
        },
        {
          year: 2022,
          annualSalesTotals: {
            purchase: { amount: 0, count: 0 },
            noCashRefinance: { amount: 0, count: 0 },
            cashRefinance: { amount: 0, count: 0 },
            otherPurpose: { amount: 0, count: 0 },
            conventional: { amount: 0, count: 0 },
            fha: { amount: 0, count: 0 },
            va: { amount: 0, count: 0 },
            usda: { amount: 0, count: 0 },
            reverse: { amount: 0, count: 0 },
            brokered: { amount: 0, count: 0 },
            otherType: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          },
          monthlySalesTotals: [
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
          ],
        },
        {
          year: 2023,
          annualSalesTotals: {
            purchase: { amount: 0, count: 0 },
            noCashRefinance: { amount: 0, count: 0 },
            cashRefinance: { amount: 0, count: 0 },
            otherPurpose: { amount: 0, count: 0 },
            conventional: { amount: 0, count: 0 },
            fha: { amount: 0, count: 0 },
            va: { amount: 0, count: 0 },
            usda: { amount: 0, count: 0 },
            reverse: { amount: 0, count: 0 },
            brokered: { amount: 0, count: 0 },
            otherType: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          },
          monthlySalesTotals: [
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
          ],
        },
        {
          year: 2024,
          annualSalesTotals: {
            purchase: { amount: 0, count: 0 },
            noCashRefinance: { amount: 0, count: 0 },
            cashRefinance: { amount: 0, count: 0 },
            otherPurpose: { amount: 0, count: 0 },
            conventional: { amount: 0, count: 0 },
            fha: { amount: 0, count: 0 },
            va: { amount: 0, count: 0 },
            usda: { amount: 0, count: 0 },
            reverse: { amount: 0, count: 0 },
            brokered: { amount: 0, count: 0 },
            otherType: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          },
          monthlySalesTotals: [
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
            {
              purchase: { amount: 0, count: 0 },
              noCashRefinance: { amount: 0, count: 0 },
              cashRefinance: { amount: 0, count: 0 },
              otherPurpose: { amount: 0, count: 0 },
              conventional: { amount: 0, count: 0 },
              fha: { amount: 0, count: 0 },
              va: { amount: 0, count: 0 },
              usda: { amount: 0, count: 0 },
              reverse: { amount: 0, count: 0 },
              brokered: { amount: 0, count: 0 },
              otherType: { amount: 0, count: 0 },
              total: { amount: 0, count: 0 },
            },
          ],
        },
      ],
    },
    thumbnailURL: "",
    branchMembers: [],
    name: "",
  });
  const [currentYearData, setCurrentYearData] = useState({
    loanChannels: {
      brokeredLoans: { count: 0, amount: 0 },
      correspondentLoans: { count: 0, amount: 0 },
      retailLoans: { count: 0, amount: 0 },
      wholesaleLoans: { count: 0, amount: 0 },
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: { amount: 0, count: 0 },
        noCashRefinance: { amount: 0, count: 0 },
        cashRefinance: { amount: 0, count: 0 },
        otherPurpose: { amount: 0, count: 0 },
        conventional: { amount: 0, count: 0 },
        fha: { amount: 0, count: 0 },
        va: { amount: 0, count: 0 },
        usda: { amount: 0, count: 0 },
        reverse: { amount: 0, count: 0 },
        otherType: { amount: 0, count: 0 },
        total: { amount: 0, count: 0 },
      },
      monthlySalesTotals: [
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
        {
          purchase: { amount: 0, count: 0 },
          noCashRefinance: { amount: 0, count: 0 },
          cashRefinance: { amount: 0, count: 0 },
          otherPurpose: { amount: 0, count: 0 },
          conventional: { amount: 0, count: 0 },
          fha: { amount: 0, count: 0 },
          va: { amount: 0, count: 0 },
          usda: { amount: 0, count: 0 },
          reverse: { amount: 0, count: 0 },
          otherType: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        },
      ],
    },
    lastUpdated: "",
    ytdMetrics: {
      averageAge: "0",
      averageLoanAmounts: {
        purchases: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
        total: "0",
      },
      top25Referrals: [],
      top25Zips: [],
      totalMonthlyIncome: "0",
      daysToFund: {
        purchase: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
      },
    },
  });
  const [myBranchModalOpen, setMyBranchModalOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({ name: "" });
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  // const [init, setInit] = useState(false);
  console.log(`sideDrawerIsMini`, sideDrawerIsMini);
  const mainPanel = React.createRef();

  const changeCurrentLoanOfficer = async (email) => {
    try {
      setLoadingLoanOfficer(true);
      const response = await fetch(
        `https://us-central1-essexdashboard.cloudfunctions.net/getLoanOfficerSales?email=${email}`
      );

      const json = await response.json();

      setCurrentLoanOfficer(json);
      setLoadingLoanOfficer(false);
    } catch (e) {
      setCurrentLoanOfficer({
        encompassMetrics: {
          pipelineLoans: [],
          lifetimeMetrics: {
            averageAge: 0,
            averageLoanAmount: 0,
            top3Zips: [],
            totalMonthlyIncome: 0,
          },
          threeMonthMetrics: {
            averageAge: 0,
            averageLoanAmount: 0,
            top3Zips: [],
            totalMonthlyIncome: 0,
          },
          sixMonthMetrics: {
            averageAge: 0,
            averageLoanAmount: 0,
            top3Zips: [],
            totalMonthlyIncome: 0,
          },
          oneYearMetrics: {
            averageAge: 0,
            averageLoanAmount: 0,
            top3Zips: [],
            totalMonthlyIncome: 0,
          },
          ytdMetrics: {
            averageAge: 0,
            averageLoanAmount: 0,
            top3Zips: [],
            totalMonthlyIncome: 0,
          },
          encompassSales: [
            {
              year: 2020,
              annualSalesTotals: {
                purchase: { amount: 0, count: 0 },
                noCashRefinance: { amount: 0, count: 0 },
                cashRefinance: { amount: 0, count: 0 },
                otherPurpose: { amount: 0, count: 0 },
                conventional: { amount: 0, count: 0 },
                fha: { amount: 0, count: 0 },
                va: { amount: 0, count: 0 },
                usda: { amount: 0, count: 0 },
                reverse: { amount: 0, count: 0 },
                brokered: { amount: 0, count: 0 },
                otherType: { amount: 0, count: 0 },
                total: { amount: 0, count: 0 },
              },
              monthlySalesTotals: [
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
              ],
            },
            {
              year: 2021,
              annualSalesTotals: {
                purchase: { amount: 0, count: 0 },
                noCashRefinance: { amount: 0, count: 0 },
                cashRefinance: { amount: 0, count: 0 },
                otherPurpose: { amount: 0, count: 0 },
                conventional: { amount: 0, count: 0 },
                fha: { amount: 0, count: 0 },
                va: { amount: 0, count: 0 },
                usda: { amount: 0, count: 0 },
                reverse: { amount: 0, count: 0 },
                brokered: { amount: 0, count: 0 },
                otherType: { amount: 0, count: 0 },
                total: { amount: 0, count: 0 },
              },
              monthlySalesTotals: [
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
                {
                  purchase: { amount: 0, count: 0 },
                  noCashRefinance: { amount: 0, count: 0 },
                  cashRefinance: { amount: 0, count: 0 },
                  otherPurpose: { amount: 0, count: 0 },
                  conventional: { amount: 0, count: 0 },
                  fha: { amount: 0, count: 0 },
                  va: { amount: 0, count: 0 },
                  usda: { amount: 0, count: 0 },
                  reverse: { amount: 0, count: 0 },
                  brokered: { amount: 0, count: 0 },
                  otherType: { amount: 0, count: 0 },
                  total: { amount: 0, count: 0 },
                },
              ],
            },
          ],
        },
        thumbnailURL: "",
        branchMembers: [],
      });
      setLoadingLoanOfficer(false);
    }
  };

  useEffect(() => {
    document.documentElement.className += " perfect-scrollbar-on";
    document.documentElement.classList.remove("perfect-scrollbar-off");
    let ps = new PerfectScrollbar(mainPanel.current);

    return () => {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    };
  }, [mainPanel]);

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setLogoutModalOpen(true);
    }, 60 * 60 * 1000);

    return () => {
      clearTimeout(currentTimeout);
    };
  }, [logoutModalOpen]);

  const toggleSideDrawerSize = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSideDrawerIsMini(false);
    } else {
      setSideDrawerIsMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const toggleMyBranchModal = () => {
    setMyBranchModalOpen((prevState) => !prevState);
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/loan-officer") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => (
              <prop.component
                currentYearData={currentYearData}
                currentLoanOfficer={currentLoanOfficer}
                setCurrentLoanOfficer={setCurrentLoanOfficer}
                setLoggedInUser={setLoggedInUser}
                setLoadingCompanyWide={setLoadingCompanyWide}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.email) {
        changeCurrentLoanOfficer(location.state.email);
      }
      if (location.state.returnAs) {
        console.log("returnAs", location.state.returnAs);
        setLoggedInUser({
          name: location.state.returnAs.name,
          email: location.state.returnAs.email,
          photoURL: location?.state?.returnAs?.photoURL,
        });
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && init === false) {
      if (location.state.initialEmail) {
        // Initial login
        changeCurrentLoanOfficer(location.state.initialEmail);
        setLoggedInUser({
          authTier: "Loan Officer",
          name: location.state.initialName,
          email: location.state.initialEmail,
          photoURL: location?.state?.photoURL,
        });
      } else if (location.state.email) {
        // Being impersonated
        changeCurrentLoanOfficer(location.state.email);
      }

      if (location.state.returnAs) {
        // Being impersonated
        setLoggedInUser({
          name: location.state.returnAs.name,
          email: location.state.returnAs.email,
          authTier: location.state.returnAs.authTier,
          photoURL: location?.state?.returnAs?.photoURL,
        });
      }
      setInit(true);
    }
  }, [location.state, init]);

  useEffect(() => {
    console.log(`location.state`, location.state);
  }, [location.state]);

  useEffect(() => {
    const fetchCompanyWideData = async () => {
      setLoadingCompanyWide(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${mostRecentYear}`
        );
        const json = await response.json();
        setCurrentYearData(json);
        setLoadingCompanyWide(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideData();
  }, [mostRecentYear]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        name={loggedInUser.name}
        routes={loanOfficerRoutes}
        backgroundColor="blue"
        minimizeSidebar={toggleSideDrawerSize}
        currentLoanOfficer={currentLoanOfficer}
        photoURL={loggedInUser.photoURL}
      />
      <div
        className="main-panel"
        ref={mainPanel}
        style={{ position: "relative" }}
      >
        <LoanOfficerNavbar
          {...props}
          brandText={getActiveRoute(loanOfficerRoutes)}
          toggleMyBranchModal={toggleMyBranchModal}
          currentLoanOfficer={currentLoanOfficer}
          loggedInUser={loggedInUser}
          changeCurrentLoanOfficer={changeCurrentLoanOfficer}
        />

        {logoutModalOpen && (
          <LogoutModal
            logoutModalOpen={logoutModalOpen}
            setLogoutModalOpen={setLogoutModalOpen}
          />
        )}
        {(loadingCompanyWide || loadingLoanOfficer) && (
          <Spinner title="Fetching Loan Officer Data" />
        )}

        <MyBranch
          currentLoanOfficer={currentLoanOfficer}
          changeCurrentLoanOfficer={changeCurrentLoanOfficer}
          myBranchModalOpen={myBranchModalOpen}
          toggleMyBranchModal={toggleMyBranchModal}
        />

        <Switch>{getRoutes(loanOfficerRoutes)}</Switch>
      </div>
    </div>
  );
};

export default LoanOfficer;
