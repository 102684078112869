import React from "react";
import { Card, CardBody, CardHeader, CardFooter, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

const RegionalManagerTop5Referrals = ({
  currentLoanOfficer,
  referrals,
  count = 5,
  withLink = true
}) => {
  const history = useHistory();
  const renderReferralPartners = () => {
    if (referrals) {
      if (referrals.length > 0) {
        return referrals.slice(0, count).map((referral, index) => {
          return (
            <Col md="4" key={index}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{referral.partner}</h3>

                  <h6 className="stats-title">
                    {`${referral.count} Loans Closed`}
                  </h6>
                  <h6 className="stats-title">
                    {`(${referral.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })})`}
                  </h6>
                </div>
              </div>
            </Col>
          );
        });
      } else {
        return (
          <h6 style={{ margin: "45px auto" }}>
            No Referral Partners found in Encompass
          </h6>
        );
      }
    } else {
      return (
        <h6 style={{ marginBottom: "25px" }}>
          No Referral Partners found in Encompass.
        </h6>
      );
    }
  };

  return (
    <Col md="12">
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${
            currentLoanOfficer.name.endsWith("s")
              ? `${currentLoanOfficer.name}'`
              : `${currentLoanOfficer.name}'s`
          } Top Referral Partners YTD`}
        </CardHeader>
        <CardBody>
          <Row>{renderReferralPartners()}</Row>
        </CardBody>

        {withLink && (
          <CardFooter>
            <h6
              className="text-right"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#061f54"
              }}
              onClick={() => {
                history.push("/regional-manager/sales/encompass-metrics");
              }}
            >
              See more metrics
            </h6>
          </CardFooter>
        )}
      </Card>
    </Col>
  );
};

export default RegionalManagerTop5Referrals;
