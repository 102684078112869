import React, {useState, useEffect} from "react";
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import Loading from "../../../components/NowUI/Loading/Loading";
// import { Pie } from "react-chartjs-2";

const LoanOfficerEncompassMetricsComparisonCard = ({
  zips,
  selectedZipIndex,
}) => {
  const [metrics, setMetrics] = useState([]);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [selectedZipMetrics, setSelectedZipMetrics] = useState(metrics[0]);

  useEffect(() => {
    const fetchZipMetrics = async (zipsArray) => {
      setMetricsLoading(true);

      const queryString = zipsArray.map((zip) => zip.zip).join();
      if (queryString) {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getZipMetrics?zips=${queryString}`,
        );

        const json = await response.json();
        setMetrics(json);
        setMetricsLoading(false);
      }
    };

    fetchZipMetrics(zips);
  }, [zips]);

  useEffect(() => {
    setSelectedZipMetrics(metrics[0]);
  }, [metrics]);

  useEffect(() => {
    setSelectedZipMetrics(metrics[selectedZipIndex]);
  }, [selectedZipIndex, metrics]);

  const formatCityName = (string) => {
    if (string.includes(";")) {
      return string.split(";").join(",");
    } else {
      return string;
    }
  };

  const stringToInt = (string) => {
    const newString = string.toString();

    if (newString.includes(",")) {
      return Number.parseInt(newString.split(",").join(""));
    } else {
      return Number.parseInt(newString);
    }
  };

  return (
    zips.length > 0 && (
      <Col md="12">
        <Card className="card-stats card-raised">
          <CardHeader className="card-category">Top 3 Zips Metrics</CardHeader>
          <CardBody>
            {metricsLoading ? (
              <Loading />
            ) : (
              <Row>
                <Col md="6">
                  <div className="statistics">
                    <div className="info">
                      <h3 className="info-title" style={{marginBottom: "0"}}>
                        {selectedZipMetrics.name}
                      </h3>
                      {selectedZipMetrics !== undefined && (
                        <div>
                          <p>{`${formatCityName(
                            selectedZipMetrics.city_name,
                          )}`}</p>
                          <p>
                            {`Average Household Income: `}
                            <b>{selectedZipMetrics.average_household_income}</b>
                          </p>
                          <p>
                            {`Average Age: `}
                            <b>{selectedZipMetrics.median_age}</b>
                          </p>
                          <p>
                            {`Housing Units: `}
                            <b>{selectedZipMetrics.housing_units}</b>
                          </p>
                          <p>
                            {`Median Value of Owner Occupied Units: `}
                            <b>
                              {
                                selectedZipMetrics.median_value_of_owner_occupied_units
                              }
                            </b>
                          </p>
                          <p>
                            {`Unemployment Percentage: `}
                            <b>{selectedZipMetrics.unemployment_pct}</b>
                          </p>
                          <p>
                            {`Population Density (sq. mile): `}
                            <b>{selectedZipMetrics.population_density_sq_mi}</b>
                          </p>
                          <p>
                            {`Family Poverty Percentage: `}
                            <b>{selectedZipMetrics.family_poverty_pct}</b>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center">Age Group</th>
                        <th className="text-center">
                          Percentage of Population
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">20 - 29</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(
                              selectedZipMetrics.age_male_20_to_29,
                            ) +
                              stringToInt(
                                selectedZipMetrics.age_female_20_to_29,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">30 - 39</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(
                              selectedZipMetrics.age_male_30_to_39,
                            ) +
                              stringToInt(
                                selectedZipMetrics.age_female_30_to_39,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">40 - 49</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(
                              selectedZipMetrics.age_male_40_to_49,
                            ) +
                              stringToInt(
                                selectedZipMetrics.age_female_40_to_49,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">50 - 59</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(
                              selectedZipMetrics.age_male_50_to_59,
                            ) +
                              stringToInt(
                                selectedZipMetrics.age_female_50_to_59,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">60 - 69</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(
                              selectedZipMetrics.age_male_60_to_69,
                            ) +
                              stringToInt(
                                selectedZipMetrics.age_female_60_to_69,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">70+</td>
                        <td className="text-center">
                          {`${(
                            ((stringToInt(selectedZipMetrics.age_male_70_plus) +
                              stringToInt(
                                selectedZipMetrics.age_female_70_plus,
                              )) /
                              stringToInt(selectedZipMetrics.age_total)) *
                            100
                          ).toFixed(2)}%`}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Col>
    )
  );
};

export default LoanOfficerEncompassMetricsComparisonCard;
