import RegionalManagerFastFacts from "../fast-facts/regional-manager/pages/RegionalManagerFastFacts";
import RegionalManagerSalesGoals from "../sales/regional-manager/pages/RegionalManagerSalesGoals";
import RegionalManagerEncompassMetrics from "../sales/regional-manager/pages/RegionalManagerEncompassMetrics";
import RegionalManagerMyRegion from "../sales/regional-manager/pages/RegionalManagerMyRegion";
import RegionalManagerSalesTimeline from "../sales/regional-manager/pages/RegionalManagerSalesTimeline";
// import RegionalManagerReportCard from "../analytics/regional-manager/pages/RegionalManagerReportCard";

let regionalManagerRoutes = [
  {
    path: "/fast-facts",
    name: "Fast Facts",
    icon: "now-ui-icons design_app",
    component: RegionalManagerFastFacts,
    layout: "/regional-manager",
  },
  {
    collapse: true,
    name: "Sales",
    icon: "now-ui-icons business_chart-bar-32",
    state: "salesOpen",
    views: [
      {
        path: "/sales/sales-timeline",
        name: "Sales Timeline",
        mini: "ST",
        component: RegionalManagerSalesTimeline,
        layout: "/regional-manager",
      },
      {
        path: "/sales/my-region",
        name: "My Region",
        mini: "MR",
        component: RegionalManagerMyRegion,
        layout: "/regional-manager",
      },
      {
        path: "/sales/encompass-metrics",
        name: "Encompass Metrics",
        mini: "EM",
        component: RegionalManagerEncompassMetrics,
        layout: "/regional-manager",
        individualOnly: true,
      },
      {
        path: "/sales/sales-goals",
        name: "Sales Goals",
        mini: "SG",
        component: RegionalManagerSalesGoals,
        layout: "/regional-manager",
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Analytics",
  //   icon: "now-ui-icons objects_globe",
  //   state: "analyticsOpen",
  //   views: [
  //     {
  //       path: "/analytics/report-card",
  //       name: "Report Card",
  //       mini: "RC",
  //       component: RegionalManagerReportCard,
  //       layout: "/regional-manager",
  //     },
  //   ],
  // },
];

export default regionalManagerRoutes;
