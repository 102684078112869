import React, {useState, useEffect} from "react";
import {Modal, ModalHeader, ModalBody, Button, ModalFooter} from "reactstrap";
import {useAuth} from "../../../hooks/use-auth";
import {useHistory} from "react-router-dom";

const LogoutModal = ({logoutModalOpen, setLogoutModalOpen}) => {
  const auth = useAuth();
  const history = useHistory();

  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    // console.log("Starting Logout Timer");
    const currentTimer = setTimeout(async () => {
      await auth.signout();
      history.push("/login");
    }, 60 * 1000);

    return () => {
      clearTimeout(currentTimer);
    };
  }, [auth, history]);

  useEffect(() => {
    setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);
  }, []);

  return (
    <Modal isOpen={logoutModalOpen}>
      <ModalHeader>Session Timeout</ModalHeader>

      <ModalBody>
        <h6>{`Securely logging you out in ${seconds} seconds.`}</h6>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setLogoutModalOpen(false)}>
          Remain Logged In
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LogoutModal;
