import React, {useState, useEffect} from "react";
import {Switch, Route} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import {useLocation} from "react-router-dom";

import Spinner from "../../components/NowUI/Spinner/Spinner";
import Sidebar from "../../components/NowUI/Sidebar/Sidebar";
import AccountExecutiveNavbar from "../../components/NowUI/Navbars/AccountExecutiveNavbar/AccountExecutiveNavbar";
import accountExecutiveRoutes from "../../routes/accountExecutiveRoutes";

import LogoutModal from "../shared/LogoutModal/LogoutModal";

const AccountExecutive = (props) => {
  const location = useLocation();
  const mostRecentYear = new Date().getFullYear();

  const [sideDrawerIsMini, setSideDrawerIsMini] = useState(false);
  const [loadingCompanyWide, setLoadingCompanyWide] = useState(true);
  const [loadingAccountExecutive, setLoadingAccountExecutive] = useState(true);
  const [init, setInit] = useState(false);
  const [currentAccountExecutive, setCurrentAccountExecutive] = useState({
    title: "Account Executive",
    encompassSales: [
      {
        year: new Date().getFullYear(),
        annualSalesTotals: {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        monthlySalesTotals: [
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
          {
            purchase: {amount: 0, count: 0},
            noCashRefinance: {amount: 0, count: 0},
            cashRefinance: {amount: 0, count: 0},
            otherPurpose: {amount: 0, count: 0},
            conventional: {amount: 0, count: 0},
            fha: {amount: 0, count: 0},
            va: {amount: 0, count: 0},
            usda: {amount: 0, count: 0},
            reverse: {amount: 0, count: 0},
            otherType: {amount: 0, count: 0},
            total: {amount: 0, count: 0},
            attached: {amount: 0, count: 0},
            condo: {amount: 0, count: 0},
            cooperative: {amount: 0, count: 0},
            detached: {amount: 0, count: 0},
            highRiseCondo: {amount: 0, count: 0},
            manufactured: {amount: 0, count: 0},
            pud: {amount: 0, count: 0},
            detachedCondo: {amount: 0, count: 0},
            otherPropertyType: {amount: 0, count: 0},
            mhSelect: {amount: 0, count: 0},
            mhAdvantage: {amount: 0, count: 0},
          },
        ],
      },
    ],
    thumbnailURL: "",
    name: "",
  });
  const [currentYearData, setCurrentYearData] = useState({
    loanChannels: {
      brokeredLoans: {count: 0, amount: 0},
      correspondentLoans: {count: 0, amount: 0},
      retailLoans: {count: 0, amount: 0},
      wholesaleLoans: {count: 0, amount: 0},
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: {amount: 0, count: 0},
        noCashRefinance: {amount: 0, count: 0},
        cashRefinance: {amount: 0, count: 0},
        otherPurpose: {amount: 0, count: 0},
        conventional: {amount: 0, count: 0},
        fha: {amount: 0, count: 0},
        va: {amount: 0, count: 0},
        usda: {amount: 0, count: 0},
        reverse: {amount: 0, count: 0},
        otherType: {amount: 0, count: 0},
        total: {amount: 0, count: 0},
        attached: {amount: 0, count: 0},
        condo: {amount: 0, count: 0},
        cooperative: {amount: 0, count: 0},
        detached: {amount: 0, count: 0},
        highRiseCondo: {amount: 0, count: 0},
        manufactured: {amount: 0, count: 0},
        pud: {amount: 0, count: 0},
        detachedCondo: {amount: 0, count: 0},
        otherPropertyType: {amount: 0, count: 0},
        mhSelect: {amount: 0, count: 0},
        mhAdvantage: {amount: 0, count: 0},
      },
      monthlySalesTotals: [
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
      ],
    },
    lastUpdated: "",
    ytdMetrics: {
      averageAge: "0",
      averageLoanAmounts: {
        purchases: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
        total: "0",
      },
      top25Referrals: [],
      top25Zips: [],
      totalMonthlyIncome: "0",
      daysToFund: {
        purchase: "0",
        noCashRefinance: "0",
        cashRefinance: "0",
        otherPurpose: "0",
        conventional: "0",
        fha: "0",
        va: "0",
        usda: "0",
        otherType: "0",
      },
    },
  });
  const [loggedInUser, setLoggedInUser] = useState({name: ""});
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  // const [init, setInit] = useState(false);
  console.log(`sideDrawerIsMini`, sideDrawerIsMini);
  const mainPanel = React.createRef();

  const changeCurrentAccountExecutive = async (email = null, name = null) => {
    try {
      setLoadingAccountExecutive(true);
      if (email !== null) {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getAccountExecutiveData?email=${email}`,
        );
        const json = await response.json();

        setCurrentAccountExecutive(json);
      } else if (name !== null) {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getAccountExecutiveData?name=${name}`,
        );
        const json = await response.json();

        setCurrentAccountExecutive(json);
      }

      setLoadingAccountExecutive(false);
    } catch (e) {
      setCurrentAccountExecutive({
        title: "Account Executive",
        encompassSales: [
          {
            year: new Date().getFullYear(),
            annualSalesTotals: {
              purchase: {amount: 0, count: 0},
              noCashRefinance: {amount: 0, count: 0},
              cashRefinance: {amount: 0, count: 0},
              otherPurpose: {amount: 0, count: 0},
              conventional: {amount: 0, count: 0},
              fha: {amount: 0, count: 0},
              va: {amount: 0, count: 0},
              usda: {amount: 0, count: 0},
              reverse: {amount: 0, count: 0},
              otherType: {amount: 0, count: 0},
              total: {amount: 0, count: 0},
              attached: {amount: 0, count: 0},
              condo: {amount: 0, count: 0},
              cooperative: {amount: 0, count: 0},
              detached: {amount: 0, count: 0},
              highRiseCondo: {amount: 0, count: 0},
              manufactured: {amount: 0, count: 0},
              pud: {amount: 0, count: 0},
              detachedCondo: {amount: 0, count: 0},
              otherPropertyType: {amount: 0, count: 0},
              mhSelect: {amount: 0, count: 0},
              mhAdvantage: {amount: 0, count: 0},
            },
            monthlySalesTotals: [
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
              {
                purchase: {amount: 0, count: 0},
                noCashRefinance: {amount: 0, count: 0},
                cashRefinance: {amount: 0, count: 0},
                otherPurpose: {amount: 0, count: 0},
                conventional: {amount: 0, count: 0},
                fha: {amount: 0, count: 0},
                va: {amount: 0, count: 0},
                usda: {amount: 0, count: 0},
                reverse: {amount: 0, count: 0},
                otherType: {amount: 0, count: 0},
                total: {amount: 0, count: 0},
                attached: {amount: 0, count: 0},
                condo: {amount: 0, count: 0},
                cooperative: {amount: 0, count: 0},
                detached: {amount: 0, count: 0},
                highRiseCondo: {amount: 0, count: 0},
                manufactured: {amount: 0, count: 0},
                pud: {amount: 0, count: 0},
                detachedCondo: {amount: 0, count: 0},
                otherPropertyType: {amount: 0, count: 0},
                mhSelect: {amount: 0, count: 0},
                mhAdvantage: {amount: 0, count: 0},
              },
            ],
          },
        ],
        thumbnailURL: "",
        name: "",
      });
      setLoadingAccountExecutive(false);
    }
  };

  useEffect(() => {
    document.documentElement.className += " perfect-scrollbar-on";
    document.documentElement.classList.remove("perfect-scrollbar-off");
    let ps = new PerfectScrollbar(mainPanel.current);

    return () => {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    };
  }, [mainPanel]);

  useEffect(() => {
    const currentTimeout = setTimeout(() => {
      setLogoutModalOpen(true);
    }, 60 * 60 * 1000);

    return () => {
      clearTimeout(currentTimeout);
    };
  }, [logoutModalOpen]);

  const toggleSideDrawerSize = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSideDrawerIsMini(false);
    } else {
      setSideDrawerIsMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/account-executive") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => (
              <prop.component
                currentYearData={currentYearData}
                currentAccountExecutive={currentAccountExecutive}
                setCurrentAccountExecutive={setCurrentAccountExecutive}
                setLoggedInUser={setLoggedInUser}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path,
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      if (location.state.email !== undefined) {
        changeCurrentAccountExecutive(location.state.email, null);
      } else if (location.state.name !== undefined) {
        changeCurrentAccountExecutive(null, location.state.name);
      }
      if (location.state.returnAs) {
        setLoggedInUser({
          name: location.state.returnAs.name,
          email: location.state.returnAs.email,
        });
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && init === false) {
      if (location.state.initialEmail) {
        // Initial login
        changeCurrentAccountExecutive(location.state.initialEmail, null);
        setLoggedInUser({
          authTier: "Account Executive",
          name: location.state.initialName,
          email: location.state.initialEmail,
          photoURL: location?.state?.photoURL,
        });
      } else if (location.state.email) {
        // Being impersonated
        changeCurrentAccountExecutive(location.state.email);
      }

      if (location.state.returnAs) {
        // Being impersonated
        setLoggedInUser({
          name: location.state.returnAs.name,
          email: location.state.returnAs.email,
          authTier: location.state.returnAs.authTier,
          photoURL: location?.state?.returnAs?.photoURL,
        });
      }
      setInit(true);
    }
  }, [location.state, init]);

  useEffect(() => {
    console.log(`location.state`, location.state);
  }, [location.state]);

  useEffect(() => {
    const fetchCompanyWideData = async () => {
      setLoadingCompanyWide(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${mostRecentYear}&channel=Correspondent`,
        );
        const json = await response.json();
        setCurrentYearData(json);
        setLoadingCompanyWide(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideData();
  }, [mostRecentYear]);

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        name={loggedInUser.name}
        routes={accountExecutiveRoutes}
        backgroundColor="blue"
        minimizeSidebar={toggleSideDrawerSize}
        currentAccountExecutive={currentAccountExecutive}
        photoURL={loggedInUser.photoURL}
      />
      <div
        className="main-panel"
        ref={mainPanel}
        style={{position: "relative"}}
      >
        <AccountExecutiveNavbar
          {...props}
          brandText={getActiveRoute(accountExecutiveRoutes)}
          currentAccountExecutive={currentAccountExecutive}
          loggedInUser={loggedInUser}
          changeCurrentAccountExecutive={changeCurrentAccountExecutive}
        />

        {logoutModalOpen && (
          <LogoutModal
            logoutModalOpen={logoutModalOpen}
            setLogoutModalOpen={setLogoutModalOpen}
          />
        )}
        {(loadingCompanyWide || loadingAccountExecutive) && (
          <Spinner title="Fetching Account Executive Data" />
        )}

        <Switch>{getRoutes(accountExecutiveRoutes)}</Switch>
      </div>
    </div>
  );
};

export default AccountExecutive;
