import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row
} from "reactstrap";

import PanelHeader from "../../components/NowUI/PanelHeader/PanelHeader";
import Loading from "../../components/NowUI/Loading/Loading";

const LoanLookup = () => {
  const [loanNumber, setLoanNumber] = useState("");
  const [loanData, setLoanData] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedOnce, setSearchedOnce] = useState(false);

  const fetchLoanData = async () => {
    setLoading(true);
    setSearchedOnce(true);
    try {
      const response = await fetch(
        `https://us-central1-essexdashboard.cloudfunctions.net/getLoanData?loanNumber=${loanNumber}`
      );
      const json = await response.json();
      setLoanData(json);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <Row>
          <Card>
            <CardHeader className="card-category">Loan Lookup</CardHeader>
            <CardBody>
              <Col md="3">
                <Label for="loan-number">Loan Number</Label>
                <Input
                  name="loan-number"
                  value={loanNumber}
                  onChange={(e) => setLoanNumber(e.target.value)}
                />
                <Button onClick={() => fetchLoanData()}>Find Loan</Button>
              </Col>
              {loading ? (
                <Loading />
              ) : searchedOnce ? (
                <Row>
                  <CardBody style={{ backgroundColor: "#232323" }}>
                    <pre style={{ color: "#00DF03" }}>
                      Output: {JSON.stringify(loanData, null, 2)}
                    </pre>
                  </CardBody>
                </Row>
              ) : null}
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
};

export default LoanLookup;
