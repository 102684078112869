import React, { useState } from "react";

import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import LoanOfficerProductionCalculator from "./LoanOfficerProductionCalculator";
import LoanOfficerCommissionCalculator from "./LoanOfficerCommissionCalculator";

const LoanOfficerSalesGoalCalculator = ({
  currentLoanOfficer,
  goalSet,
  handleGoalSetChange,
}) => {
  const [goalType, setGoalType] = useState("commission");
  const [calculatorOpen, setCalculatorOpen] = useState(
    currentLoanOfficer?.salesGoals?.[new Date().getFullYear()] === undefined
      ? true
      : false
  );

  let prevYearsAnnualSalesTotals =
    currentLoanOfficer.encompassMetrics.encompassSales.length > 1
      ? currentLoanOfficer.encompassMetrics.encompassSales
          .filter(
            (salesObj) =>
              salesObj.year !== new Date().getFullYear() &&
              salesObj.year !== 0 &&
              salesObj.year >= 2019
          )
          .map((salesObj) => ({
            year: salesObj.year,
            count: salesObj.annualSalesTotals.total.count,
            amount: salesObj.annualSalesTotals.total.amount,
          }))
          .map((salesObj, index, thisArr) => {
            if (index !== 0) {
              return {
                ...salesObj,
                salesAmountPercentChange:
                  (
                    ((salesObj.amount - thisArr[index - 1].amount) /
                      Math.abs(thisArr[index - 1].amount)) *
                    100
                  ).toFixed(2) + "%",
              };
            } else {
              return salesObj;
            }
          })
      : [
          {
            year: new Date().getFullYear() - 1,
            count: 0,
            amount: 0,
          },
        ];

  const avgLoanAmount =
    currentLoanOfficer.encompassMetrics.oneYearMetrics.averageLoanAmount || 0;

  return (
    <Card>
      <CardHeader
        className="card-category"
        onClick={(e) => {
          e.preventDefault();
          setCalculatorOpen(!calculatorOpen);
        }}
        style={{ cursor: "pointer" }}
      >
        Sales Goal Calculator
        <b
          className="caret"
          style={{
            marginLeft: "10px",
            transform: calculatorOpen ? "rotate(180deg)" : null,
          }}
        />
      </CardHeader>

      <Card className="card-plain">
        <CardHeader role="tab"></CardHeader>
        <Collapse role="tabpanel" isOpen={calculatorOpen}>
          <CardBody>
            <Nav
              pills
              fill
              className="nav-pills-danger"
              style={{ margin: "4vh 0" }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: goalType === "commission",
                  })}
                  onClick={() => {
                    setGoalType("commission");
                  }}
                >
                  Set Annual Commission Goal
                </NavLink>
              </NavItem>
              <div
                style={{
                  display: "flex",
                  width: "10vw",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <hr style={{ width: "40px", border: "solid #ccc 1px" }} />
                OR
                <hr style={{ width: "40px", border: "solid #ccc 1px" }} />
              </div>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: goalType === "production",
                  })}
                  onClick={() => {
                    setGoalType("production");
                  }}
                >
                  Set Annual Production Goal
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={goalType}>
              <TabPane tabId="commission">
                <LoanOfficerCommissionCalculator
                  currentLoanOfficer={currentLoanOfficer}
                  prevYearsAnnualSalesTotals={prevYearsAnnualSalesTotals}
                  avgLoanAmount={avgLoanAmount}
                  handleGoalSetChange={handleGoalSetChange}
                  setCalculatorOpen={setCalculatorOpen}
                  goalSet={goalSet}
                />
              </TabPane>
              <TabPane tabId="production">
                <LoanOfficerProductionCalculator
                  currentLoanOfficer={currentLoanOfficer}
                  prevYearsAnnualSalesTotals={prevYearsAnnualSalesTotals}
                  avgLoanAmount={avgLoanAmount}
                  handleGoalSetChange={handleGoalSetChange}
                  setCalculatorOpen={setCalculatorOpen}
                  goalSet={goalSet}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Collapse>
      </Card>
    </Card>
  );
};

export default LoanOfficerSalesGoalCalculator;
