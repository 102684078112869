import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom";

import LoanOfficerMonthlyLoanPurposeCard from "../components/LoanOfficerMonthlyLoanPurposeCard";
import LoanOfficerMonthlyLoanTypeCard from "../components/LoanOfficerMonthlyLoanTypeCard";
import LoanOfficerSalesComparisonCard from "../components/LoanOfficerSalesComparisonCard";

import LoanOfficerSalesTimelineControls from "../components/LoanOfficerSalesTimelineControls";
import LoanOfficerSalesTimelineMainChart from "../components/LoanOfficerSalesTimelineMainChart";
import LoanOfficerBrokeredOutLoans from "../components/LoanOfficerBrokeredOutLoans";

const SalesTimeline = ({ currentLoanOfficer }) => {
  const location = useLocation();

  const [currentYear, setCurrentYear] = useState(
    currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear()
    ) === undefined
      ? new Date().getFullYear() - 1
      : new Date().getFullYear()
  );

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  useEffect(() => {
    if (location.state) {
      setCurrentMonth(location.state.redirectMonthIndex);
    }
  }, [location.state]);

  return (
    <>
      <LoanOfficerSalesTimelineMainChart
        currentLoanOfficer={currentLoanOfficer}
        setCurrentMonth={setCurrentMonth}
        currentYear={currentYear}
      />
      <div className="content">
        <Row>
          <LoanOfficerSalesTimelineControls
            currentLoanOfficer={currentLoanOfficer}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </Row>
        <Row>
          <LoanOfficerMonthlyLoanPurposeCard
            currentLoanOfficer={currentLoanOfficer}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
          <LoanOfficerMonthlyLoanTypeCard
            currentLoanOfficer={currentLoanOfficer}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
        </Row>
        <Row>
          <LoanOfficerBrokeredOutLoans
            currentLoanOfficer={currentLoanOfficer}
            currentYear={currentYear}
            currentMonth={currentMonth}
          />
        </Row>
        <Row>
          <LoanOfficerSalesComparisonCard
            currentLoanOfficer={currentLoanOfficer}
          />
        </Row>
      </div>
    </>
  );
};

export default SalesTimeline;
