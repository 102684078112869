import React from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

const LoanOfficerAnnualSalesHighlights = ({ currentLoanOfficer }) => {
  const currentYearSales =
    currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear()
    );

  const annualSalesTotal = currentYearSales.annualSalesTotals.total.amount;

  const annualSalesCount = currentYearSales.annualSalesTotals.total.count;

  const toOrdinal = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num;
  };

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${
          currentLoanOfficer.name.endsWith("s")
            ? `${currentLoanOfficer.name}'`
            : `${currentLoanOfficer.name}'s`
        } Annual Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {annualSalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    })}
                  </h3>
                  <h6 className="stats-title">YTD Sales Total</h6>
                </div>
              </div>
            </Col>

            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{annualSalesCount}</h3>
                  <h6 className="stats-title">YTD Loan Count</h6>
                </div>
              </div>
            </Col>

            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {currentLoanOfficer.rankings
                      ? toOrdinal(currentLoanOfficer.rankings.ytd)
                      : "-"}
                  </h3>
                  <h6 className="stats-title">Production Ranking YTD</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerAnnualSalesHighlights;
