import React from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Row } from "reactstrap";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import RegionalManagerFastFactsChart from "../components/RegionalManagerFastFactsChart";
import RegionalManagerCompanyWideSalesHighlights from "../components/RegionalManagerCompanyWideSalesHighlights";
import RegionalManagerAnnualSalesHighlights from "../components/RegionalManagerAnnualSalesHighlights";
import RegionalManagerPreviousMonthSalesHighlights from "../components/RegionalManagerPreviousMonthSalesHighlights";
import RegionalManagerCurrentMonthSalesHighlights from "../components/RegionalManagerCurrentMonthSalesHighlights";
import RegionalManagerNextMonthSalesHighlights from "../components/RegionalManagerNextMonthSalesHighlights";
import RegionalManagerTopZipsCard from "../components/RegionalManagerTopZipsCard";
import RegionalManagerTop5Referrals from "../components/RegionalManagerTop5Referrals";

const RegionalManagerFastFacts = ({
  initLoading,
  dataFor,
  currentLoanOfficer,
  currentYearData,
  regionData,
  branchData
}) => {
  const auth = useAuth();

  const mostRecentYear =
    !currentLoanOfficer.encompassMetrics.encompassSales.find(
      (salesObject) => salesObject.year === new Date().getFullYear()
    )
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();

  const mostRecentMonth = 7;

  // Redirect when logged out
  // useEffect(() => {
  //   if (auth.user === false) {
  //     history.push("/login");
  //   }
  // }, [auth.signedIn, auth.user, history]);

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <RegionalManagerFastFactsChart
            dataFor={dataFor}
            currentLoanOfficer={currentLoanOfficer}
            regionData={regionData}
            branchData={branchData}
            mostRecentYear={mostRecentYear}
            mostRecentMonth={mostRecentMonth}
          />
          <div className="content">
            <Row>
              <RegionalManagerCompanyWideSalesHighlights
                currentYearData={currentYearData}
              />
            </Row>
            <Row>
              <RegionalManagerAnnualSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerPreviousMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerCurrentMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerNextMonthSalesHighlights
                dataFor={dataFor}
                currentLoanOfficer={currentLoanOfficer}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            {dataFor === "Individual" && (
              <>
                <Row>
                  <RegionalManagerTopZipsCard
                    dataFor={dataFor}
                    currentLoanOfficer={currentLoanOfficer}
                    regionData={regionData}
                    branchData={branchData}
                  />
                </Row>
                <Row>
                  <RegionalManagerTop5Referrals
                    currentLoanOfficer={currentLoanOfficer}
                    referrals={
                      currentLoanOfficer.encompassMetrics.ytdMetrics
                        .top5Referrals
                    }
                    count={3}
                  />
                </Row>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RegionalManagerFastFacts;
