import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Row } from "reactstrap";

import Loading from "../../../components/NowUI/Loading/Loading";

import RegionalManagerAdditionalCommissionCard from "./RegionalManagerAdditionalCommissionCard";

const RegionalManagerSalesGoalAdditionalCommissionsTracker = ({
  currentLoanOfficer,
}) => {
  const sales = currentLoanOfficer.encompassMetrics.encompassSales.find(
    (salesObj) => salesObj.year === new Date().getFullYear()
  );

  const salesGoal = currentLoanOfficer?.salesGoals?.[new Date().getFullYear()];

  const [additionalCommissions, setAdditionalCommissions] = useState();
  const [loading, setLoading] = useState(true);

  const renderAdditionalCommissions = () => {
    return additionalCommissions.map((loanOfficer) => {
      return (
        <RegionalManagerAdditionalCommissionCard
          key={loanOfficer.name}
          loanOfficer={loanOfficer}
        />
      );
    });
  };

  const renderThisYearCommissionEstimate = () => {
    return additionalCommissions.map((additionalCommission) => {
      return (
        <div style={{ marginBottom: "2vh" }} key={additionalCommission.email}>
          <h6>{`${additionalCommission.name}`}</h6>
          {`${additionalCommission.thisYearSalesTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })} x ${additionalCommission.commissionPercent}% = ${Number.parseInt(
            (additionalCommission.commissionPercent / 100) *
              additionalCommission.thisYearSalesTotal
          ).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`}
        </div>
      );
    });
  };

  useEffect(() => {
    const fetchBranchMembersSales = async () => {
      setLoading(true);
      const emails = salesGoal.additionalCommissions
        .map((loanOfficer) => loanOfficer.email)
        .join();
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getLoanOfficerData?emails=${emails}&year=${new Date().getFullYear()}`
        );

        const json = await response.json();
        const newArr = salesGoal.additionalCommissions.map((loanOfficer) => {
          const thisYearSalesObject = json.find(
            (salesObj) => salesObj.email === loanOfficer.email
          );
          const thisBranchMember = currentLoanOfficer.branchMembers.find(
            (branchMember) => branchMember.email === loanOfficer.email
          );

          const thisLoanOfficerHeadshotURL =
            thisBranchMember === undefined || thisBranchMember === ""
              ? null
              : thisBranchMember.headshotURL;

          const thisYearSalesTotal = thisYearSalesObject.hasOwnProperty("sales")
            ? thisYearSalesObject.sales.annualSalesTotals.total.amount
            : 0;

          return {
            ...loanOfficer,
            thisYearSalesTotal,
            headshotURL: thisLoanOfficerHeadshotURL,
          };
        });
        setAdditionalCommissions(newArr);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBranchMembersSales();
  }, [
    currentLoanOfficer.branchMembers,
    salesGoal.additionalCommissions,
    salesGoal.additionalCommissions.length,
  ]);

  return (
    <Card>
      <CardHeader className="card-category">Additional Commissions</CardHeader>
      <CardBody>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row>{renderAdditionalCommissions()}</Row>
            <div
              style={{
                margin: "0 0 0 auto",
                textAlign: "end",
              }}
            >
              <h5>Current Year Estimated Commission</h5>
              <br />
              <h6>Commission from my loans</h6>
              <div
                style={{ marginBottom: "2vh" }}
              >{`${sales.annualSalesTotals.total.amount.toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )} x ${
                salesGoal.selfGeneratedCommissionPercent
              }% = ${Number.parseInt(
                (salesGoal.selfGeneratedCommissionPercent / 100) *
                  sales.annualSalesTotals.total.amount
              ).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`}</div>
              {renderThisYearCommissionEstimate()}
              <h5>{`Total:
            ${(
              additionalCommissions.reduce((prevValue, currentValue) => {
                return (
                  prevValue +
                  Number.parseInt(
                    (currentValue.commissionPercent / 100) *
                      currentValue.thisYearSalesTotal
                  )
                );
              }, 0) +
              Number.parseInt(
                (salesGoal.selfGeneratedCommissionPercent / 100) *
                  sales.annualSalesTotals.total.amount
              )
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</h5>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default RegionalManagerSalesGoalAdditionalCommissionsTracker;
