import React, {useState, useEffect} from "react";
import {Col, Card, CardHeader, CardBody, Row} from "reactstrap";

import MoneyBag from "../../../assets/icons/Essex_Icons_Money Bag.svg";
import Closing from "../../../assets/icons/Essex_Icons_Closing.svg";

const LoanOfficerCompanyWideAnnualSalesHighlights = ({currentYearData}) => {
  const [lastYearData, setLastYearData] = useState({});

  const previousMonthIndex =
    new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;

  const currentMonthIndex = new Date().getMonth();
  useEffect(() => {
    const fetchLastYearData = async () => {
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${
            new Date().getFullYear() - 1
          }`,
        );
        const json = await response.json();
        setLastYearData(json);
      } catch (error) {
        console.log(error);
      }
    };

    if (previousMonthIndex === 11) {
      fetchLastYearData();
    }
  }, [previousMonthIndex, setLastYearData]);

  const yearDataObject =
    previousMonthIndex === 11 ? lastYearData : currentYearData;

  const annualSalesTotal = yearDataObject.encompassSales
    ? currentYearData.encompassSales.annualSalesTotals.total.amount
    : 0;

  const previousMonthSalesTotal = yearDataObject.encompassSales
    ? yearDataObject.encompassSales.monthlySalesTotals[previousMonthIndex].total
        .amount
    : 0;

  const currentMonthSalesTotal = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals[currentMonthIndex].total
        .amount
    : 0;

  const previousMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][previousMonthIndex];

  const currentMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][currentMonthIndex];

  return (
    <Col xs={12} md={12}>
      <Card
        className="card-stats card-raised"
        style={{
          border: "solid 3px #012c4b",
          boxShadow: "0 1px 10px 1px #012c4b",
        }}
      >
        <CardHeader className="card-category">{`Company Wide Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={Closing} alt="closing" />
                  </div>
                  <h3 className="info-title">
                    {previousMonthSalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${previousMonth} Sales Total`}</h6>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={Closing} alt="closing" />
                  </div>
                  <h3 className="info-title">
                    {currentMonthSalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${currentMonth} Sales Total`}</h6>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={MoneyBag} alt="money" />
                  </div>
                  <h3 className="info-title">
                    {annualSalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">YTD Sales Total</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerCompanyWideAnnualSalesHighlights;
