import React, {useState} from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";
import RegionalManagerAdditionalCommissions from "../components/RegionalManagerAdditionalCommissions";
import LoanOfficerSalesGoalConfirmationModal from "../../loan-officer/components/LoanOfficerSalesGoalConfirmationModal";

const RegionalManagerCommissionCalculator = ({
  regionData,
  currentLoanOfficer,
  prevYearsAnnualSalesTotals,
  avgLoanAmount,
  handleGoalSetChange,
  setCalculatorOpen,
  goalSet,
}) => {
  const [commissionGoal, setCommissionGoal] = useState(0);
  const [selfGeneratedCommissionPercent, setSelfGeneratedCommissionPercent] =
    useState(0.75);
  const [
    companyGeneratedCommissionPercent,
    setCompanyGeneratedCommissionPercent,
  ] = useState(0.5);
  const [percentChange, setPercentChange] = useState(
    (
      ((commissionGoal / (selfGeneratedCommissionPercent / 100) -
        prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
          .amount) /
        Math.abs(
          prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
            .amount,
        )) *
      100
    ).toFixed(2),
  );
  const [additionalCommissions, setAdditionalCommissions] = useState(
    regionData.regionMembers
      .filter((loanOfficer) => loanOfficer.name !== currentLoanOfficer.name)
      .map((loanOfficer) => ({
        ...loanOfficer,
        commissionPercent: 0,
      })),
  );
  const [modalOpen, setModalOpen] = useState(false);

  const handleCommissionGoalChange = (value) => {
    setPercentChange(
      (
        ((value / (selfGeneratedCommissionPercent / 100) -
          prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
            .amount) /
          Math.abs(
            prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
              .amount,
          )) *
        100
      ).toFixed(2),
    );
    setCommissionGoal(value);
  };
  const handleSelfGeneratedCommissionPercentChange = (value) => {
    if (value === undefined || value >= 0) {
      setPercentChange(
        (
          ((commissionGoal / (value / 100) -
            prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
              .amount) /
            Math.abs(
              prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
                .amount,
            )) *
          100
        ).toFixed(2),
      );
      setSelfGeneratedCommissionPercent(value);
    }
  };

  const handleGoalPercentChange = (value) => {
    setCommissionGoal(
      Number.parseInt(
        prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
          .amount *
          (selfGeneratedCommissionPercent / 100) +
          prevYearsAnnualSalesTotals[prevYearsAnnualSalesTotals.length - 1]
            .amount *
            (selfGeneratedCommissionPercent / 100) *
            (value / 100),
      ),
    );
    setPercentChange(value);
  };

  const handleGoalSet = () => {
    setModalOpen(true);
  };

  const renderPrevYearsProduction = () => {
    return prevYearsAnnualSalesTotals.map((salesObj) => {
      return (
        <Col key={salesObj.year}>
          <div className="statistics">
            <div className="info">
              <h6 className="stats-title">{salesObj.year} Total Production</h6>
              <h3 className="info-title">
                {salesObj.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </h3>
              <div>{salesObj.count} loans </div>
              {salesObj.salesAmountPercentChange && (
                <div>{`${salesObj.salesAmountPercentChange} ${
                  salesObj.salesAmountPercentChange >= 0
                    ? "production decrease"
                    : "production increase"
                }`}</div>
              )}
            </div>
          </div>
        </Col>
      );
    });
  };

  return (
    <Card style={{boxShadow: "none"}} className="card-stats card-raised">
      <CardBody>
        <Row md="12">
          {renderPrevYearsProduction()}
          <Col>
            <div className="statistics">
              <div className="info">
                <h6 className="stats-title">
                  Previous Year Average Loan Amount
                </h6>
                <h3 className="info-title">
                  {Number.parseInt(avgLoanAmount).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </h3>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          style={{
            padding: "4vh 0",
            margin: "4vh 1vw",
            border: "solid 3px #012c4b",
            boxShadow: "0 1px 10px 1px #012c4b",
          }}
        >
          <Col md={3}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(
                    commissionGoal / (selfGeneratedCommissionPercent / 100),
                  ) ||
                  !Number.isFinite(
                    commissionGoal / (selfGeneratedCommissionPercent / 100),
                  )
                    ? "-"
                    : (
                        commissionGoal /
                        (selfGeneratedCommissionPercent / 100)
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                </h3>
                <h6 className="stats-title">Production needed to meet goal</h6>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(
                    commissionGoal /
                      ((selfGeneratedCommissionPercent / 100) * avgLoanAmount),
                  ) ||
                  !Number.isFinite(
                    commissionGoal /
                      ((selfGeneratedCommissionPercent / 100) * avgLoanAmount),
                  )
                    ? "-"
                    : Math.round(
                        (commissionGoal /
                          ((selfGeneratedCommissionPercent / 100) *
                            avgLoanAmount)) *
                          100,
                      ) / 100}
                </h3>
                <h6 className="stats-title">Loans needed to meet goal</h6>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(
                    commissionGoal /
                      (selfGeneratedCommissionPercent / 100) /
                      12,
                  ) ||
                  !Number.isFinite(
                    commissionGoal /
                      (selfGeneratedCommissionPercent / 100) /
                      12,
                  )
                    ? "-"
                    : (
                        commissionGoal /
                        (selfGeneratedCommissionPercent / 100) /
                        12
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                </h3>
                <h6 className="stats-title">Production needed per month</h6>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {Number.isNaN(
                    commissionGoal /
                      ((selfGeneratedCommissionPercent / 100) * avgLoanAmount) /
                      12,
                  ) ||
                  !Number.isFinite(
                    commissionGoal /
                      ((selfGeneratedCommissionPercent / 100) * avgLoanAmount) /
                      12,
                  )
                    ? "-"
                    : Math.ceil(
                        commissionGoal /
                          ((selfGeneratedCommissionPercent / 100) *
                            avgLoanAmount) /
                          12,
                      )}
                </h3>
                <h6 className="stats-title">Loans needed per month</h6>
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{marginTop: "50px"}}>
          <Col md={3}>
            <FormGroup>
              <Label for="self-percent-change">
                <h6>Self Generated Commission Percent</h6>
              </Label>
              <InputGroup>
                <Input
                  type="number"
                  step={0.01}
                  style={{
                    borderRadius: "5px",
                  }}
                  id="self-percent-change"
                  placeholder=""
                  value={selfGeneratedCommissionPercent}
                  onChange={(e) =>
                    handleSelfGeneratedCommissionPercentChange(e.target.value)
                  }
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>

          <Col md={3}>
            <FormGroup>
              <Label for="company-percent-change">
                <h6>Company Generated Commission Percent</h6>
              </Label>
              <InputGroup>
                <Input
                  type="number"
                  step={0.01}
                  style={{
                    borderRadius: "5px",
                  }}
                  id="company-percent-change"
                  placeholder=""
                  value={companyGeneratedCommissionPercent}
                  onChange={(e) =>
                    setCompanyGeneratedCommissionPercent(e.target.value)
                  }
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>

          <Col md="3">
            <FormGroup>
              <Label for="commission-goal">
                <h6>Annual Commission Goal</h6>
              </Label>
              <CurrencyInput
                className="form-control"
                style={{borderRadius: "5px"}}
                id="commission-goal"
                prefix="$"
                placeholder=""
                value={commissionGoal}
                defaultValue={0}
                decimalsLimit={0}
                onValueChange={(value) => handleCommissionGoalChange(value)}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="percent-change">
                <h6>Percent change from last year</h6>
              </Label>
              <InputGroup>
                <Input
                  type="number"
                  step={0.1}
                  style={{
                    borderRadius: "5px",
                  }}
                  id="percent-change"
                  placeholder=""
                  value={percentChange}
                  onChange={(e) => handleGoalPercentChange(e.target.value)}
                />
                <InputGroupText>%</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        {currentLoanOfficer.branchMembers.length > 1 && (
          <div style={{margin: "4vh 0"}}>
            <RegionalManagerAdditionalCommissions
              prevYearsAnnualSalesTotals={prevYearsAnnualSalesTotals}
              selfGeneratedCommissionPercent={selfGeneratedCommissionPercent}
              additionalCommissions={additionalCommissions}
              setAdditionalCommissions={setAdditionalCommissions}
            />
          </div>
        )}

        <Row>
          {goalSet ? (
            <Button
              disabled={goalSet}
              style={{
                cursor: "not-allowed",
                backgroundColor: "#ff2211",
                margin: "1vh 15px 1vh auto",
              }}
            >
              Goal Already Set
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#061f54",
                margin: "1vh 15px 1vh auto",
              }}
              onClick={handleGoalSet}
            >
              Review Goal
            </Button>
          )}
        </Row>
        <LoanOfficerSalesGoalConfirmationModal
          currentLoanOfficer={currentLoanOfficer}
          commissionGoal={commissionGoal}
          selfGeneratedCommissionPercent={selfGeneratedCommissionPercent}
          companyGeneratedCommissionPercent={companyGeneratedCommissionPercent}
          additionalCommissions={additionalCommissions}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          handleGoalSetChange={handleGoalSetChange}
          setCalculatorOpen={setCalculatorOpen}
        />
      </CardBody>
    </Card>
  );
};

export default RegionalManagerCommissionCalculator;
