import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const AccountExecutiveCurrentMonthSalesHighlights = ({
  currentAccountExecutive,
}) => {
  const history = useHistory();

  // const thisMonth = new Date().getMonth();

  // const thisYear = new Date().getFullYear();

  const currentYearSales = currentAccountExecutive.encompassSales.find(
    (salesObject) => salesObject.year === new Date().getFullYear(),
  );

  const currentMonthSales =
    currentYearSales.monthlySalesTotals[new Date().getMonth()];

  const monthlySalesTotal = currentMonthSales.total.amount;
  const monthlySalesCount = currentMonthSales.total.count;

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][new Date().getMonth()];

  //   const loansExpectedToCloseThisMonth =
  //     currentAccountExecutive.encompassMetrics.pipelineLoans.filter(
  //       (loan) =>
  //         new Date(loan.fields["Fields.CX.PDEXFUND"]).getFullYear() ===
  //           thisYear &&
  //         new Date(loan.fields["Fields.CX.PDEXFUND"]).getMonth() === thisMonth,
  //     );

  // const toOrdinal = (num) => {
  //   const j = num % 10,
  //     k = num % 100;
  //   if (j === 1 && k !== 11) {
  //     return num + "st";
  //   }
  //   if (j === 2 && k !== 12) {
  //     return num + "nd";
  //   }
  //   if (j === 3 && k !== 13) {
  //     return num + "rd";
  //   }
  //   return num;
  // };

  return (
    <Col xs={12} md={6}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${
          currentAccountExecutive.name.endsWith("s")
            ? `${currentAccountExecutive.name}'`
            : `${currentAccountExecutive.name}'s`
        } ${month} Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {monthlySalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${month} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{monthlySalesCount}</h3>
                  <h6 className="stats-title">{`${month} Loan Count`}</h6>
                </div>
              </div>
            </Col>
            {/*
            <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">N/A</h3>
                  <h6 className="stats-title">{`${month} Production Ranking`}</h6>
                </div>
              </div>
            </Col> */}

            {/* <Col md="3">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">N/A</h3>
                  <h6 className="stats-title">{`Remaining Sales Estimated to settle in ${month}`}</h6>
                </div>
              </div>
            </Col> */}
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/account-executive/sales/sales-timeline");
            }}
          >
            See My Sales Timeline
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default AccountExecutiveCurrentMonthSalesHighlights;
