import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const LoanOfficerSalesGoalCommissionBreakdown = ({ currentLoanOfficer }) => {
  const [additionalCommissions, setAdditionalCommissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const sales = currentLoanOfficer.encompassMetrics.encompassSales.find(
    (salesObj) => salesObj.year === new Date().getFullYear()
  );

  const salesGoal = currentLoanOfficer?.salesGoals?.[new Date().getFullYear()];

  const selfGeneratedCommission =
    sales.annualSalesTotals.selfGenerated.amount *
    (salesGoal.selfGeneratedCommissionPercent / 100);

  const companyGeneratedCommission =
    sales.annualSalesTotals.companyGenerated.amount *
    (salesGoal.companyGeneratedCommissionPercent / 100);

  const additionalCommissionsTotal =
    additionalCommissions.length > 0
      ? additionalCommissions.reduce((total, curr) => {
          return (
            total + curr.thisYearSalesTotal * (curr.commissionPercent / 100)
          );
        }, 0)
      : 0;

  const commissionFromMySales =
    selfGeneratedCommission + companyGeneratedCommission;

  const commissionFromAll = additionalCommissionsTotal + commissionFromMySales;

  useEffect(() => {
    const fetchBranchMembersSales = async () => {
      if (salesGoal.additionalCommissions.length < 1) return;
      setLoading(true);
      const emails = salesGoal.additionalCommissions
        .map((loanOfficer) => loanOfficer.email)
        .join();
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getLoanOfficerData?emails=${emails}&year=${new Date().getFullYear()}`
        );

        const json = await response.json();
        const newArr = salesGoal.additionalCommissions.map((loanOfficer) => {
          const thisYearSalesObject = json.find(
            (salesObj) => salesObj.email === loanOfficer.email
          );
          const thisBranchMember = currentLoanOfficer.branchMembers.find(
            (branchMember) => branchMember.email === loanOfficer.email
          );

          const thisLoanOfficerHeadshotURL =
            thisBranchMember === undefined || thisBranchMember === ""
              ? null
              : thisBranchMember.headshotURL;

          const thisYearSalesTotal = thisYearSalesObject.hasOwnProperty("sales")
            ? thisYearSalesObject.sales.annualSalesTotals.total.amount
            : 0;

          return {
            ...loanOfficer,
            thisYearSalesTotal,
            headshotURL: thisLoanOfficerHeadshotURL,
          };
        });
        setAdditionalCommissions(newArr);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBranchMembersSales();
  }, [
    currentLoanOfficer.branchMembers,
    salesGoal.additionalCommissions,
    salesGoal.additionalCommissions.length,
  ]);

  return (
    <Card className="card-stats card-raised">
      <CardHeader className="card-category">{`${
        currentLoanOfficer.name.endsWith("s")
          ? `${currentLoanOfficer.name}'`
          : `${currentLoanOfficer.name}'s`
      } Commission Breakdown`}</CardHeader>
      <CardBody>
        <Row>
          {loading === false && additionalCommissions.length > 0 ? (
            <>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.parseInt(salesGoal.commissionGoal).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                    </h3>
                    <h6 className="stats-title">Commission Goal</h6>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {`${commissionFromMySales.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}
                    </h3>
                    <h6 className="stats-title">Commission From My Sales</h6>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {additionalCommissionsTotal.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h3>
                    <h6 className="stats-title">
                      Commission From Additional Incomes
                    </h6>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {commissionFromAll.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </h3>
                    <h6 className="stats-title">Commission From All Sources</h6>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col md="6">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {Number.parseInt(salesGoal.commissionGoal).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )}
                    </h3>
                    <h6 className="stats-title">Commission Goal</h6>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="statistics">
                  <div className="info">
                    <h3 className="info-title">
                      {`${commissionFromMySales.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`}
                    </h3>
                    <h6 className="stats-title">Commission From My Sales</h6>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${salesGoal.selfGeneratedCommissionPercent}%`}
                </h3>
                <h6 className="stats-title">
                  My Self Generated Commission Percent
                </h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${sales.annualSalesTotals.selfGenerated.amount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}`}
                </h3>
                <h6 className="stats-title">
                  My Self Generated Total Production
                </h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${sales.annualSalesTotals.selfGenerated.count}`}
                </h3>
                <h6 className="stats-title">My Self Generated Total Loans</h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${selfGeneratedCommission.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`}
                </h3>
                <h6 className="stats-title">
                  Commission From self Generated Loans
                </h6>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${salesGoal.companyGeneratedCommissionPercent}%`}
                </h3>
                <h6 className="stats-title">
                  My company Generated Commission Percent
                </h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${sales.annualSalesTotals.companyGenerated.amount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}`}
                </h3>
                <h6 className="stats-title">
                  My company Generated Total Production
                </h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${sales.annualSalesTotals.companyGenerated.count}`}
                </h3>
                <h6 className="stats-title">
                  My company Generated Total Loans
                </h6>
              </div>
            </div>
          </Col>
          <Col md="3">
            <div className="statistics">
              <div className="info">
                <h3 className="info-title">
                  {`${companyGeneratedCommission.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`}
                </h3>
                <h6 className="stats-title">
                  Commission From Company Generated Loans
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default LoanOfficerSalesGoalCommissionBreakdown;
