import React from "react";
import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";
import {Line} from "react-chartjs-2";

import {Col, Card, CardTitle, CardHeader, CardBody} from "reactstrap";

const AdminBranchCard = ({
  branchName,
  branchCode,
  branchSales,
  year,
  month,
  setMonth,
}) => {
  const annualSalesAmounts = branchSales.find(
    (element) => element.year === year,
  )
    ? branchSales
        .find((element) => element.year === year)
        .monthlySalesTotals.map((element) => element.total.amount)
    : [];

  const annualSalesCounts = branchSales.find((element) => element.year === year)
    ? branchSales
        .find((element) => element.year === year)
        .monthlySalesTotals.map((element) => element.total.count)
    : [];

  const ytdTotal = branchSales.find((element) => element.year === year)
    ? branchSales.find((element) => element.year === year).annualSalesTotals
        .total.amount
    : 0;

  const thisMonthTotal = branchSales.find((element) => element.year === year)
    ? branchSales.find((element) => element.year === year).monthlySalesTotals[
        month
      ].total.amount
    : 0;

  const monthLabel = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];

  const branchCardChart = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "Total Sales: ",
            borderColor: chartColor,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2c2c2c",
            pointHoverBackgroundColor: "#2c2c2c",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: annualSalesAmounts,
          },
        ],
      };
    },
    options: {
      onClick: (event, element) => {
        if (element.length > 0) {
          const index = element[0]._index;
          setMonth(index);
        }
      },
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },

      layout: {},
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#333",
        titleFontSize: 16,
        bodyFontColor: "#666",
        bodyFontSize: 16,
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const dollarValue = parseInt(tooltipItem.value).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              },
            );
            const loanCount = annualSalesCounts[tooltipItem.index];

            return `${label} ${dollarValue}
          Count: ${loanCount}`;
          },
        },
      },
      legend: {
        position: "bottom",
        fillStyle: "#FFF",
        display: false,
      },

      scales: {
        yAxes: [
          {
            ticks: {
              display: false,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 4,
              padding: 10,
              callback: (value, index, values) => {
                return value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                });
              },
            },
            gridLines: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "rgba(255,255,255,0.1)",
            },
            ticks: {
              padding: 10,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
            },
          },
        ],
      },
    },
  };
  return (
    <Col md={4}>
      <Card className="card-chart">
        <CardHeader className="card-category">
          <CardTitle tag="h6">{`${branchName} (${branchCode})`}</CardTitle>
          {`Branch YTD Production: ${ytdTotal.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`}
        </CardHeader>
        <CardBody>
          <div className="chart-area" style={{marginBottom: "100px"}}>
            <PanelHeader
              size="md"
              content={
                <Line
                  data={branchCardChart.data}
                  options={branchCardChart.options}
                />
              }
            />
          </div>
          <h6>{`${monthLabel} ${year} Total Production`}</h6>
          <p>
            {thisMonthTotal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminBranchCard;
