import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

import "./Spinner.css";

const Spinner = ({ title }) => {
  return (
    <div className="backdrop">
      <div className="spinner-modal">
        <h1 className="spinner-title">{title}</h1>
        <FadeLoader color="#061f54" size={150} />
      </div>
    </div>
  );
};

export default Spinner;
