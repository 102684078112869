import React from "react";
import {Col, Row, Card, CardHeader, CardBody} from "reactstrap";

const AdminEncompassMetricsCurrentMonthHighlights = ({currentYearData}) => {
  const thisMonthIndex = new Date().getMonth();
  const thisMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][thisMonthIndex];

  const thisMonthLoanCount =
    currentYearData.encompassSales.monthlySalesTotals[thisMonthIndex].total
      .count;

  const thisMonthLoanAmount =
    currentYearData.encompassSales.monthlySalesTotals[thisMonthIndex].total
      .amount;

  return (
    <Col xs={6}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {`${thisMonth} Metrics`}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{thisMonthLoanCount}</h3>
                  <h6 className="stats-title">{`Loans Funded in ${thisMonth}`}</h6>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {thisMonthLoanAmount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`Loan Amount Funded in ${thisMonth}`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminEncompassMetricsCurrentMonthHighlights;
