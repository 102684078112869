import React from "react";
import {Col, Card, CardHeader, CardBody, CardFooter, Table} from "reactstrap";
import {Doughnut} from "react-chartjs-2";

const AdminMonthlyLoanChannelCard = ({secondYearData, currentMonth}) => {
  const currentSalesObject = secondYearData.loanChannels;

  //Correspondent = reverse
  const dashboardMonthlyLoanTypeDoughnutChart = {
    data: {
      labels: ["Brokered", "Reverse", "Retail"],
      datasets: [
        {
          label: "Loan Channels Sales Amount",
          data: [
            currentSalesObject.brokeredLoans.amount,
            currentSalesObject.correspondentLoans.amount,
            currentSalesObject.retailLoans.amount,
          ],
          backgroundColor: [
            "#66a7dd",
            "#b33633",
            "#212d64",
            "#9ecf61",
            "#535759",
            "#303b4f",
          ],
          borderColor: ["#fff"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];

            const dollarValue = parseInt(
              data.datasets[0].data[tooltipItem.index],
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
            return `${label}: ${dollarValue}`;
          },
        },
      },
      legend: {
        onHover: function (e) {
          e.target.style.cursor = "pointer";
        },
        onLeave: function (e) {
          e.target.style.cursor = "default";
        },
        position: "right",
        labels: {
          fontSize: 15,
        },
      },
    },
  };

  const toDollarString = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <Col xs={12} md={6}>
      <Card className="card-chart">
        <CardHeader className="card-category">
          {`${secondYearData.encompassSales.year} `}
          Loan Channels
        </CardHeader>
        <CardBody>
          <Doughnut
            key={currentMonth}
            data={dashboardMonthlyLoanTypeDoughnutChart.data}
            options={dashboardMonthlyLoanTypeDoughnutChart.options}
          />

          <Table responsive>
            <tbody>
              {currentSalesObject.brokeredLoans.count > 0 && (
                <tr>
                  <td>Brokered</td>
                  <td className="text-right">
                    {Math.round(
                      (currentSalesObject.brokeredLoans.count /
                        (currentSalesObject.brokeredLoans.count +
                          currentSalesObject.correspondentLoans.count +
                          currentSalesObject.retailLoans.count)) *
                        100 *
                        100,
                    ) /
                      100 +
                      "%"}
                  </td>
                  <td className="text-right">
                    {currentSalesObject.brokeredLoans.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.brokeredLoans.amount)}
                  </td>
                </tr>
              )}
              {currentSalesObject.correspondentLoans.count > 0 && (
                <tr>
                  <td>Reverse</td>
                  <td className="text-right">
                    {Math.round(
                      (currentSalesObject.correspondentLoans.count /
                        (currentSalesObject.brokeredLoans.count +
                          currentSalesObject.correspondentLoans.count +
                          currentSalesObject.retailLoans.count)) *
                        100,
                    ) + "%"}
                  </td>
                  <td className="text-right">
                    {currentSalesObject.correspondentLoans.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(
                      currentSalesObject.correspondentLoans.amount,
                    )}
                  </td>
                </tr>
              )}
              {currentSalesObject.retailLoans.count > 0 && (
                <tr>
                  <td>Retail</td>
                  <td className="text-right">
                    {Math.round(
                      (currentSalesObject.retailLoans.count /
                        (currentSalesObject.brokeredLoans.count +
                          currentSalesObject.correspondentLoans.count +
                          currentSalesObject.retailLoans.count)) *
                        100,
                    ) + "%"}
                  </td>
                  <td className="text-right">
                    {currentSalesObject.retailLoans.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(currentSalesObject.retailLoans.amount)}
                  </td>
                </tr>
              )}

              {currentSalesObject.brokeredLoans.amount +
                currentSalesObject.correspondentLoans.amount +
                currentSalesObject.retailLoans.amount >
                0 && (
                <tr>
                  <td>Total</td>
                  <td className="text-right"></td>
                  <td className="text-right">
                    {currentSalesObject.brokeredLoans.count +
                      currentSalesObject.correspondentLoans.count +
                      currentSalesObject.retailLoans.count}
                  </td>
                  <td className="text-right">
                    {toDollarString(
                      currentSalesObject.brokeredLoans.amount +
                        currentSalesObject.correspondentLoans.amount +
                        currentSalesObject.retailLoans.amount,
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </Col>
  );
};

export default AdminMonthlyLoanChannelCard;
