import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Row} from "reactstrap";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import CorrespondentAdminSalesTimelineMainChart from "../components/CorrespondentAdminSalesTimelineMainChart";
import CorrespondentAdminSalesTimelineControls from "../components/CorrespondentAdminSalesTimelineControls";
import CorrespondentAdminMonthlyLoanPurposeCard from "../components/CorrespondentAdminMonthlyLoanPurposeCard";
import CorrespondentAdminMonthlyLoanTypeCard from "../components/CorrespondentAdminMonthlyLoanTypeCard";
import CorrespondentAdminSalesComparisonCard from "../components/CorrespondentAdminSalesComparisonCard";
import CorrespondentAdminMonthlyPropertyTypeCard from "../components/CorrespondentAdminMonthlyPropertyTypeCard";

const CorrespondentAdminSalesTimeline = ({currentYearData}) => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [secondYearData, setSecondYearData] = useState({
    loanChannels: {
      brokeredLoans: {count: 0, amount: 0},
      correspondentLoans: {count: 0, amount: 0},
      retailLoans: {count: 0, amount: 0},
      wholesaleLoans: {count: 0, amount: 0},
    },
    encompassSales: {
      annualSalesTotals: {
        purchase: {amount: 0, count: 0},
        noCashRefinance: {amount: 0, count: 0},
        cashRefinance: {amount: 0, count: 0},
        otherPurpose: {amount: 0, count: 0},
        conventional: {amount: 0, count: 0},
        fha: {amount: 0, count: 0},
        va: {amount: 0, count: 0},
        usda: {amount: 0, count: 0},
        reverse: {amount: 0, count: 0},
        otherType: {amount: 0, count: 0},
        total: {amount: 0, count: 0},
        attached: {amount: 0, count: 0},
        condo: {amount: 0, count: 0},
        cooperative: {amount: 0, count: 0},
        detached: {amount: 0, count: 0},
        highRiseCondo: {amount: 0, count: 0},
        manufactured: {amount: 0, count: 0},
        pud: {amount: 0, count: 0},
        detachedCondo: {amount: 0, count: 0},
        otherPropertyType: {amount: 0, count: 0},
        mhSelect: {amount: 0, count: 0},
        mhAdvantage: {amount: 0, count: 0},
      },
      monthlySalesTotals: [
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
          attached: {amount: 0, count: 0},
          condo: {amount: 0, count: 0},
          cooperative: {amount: 0, count: 0},
          detached: {amount: 0, count: 0},
          highRiseCondo: {amount: 0, count: 0},
          manufactured: {amount: 0, count: 0},
          pud: {amount: 0, count: 0},
          detachedCondo: {amount: 0, count: 0},
          otherPropertyType: {amount: 0, count: 0},
          mhSelect: {amount: 0, count: 0},
          mhAdvantage: {amount: 0, count: 0},
        },
      ],
    },
  });
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (location.state) {
      setCurrentMonth(location.state.redirectMonthIndex);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchCompanyWideData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getCompanyYearData?year=${currentYear}&channel=Correspondent`,
        );
        const json = await response.json();
        setSecondYearData(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyWideData();
  }, [setSecondYearData, currentYear]);

  return (
    <>
      {loading && <Spinner title="Fetching Company Wide Sales Data" />}
      <CorrespondentAdminSalesTimelineMainChart
        currentYearData={currentYearData}
        secondYearData={secondYearData}
      />
      <div className="content">
        <Row>
          <CorrespondentAdminSalesTimelineControls
            secondYearData={secondYearData}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </Row>
        <Row>
          <CorrespondentAdminMonthlyLoanPurposeCard
            secondYearData={secondYearData}
            currentMonth={currentMonth}
            currentYear={currentYear}
          />
          <CorrespondentAdminMonthlyLoanTypeCard
            secondYearData={secondYearData}
            currentMonth={currentMonth}
            currentYear={currentYear}
          />
        </Row>
        <Row>
          <CorrespondentAdminMonthlyPropertyTypeCard
            secondYearData={secondYearData}
            currentMonth={currentMonth}
            currentYear={currentYear}
          />
        </Row>
        <Row>
          <CorrespondentAdminSalesComparisonCard
            secondYearData={secondYearData}
          />
        </Row>
      </div>
    </>
  );
};

export default CorrespondentAdminSalesTimeline;
