import React, { useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Row, Label } from "reactstrap";
import AdminBranchCard from "./AdminBranchCard";
import Select from "react-select";
import Spinner from "../../../components/NowUI/Spinner/Spinner";
const AdminBranchesList = () => {
  const [allBranches, setAllBranches] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllBranches = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getAllBranches`
        );
        const json = await response.json();
        setAllBranches(json);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllBranches();
  }, []);

  const renderBranches = () => {
    return allBranches.map((branch) => {
      if (branch.branchCode === "0") {
        return null;
      } else {
        return (
          <AdminBranchCard
            month={month}
            setMonth={setMonth}
            year={year}
            key={branch.branchCode}
            branchName={branch.branchName}
            branchCode={branch.branchCode}
            branchSales={branch.branchSales}
            loanOfficers={branch.loanOfficers}
          />
        );
      }
    });
  };

  const yearOptions = [
    { value: 2019, label: "2019" },
    { value: 2020, label: "2020" },
    { value: 2021, label: "2021" },
    { value: 2022, label: "2022" },
    { value: 2023, label: "2023" },
    { value: 2024, label: "2024" },
  ];

  return (
    <Col md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`All Branches`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <Label for="yearSelect">Year</Label>
              <Select
                className="primary"
                placeholder={year}
                name="yearSelect"
                value={year}
                options={yearOptions}
                onChange={(value) => setYear(parseInt(value.value))}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {loading && <Spinner title="Fetching Branch Data" />}
      <Row>{renderBranches()}</Row>
    </Col>
  );
};

export default AdminBranchesList;
