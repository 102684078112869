import React from "react";
import {Col, Card, CardHeader, CardBody, Row} from "reactstrap";

import MoneyBag from "../../../assets/icons/Essex_Icons_Money Bag.svg";
import Increase from "../../../assets/icons/Essex_Icons_Increase.svg";

const AdminAnnualSalesHighlights = ({currentYearData}) => {
  const annualSalesTotal = currentYearData.encompassSales
    ? currentYearData.encompassSales.annualSalesTotals.total.amount
    : 0;

  const annualSalesCount = currentYearData.encompassSales
    ? currentYearData.encompassSales.annualSalesTotals.total.count
    : 0;

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`Annual Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={MoneyBag} alt="money" />
                  </div>

                  <h3 className="info-title">
                    {annualSalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">YTD Sales Total</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <img src={Increase} alt="increase" />
                  </div>
                  <h3 className="info-title">{annualSalesCount}</h3>
                  <h6 className="stats-title">YTD Loan Count</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminAnnualSalesHighlights;
