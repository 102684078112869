import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const BranchManagerDataSelector = ({ dataFor, setDataFor }) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle caret>{`Viewing data for: ${dataFor}`}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setDataFor("Branch")}>
          My Branch
        </DropdownItem>
        <DropdownItem onClick={() => setDataFor("Individual")}>
          My Data
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default BranchManagerDataSelector;
