import React, {useState, useEffect} from "react";
import CorrespondentAdminSearchResultItem from "../CorrespondentAdminSearchResultItem/CorrespondentAdminSearchResultItem";

import "./SearchResultsList.css";

const CorrespondentAdminSearchResultsList = ({
  matchedAccountExecutives,
  changeAccountExecutive,
  setSearchTerm,
  currentIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasMatch = matchedAccountExecutives.length > 0 ? true : false;

    setIsOpen(hasMatch);
  }, [matchedAccountExecutives]);

  const renderList = () => {
    return (
      <div className="search-results-container fade-in">
        {matchedAccountExecutives.map((accountExecutive, index) => (
          <CorrespondentAdminSearchResultItem
            key={accountExecutive.name}
            name={accountExecutive.name}
            email={accountExecutive.email}
            changeAccountExecutive={changeAccountExecutive}
            setSearchTerm={setSearchTerm}
            setIsOpen={setIsOpen}
            currentIndex={currentIndex}
            givenIndex={index}
          />
        ))}
      </div>
    );
  };
  return <>{isOpen && renderList()}</>;
};

export default CorrespondentAdminSearchResultsList;
