import React, { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Row } from "reactstrap";
import { useLocation } from "react-router-dom";

import Spinner from "../../../components/NowUI/Spinner/Spinner";
import RegionalManagerSalesTimelineMainChart from "../components/RegionalManagerSalesTimelineMainChart";
import RegionalManagerSalesTimelineControls from "../components/RegionalManagerSalesTimelineControls";
import RegionalManagerMonthlyLoanPurposeCard from "../components/RegionalManagerMonthlyLoanPurposeCard";
import RegionalManagerMonthlyLoanTypeCard from "../components/RegionalManagerMonthlyLoanTypeCard";
import RegionalManagerBrokeredOutLoans from "../components/RegionalManagerBrokeredOutLoans";
import RegionalManagerSalesComparisonCard from "../components/RegionalManagerSalesComparisonCard";

const RegionalManagerSalesTimeline = ({
  initLoading,
  dataFor,
  currentLoanOfficer,
  currentYearData,
  regionData,
  branchData
}) => {
  const auth = useAuth();
  const location = useLocation();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());

  useEffect(() => {
    if (location.state) {
      setCurrentMonth(location.state.redirectMonthIndex);
    }
  }, [location.state]);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
    setCurrentMonth(100);
  }, [dataFor]);

  // Redirect when logged out
  // useEffect(() => {
  //   if (auth.user === false) {
  //     history.push("/login");
  //   }
  // }, [auth.signedIn, auth.user, history]);

  return (
    <>
      {auth.user === null && <Spinner />}
      {initLoading === false && (
        <>
          <RegionalManagerSalesTimelineMainChart
            currentLoanOfficer={currentLoanOfficer}
            setCurrentMonth={setCurrentMonth}
            currentYear={currentYear}
            dataFor={dataFor}
            regionData={regionData}
            branchData={branchData}
          />
          <div className="content">
            <Row>
              <RegionalManagerSalesTimelineControls
                currentLoanOfficer={currentLoanOfficer}
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
                dataFor={dataFor}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerMonthlyLoanPurposeCard
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                regionData={regionData}
                branchData={branchData}
              />
              <RegionalManagerMonthlyLoanTypeCard
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerBrokeredOutLoans
                currentLoanOfficer={currentLoanOfficer}
                currentYear={currentYear}
                currentMonth={currentMonth}
                dataFor={dataFor}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
            <Row>
              <RegionalManagerSalesComparisonCard
                currentLoanOfficer={currentLoanOfficer}
                dataFor={dataFor}
                regionData={regionData}
                branchData={branchData}
              />
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default RegionalManagerSalesTimeline;
