import CorrespondentAdminFastFacts from "../fast-facts/correspondent-admin/pages/CorrespondentAdminFastFacts";
import CorrespondentAdminEncompassMetrics from "../sales/correspondent-admin/pages/CorrespondentAdminEncompassMetrics";
import CorrespondentAdminSalesTimeline from "../sales/correspondent-admin/pages/CorrespondentAdminSalesTimeline";

const correspondentAdminRoutes = [
  {
    path: "/fast-facts",
    name: "Fast Facts",
    icon: "now-ui-icons design_app",
    component: CorrespondentAdminFastFacts,
    layout: "/correspondent-admin",
  },
  {
    collapse: true,
    name: "Sales",
    icon: "now-ui-icons business_chart-bar-32",
    state: "salesOpen",
    views: [
      {
        path: "/sales/sales-timeline",
        name: "Sales Timeline",
        mini: "ST",
        component: CorrespondentAdminSalesTimeline,
        layout: "/correspondent-admin",
      },
      {
        path: "/sales/encompass-metrics",
        name: "Encompass Metrics",
        mini: "EM",
        component: CorrespondentAdminEncompassMetrics,
        layout: "/correspondent-admin",
      },
    ],
  },
];

export default correspondentAdminRoutes;
