import React from "react";
import {Row, Col, Card, CardHeader, CardBody} from "reactstrap";

const RegionalManagerMyPipelineHighlights = ({regionData}) => {
  return (
    <Col xs={12} md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`My Region-Wide My Pipeline`}</CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {regionData.encompassMetrics.totalLoansInMyPipeline}
                  </h3>
                  <h6 className="stats-title">Total Loans In My Pipeline</h6>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {
                      regionData.encompassMetrics.loansExpectedToCloseThisMonth
                        .count
                    }
                  </h3>
                  <h6 className="stats-title">
                    Loans Estimated to fund this month
                  </h6>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {regionData.encompassMetrics.loansExpectedToCloseThisMonth.amount.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                    )}
                  </h3>
                  <h6 className="stats-title">
                    Loan Amount Remaining Estimated to fund this month
                  </h6>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {
                      regionData.encompassMetrics.loansExpectedToCloseNextMonth
                        .count
                    }
                  </h3>
                  <h6 className="stats-title">
                    Loans Estimated to fund Next month
                  </h6>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {regionData.encompassMetrics.loansExpectedToCloseNextMonth.amount.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      },
                    )}
                  </h3>
                  <h6 className="stats-title">
                    Loan Amount Remaining Estimated to fund Next month
                  </h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegionalManagerMyPipelineHighlights;
