import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

import ReactTable from "../../../components/NowUI/ReactTable/ReactTable";
import Loading from "../../../components/NowUI/Loading/Loading";

const RegionalManagerMyRegionRankings = ({ branchData }) => {
  const [timeframe, setTimeframe] = useState("ytd");

  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getRankings?timeframe=${timeframe}`
        );

        const json = await response.json();
        if (timeframe === "ytd") {
          const rankingsWithIndex = json.ytdSalesTotals.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "previousMonth") {
          const rankingsWithIndex = json.lastMonthSalesTotals.map(
            (lo, index) => ({
              ...lo,
              companyRankIndex: index,
            })
          );
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "currentMonth") {
          const rankingsWithIndex = json.currentMonthSalesTotals.map(
            (lo, index) => ({
              ...lo,
              companyRankIndex: index,
            })
          );
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "2023") {
          const rankingsWithIndex = json.salesTotals2023.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "2022") {
          const rankingsWithIndex = json.salesTotals2022.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "2021") {
          const rankingsWithIndex = json.salesTotals2021.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "2020") {
          const rankingsWithIndex = json.salesTotals2020.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        } else if (timeframe === "2019") {
          const rankingsWithIndex = json.salesTotals2019.map((lo, index) => ({
            ...lo,
            companyRankIndex: index,
          }));
          const myRegionMemberRankings = rankingsWithIndex
            .filter((lo) =>
              branchData.branchMembers.some((member) => member.name === lo.name)
            )
            .filter((lo) => lo.amount !== 0 || lo.leftEssex === false);
          setRankings(myRegionMemberRankings);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRankings();
  }, [timeframe, branchData.branchMembers]);

  return (
    <Col md="12">
      <Card>
        <CardHeader className="card-category">
          {`My Branch Production Rankings`}
        </CardHeader>
        <CardBody>
          <Nav
            pills
            className="nav-pills-danger"
            style={{ margin: "4vh 0 ", display: "flex", gap: "20px" }}
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "2019",
                })}
                onClick={() => {
                  setTimeframe("2019");
                }}
              >
                2019
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "2020",
                })}
                onClick={() => {
                  setTimeframe("2020");
                }}
              >
                2020
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "2021",
                })}
                onClick={() => {
                  setTimeframe("2021");
                }}
              >
                2021
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "2022",
                })}
                onClick={() => {
                  setTimeframe("2022");
                }}
              >
                2022
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "2023",
                })}
                onClick={() => {
                  setTimeframe("2023");
                }}
              >
                2023
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "previousMonth",
                })}
                onClick={() => {
                  setTimeframe("previousMonth");
                }}
              >
                Previous Month
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "currentMonth",
                })}
                onClick={() => {
                  setTimeframe("currentMonth");
                }}
              >
                Current Month
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: timeframe === "ytd",
                })}
                onClick={() => {
                  setTimeframe("ytd");
                }}
              >
                YTD
              </NavLink>
            </NavItem>
          </Nav>

          {loading ? (
            <Loading />
          ) : (
            <ReactTable
              defaultSortColumn="regionRank"
              data={rankings.map((loanOfficer, index) => {
                return {
                  regionRank: index + 1,
                  companyRank: loanOfficer.companyRankIndex + 1,
                  name: (
                    <span
                      style={{
                        textDecoration: loanOfficer.leftEssex
                          ? "line-through"
                          : "",
                      }}
                    >
                      {loanOfficer.name}
                    </span>
                  ),
                  salesAmount: loanOfficer.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                  salesCount: loanOfficer.count,
                };
              })}
              columns={[
                {
                  Header: "Branch Rank",
                  accessor: "regionRank",
                  disableFilters: true,
                },

                {
                  Header: "Company Rank",
                  accessor: "companyRank",
                  disableFilters: true,
                },
                {
                  Header: "Name",
                  accessor: "name",
                  // disableSortBy: true,
                  // canSort: false
                },
                {
                  Header: "Production Amount",
                  accessor: "salesAmount",
                  disableFilters: true,
                  // canSort: false,
                },
                {
                  Header: "Loan Count",
                  accessor: "salesCount",
                  disableFilters: true,
                },
              ]}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegionalManagerMyRegionRankings;
