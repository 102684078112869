import React from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

const RegionalManagerBrokeredOutLoans = ({
  currentLoanOfficer,
  currentMonth,
  currentYear,
  dataFor,
  branchData,
}) => {
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let currentSalesObject = {
    purchase: {amount: 0, count: 0},
    noCashRefinance: {amount: 0, count: 0},
    cashRefinance: {amount: 0, count: 0},
    otherPurpose: {amount: 0, count: 0},
    conventional: {amount: 0, count: 0},
    fha: {amount: 0, count: 0},
    va: {amount: 0, count: 0},
    usda: {amount: 0, count: 0},
    reverse: {amount: 0, count: 0},
    brokered: {amount: 0, count: 0},
    otherType: {amount: 0, count: 0},
    total: {amount: 0, count: 0},
  };
  let dataForLabel = "";
  switch (dataFor) {
    case "Branch": {
      currentSalesObject =
        currentMonth === 100
          ? branchData.branchSales.find(
              (element) => element.year === currentYear,
            ).annualSalesTotals
          : branchData.branchSales.find(
              (element) => element.year === currentYear,
            ).monthlySalesTotals[currentMonth];
      dataForLabel = "My Branch";
      break;
    }
    case "Individual": {
      if (
        currentLoanOfficer.encompassMetrics.encompassSales.find(
          (element) => element.year === currentYear,
        ) !== undefined
      ) {
        currentSalesObject =
          currentMonth === 100
            ? currentLoanOfficer.encompassMetrics.encompassSales.find(
                (element) => element.year === currentYear,
              ).annualSalesTotals
            : currentLoanOfficer.encompassMetrics.encompassSales.find(
                (element) => element.year === currentYear,
              ).monthlySalesTotals[currentMonth];
      }
      dataForLabel = currentLoanOfficer.name.endsWith("s")
        ? `${currentLoanOfficer.name}'`
        : `${currentLoanOfficer.name}'s`;
      break;
    }
    default:
  }

  return (
    <Col lg={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">
          {dataForLabel}{" "}
          {currentMonth === 100
            ? `${currentYear} Brokered Loans`
            : `${MONTHS[currentMonth]} ${currentYear} Brokered Loans`}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {currentSalesObject.brokered.count}
                  </h3>
                  <h6 className="stats-title">Brokered Out Loans</h6>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {Number.parseFloat(
                      currentSalesObject.brokered.amount,
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">Brokered Out Loan Amount</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegionalManagerBrokeredOutLoans;
