import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const RegionalManagerMyRegionRoster = ({
  regionData,
  myRegionModalOpen,
  changeCurrentLoanOfficer,
  toggleMyRegionModal
}) => {
  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item]
      }),
      {}
    );

  const groupedRegionMembers = groupBy(regionData.regionMembers, "branchName");

  const listMyRegionLoanOfficers = () => {
    return Object.keys(groupedRegionMembers)
      .sort()
      .map((group) => {
        return (
          <>
            <div>
              <h6>{group}</h6>
              {groupedRegionMembers[group].map((loanOfficer) => (
                <div>
                  {loanOfficer.authTier !== "Regional Manager" ? (
                    <p
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        margin: "5px 0"
                      }}
                      onClick={() =>
                        changeCurrentLoanOfficer(loanOfficer.email)
                      }
                    >
                      {loanOfficer.name}
                    </p>
                  ) : (
                    <p>{loanOfficer.name}</p>
                  )}
                </div>
              ))}
            </div>
          </>
        );
      });
  };

  return (
    <Modal isOpen={myRegionModalOpen} toggle={toggleMyRegionModal} size="lg">
      <ModalHeader toggle={toggleMyRegionModal}>My Region</ModalHeader>
      <ModalBody>
        <div>Select a Branch Manager or Loan Officer to impersonate.</div>
        <hr />
        <div style={{ display: "flex", gap: "60px", flexWrap: "wrap" }}>
          {listMyRegionLoanOfficers()}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RegionalManagerMyRegionRoster;
