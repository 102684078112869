import React, {useState, useEffect} from "react";

import {InputGroup, InputGroupAddon, InputGroupText, Input} from "reactstrap";
import SearchResultsList from "../CorrespondentAdminSearchResultsList/CorrespondentAdminSearchResultsList";
import SyncLoader from "react-spinners/SyncLoader";

import "./Searchbar.css";

const CorrespondentAdminSearchbar = ({changeAccountExecutive}) => {
  const [accountExecutives, setAccountExecutives] = useState([]);
  const [matchedAccountExecutives, setMatchedAccountExecutives] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [loading, setLoading] = useState(true);

  const updateSearchTerm = (name) => {
    setSearchTerm(name);

    setMatchedAccountExecutives(() => {
      if (name.length === 0) {
        return [];
      }

      return accountExecutives.filter((accountExecutives) => {
        if (accountExecutives.name.split(" ").length > 2) {
          return (
            accountExecutives.name
              .toLowerCase()
              .startsWith(name.toLowerCase()) ||
            accountExecutives.name
              .toLowerCase()
              .split(" ")[1]
              .startsWith(name.toLowerCase()) ||
            accountExecutives.name
              .toLowerCase()
              .split(" ")[2]
              .startsWith(name.toLowerCase())
          );
        }
        return (
          accountExecutives.name.toLowerCase().startsWith(name.toLowerCase()) ||
          accountExecutives.name
            .toLowerCase()
            .split(" ")[1]
            .startsWith(name.toLowerCase())
        );
      });
    });
  };

  const fetchAllAccountExecutives = async () => {
    setLoading(true);
    const response = await fetch(
      `https://us-central1-essexdashboard.cloudfunctions.net/getAllAccountExecutives `,
    );

    const json = await response.json();
    setAccountExecutives(json);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllAccountExecutives();
  }, []);

  useEffect(() => {
    if (searchTerm.length < 1) {
      setCurrentIndex(-1);
    }
  }, [searchTerm.length]);

  useEffect(() => {
    if (currentIndex >= 0 && matchedAccountExecutives.length > 0) {
    }
  }, [currentIndex, matchedAccountExecutives]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <InputGroup className="no-border">
        <Input
          disabled={loading}
          className="mobile"
          placeholder={
            loading ? "Loading Account Executives" : "View Account Executive"
          }
          value={searchTerm}
          onChange={(e) => {
            updateSearchTerm(e.target.value);
          }}
          onSubmit={(e) => e.preventDefault()}
          onKeyDown={(e) => {
            if (
              e.key === "ArrowDown" ||
              e.key === "ArrowUp" ||
              e.key === "Enter"
            ) {
              e.preventDefault();
            }
            if (
              e.key === "ArrowDown" &&
              currentIndex < matchedAccountExecutives.length - 1
            ) {
              setCurrentIndex((prevState) => prevState + 1);
            } else if (e.key === "ArrowUp" && currentIndex > 0) {
              setCurrentIndex((prevState) => prevState - 1);
            } else if (
              e.key === "Enter" &&
              matchedAccountExecutives[currentIndex]?.name !== undefined
            ) {
              changeAccountExecutive(
                matchedAccountExecutives[currentIndex].name,
              );
            }
          }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            {loading ? (
              <SyncLoader size={5} color="#fff" margin={2} />
            ) : (
              <i className="now-ui-icons ui-1_zoom-bold" />
            )}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <SearchResultsList
        matchedAccountExecutives={matchedAccountExecutives}
        changeAccountExecutive={changeAccountExecutive}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        currentIndex={currentIndex}
      />
    </form>
  );
};

export default CorrespondentAdminSearchbar;
