import React, {useState} from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupText,
  Col,
  CardHeader,
} from "reactstrap";

const RegionalManagerAdditionalCommissionsInput = ({
  loanOfficer,
  additionalCommissions,
  setAdditionalCommissions,
}) => {
  const [commissionPercent, setCommissionPercent] = useState(0);
  const [include, setInclude] = useState(true);

  const handlePercentChange = (value, name) => {
    const newArr = [...additionalCommissions];
    const updateIndex = newArr.findIndex(
      (loanOfficer) => loanOfficer.name === name,
    );
    newArr[updateIndex].commissionPercent = Number.parseFloat(value);
    setAdditionalCommissions(newArr);
    setCommissionPercent(value);
  };

  const styles = {color: "#2c2c2c"};

  return (
    <Col md="3">
      <Card>
        <CardHeader>
          <h6 style={styles}>{loanOfficer.name}</h6>
          <p style={styles}>
            {`Previous Year Total: ${loanOfficer.prevYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            )}`}
          </p>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <InputGroup style={{marginLeft: "20px"}}>
              <Input
                type="checkbox"
                value={include}
                onChange={() => setInclude(!include)}
              />
              <Label check>Include in my commission goal</Label>
            </InputGroup>
            <Label for="percent-change">Commission Percent</Label>
            <InputGroup style={{width: "80%"}}>
              <Input
                disabled={include}
                type="number"
                step={0.01}
                style={{
                  borderRadius: "5px",
                }}
                id="percent-change"
                placeholder=""
                value={commissionPercent}
                onChange={(e) => {
                  handlePercentChange(e.target.value, loanOfficer.name);
                }}
              />
              <InputGroupText>%</InputGroupText>
            </InputGroup>
          </FormGroup>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegionalManagerAdditionalCommissionsInput;
