import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const AdminCurrentMonthSalesHighlights = ({currentYearData}) => {
  const history = useHistory();

  const monthlySalesTotal = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals[new Date().getMonth()]
        .total.amount
    : 0;
  const monthlySalesCount = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals[new Date().getMonth()]
        .total.count
    : 0;

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][new Date().getMonth()];

  return (
    <Col xs={12} md={6}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${month} Sales`}</CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {monthlySalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${month} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="6">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{monthlySalesCount}</h3>
                  <h6 className="stats-title">{`${month} Loan Count`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/retail-admin/sales/encompass-metrics");
            }}
          >
            See More Sales
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default AdminCurrentMonthSalesHighlights;
