import React from "react";
import {Line} from "react-chartjs-2";
import {useHistory} from "react-router-dom";

import PanelHeader from "../../../components/NowUI/PanelHeader/PanelHeader";

const CorrespondentAdminFastFactsChart = ({currentYearData}) => {
  const history = useHistory();

  const annualSalesAmounts = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals.map(
        (month) => month.total.amount,
      )
    : [];

  const annualSalesCounts = currentYearData.encompassSales
    ? currentYearData.encompassSales.monthlySalesTotals.map(
        (element) => element.total.count,
      )
    : [];

  const correspondentAdminFastFactsChart = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");
      var chartColor = "#FFFFFF";
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#80b6f4");
      gradientStroke.addColorStop(1, chartColor);
      var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

      return {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        datasets: [
          {
            label: "Total Sales: ",
            borderColor: chartColor,
            pointBorderColor: chartColor,
            pointBackgroundColor: "#2c2c2c",
            pointHoverBackgroundColor: "#2c2c2c",
            pointHoverBorderColor: chartColor,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: annualSalesAmounts,
          },
        ],
      };
    },
    options: {
      onClick: (event, element) => {
        if (element.length > 0) {
          const index = element[0]._index;
          history.push({
            pathname: "/correspondent-admin/sales/sales-timeline",
            state: {redirectMonthIndex: index},
          });
        }
      },

      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = "pointer";
        else e.target.style.cursor = "default";
      },

      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 0,
          bottom: 0,
        },
      },
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: "#fff",
        titleFontColor: "#333",
        titleFontSize: 16,
        bodyFontColor: "#666",
        bodyFontSize: 16,
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.datasets[tooltipItem.datasetIndex].label;
            const dollarValue = parseInt(tooltipItem.value).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              },
            );
            const loanCount = annualSalesCounts[tooltipItem.index];

            return `${label} ${dollarValue}
          Count: ${loanCount}`;
          },
        },
      },
      legend: {
        position: "bottom",
        fillStyle: "#FFF",
        display: false,
      },
      title: {
        display: true,
        text: "Company Wide Correspondent Production",
        fontStyle: "bold",
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 4,
              padding: 10,
              callback: (value, index, values) => {
                return value.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                });
              },
            },
            gridLines: {
              drawTicks: true,
              drawBorder: false,
              display: true,
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: "transparent",
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              color: "rgba(255,255,255,0.1)",
            },
            ticks: {
              padding: 10,
              fontColor: "rgba(255,255,255,0.4)",
              fontStyle: "bold",
            },
          },
        ],
      },
    },
  };

  return (
    <PanelHeader
      size="lg"
      content={
        <Line
          key={currentYearData}
          data={correspondentAdminFastFactsChart.data}
          options={correspondentAdminFastFactsChart.options}
        />
      }
    />
  );
};

export default CorrespondentAdminFastFactsChart;
