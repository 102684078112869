import React from "react";
import { Card, CardBody, Col } from "reactstrap";

import DefaultHeadshot from "../../../assets/images/default_headshot.png";

const LoanOfficerAdditionalCommissionCard = ({ loanOfficer }) => {
  return (
    <Col md="4">
      <Card>
        <CardBody style={{ display: "flex" }}>
          <img
            alt={`${loanOfficer.name} headshot`}
            style={{
              maxWidth: "125px",
              height: "125px",
              borderRadius: "50%",
              objectFit: "cover"
            }}
            src={
              loanOfficer.headshotURL === ""
                ? DefaultHeadshot
                : loanOfficer.headshotURL
            }
          />
          <div style={{ margin: "10px 0 0 40px" }}>
            <h6>{loanOfficer.name}</h6>
            <p>{`YTD Production: ${loanOfficer.thisYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }
            )}`}</p>
            <p>{`My Commission: ${loanOfficer.thisYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }
            )} x ${loanOfficer.commissionPercent}% = ${Number.parseInt(
              (loanOfficer.commissionPercent / 100) *
                loanOfficer.thisYearSalesTotal
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}`}</p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LoanOfficerAdditionalCommissionCard;
