import BranchManagerFastFacts from "../fast-facts/branch-manager/pages/BranchManagerFastFacts";
import BranchManagerSalesTimeline from "../sales/branch-manager/pages/BranchManagerSalesTimeline";
import BranchManagerSalesGoals from "../sales/branch-manager/pages/BranchManagerSalesGoals";
import BranchManagerEncompassMetrics from "../sales/branch-manager/pages/BranchManagerEncompassMetrics";
import BranchManagerMyBranch from "../sales/branch-manager/pages/BranchManagerMyBranch";
// import BranchManagerReportCard from "../analytics/branch-manager/pages/BranchManagerReportCard";

let branchManagerRoutes = [
  {
    path: "/fast-facts",
    name: "Fast Facts",
    icon: "now-ui-icons design_app",
    component: BranchManagerFastFacts,
    layout: "/branch-manager",
  },
  {
    collapse: true,
    name: "Sales",
    icon: "now-ui-icons business_chart-bar-32",
    state: "salesOpen",
    views: [
      {
        path: "/sales/sales-timeline",
        name: "Sales Timeline",
        mini: "ST",
        component: BranchManagerSalesTimeline,
        layout: "/branch-manager",
      },
      {
        path: "/sales/my-branch",
        name: "My Branch",
        mini: "MB",
        component: BranchManagerMyBranch,
        layout: "/branch-manager",
      },
      {
        path: "/sales/encompass-metrics",
        name: "Encompass Metrics",
        mini: "EM",
        component: BranchManagerEncompassMetrics,
        layout: "/branch-manager",
        individualOnly: true,
      },
      {
        path: "/sales/sales-goals",
        name: "Sales Goals",
        mini: "SG",
        component: BranchManagerSalesGoals,
        layout: "/branch-manager",
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Analytics",
  //   icon: "now-ui-icons objects_globe",
  //   state: "analyticsOpen",
  //   views: [
  //     {
  //       path: "/analytics/report-card",
  //       name: "Report Card",
  //       mini: "RC",
  //       component: BranchManagerReportCard,
  //       layout: "/branch-manager",
  //     },
  //   ],
  // },
];

export default branchManagerRoutes;
