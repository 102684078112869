import React, {useState} from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import classnames from "classnames";
import AccountExecutiveMonthlyLoanPurposeCard from "./AccountExecutiveMonthlyLoanPurposeCard";
import AccountExecutiveMonthlyLoanTypeCard from "./AccountExecutiveMonthlyLoanTypeCard";

const AccountExecutiveSalesComparisonCard = ({currentAccountExecutive}) => {
  const yearOptions = currentAccountExecutive.encompassSales
    .filter((salesObject) => salesObject.year !== 0)
    .map((salesObject) => {
      return {value: salesObject.year, label: salesObject.year.toString()};
    });

  const mostRecentYear = new Date().getFullYear();

  const mostRecentMonth = new Date().getMonth();

  const secondMostRecentYear =
    mostRecentMonth === 0 ? mostRecentYear - 1 : mostRecentYear;

  const secondMostRecentMonth =
    mostRecentMonth === 0 ? 11 : mostRecentMonth - 1;

  const [leftPeriod, setLeftPeriod] = useState({
    currentYear: secondMostRecentYear,
    currentMonth: secondMostRecentMonth,
  });
  const [rightPeriod, setRightPeriod] = useState({
    currentYear: mostRecentYear,
    currentMonth: mostRecentMonth,
  });
  const [activeTab, setActiveTab] = useState("Loan Types");

  let leftMonthOptions =
    leftPeriod.currentYear === new Date().getFullYear()
      ? [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ].slice(0, new Date().getMonth() + 2)
      : [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ];

  let rightMonthOptions =
    rightPeriod.currentYear === new Date().getFullYear()
      ? [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ].slice(0, new Date().getMonth() + 2)
      : [
          {value: 100, label: "YTD"},
          {value: 0, label: "January"},
          {value: 1, label: "February"},
          {value: 2, label: "March"},
          {value: 3, label: "April"},
          {value: 4, label: "May"},
          {value: 5, label: "June"},
          {value: 6, label: "July"},
          {value: 7, label: "August"},
          {value: 8, label: "September"},
          {value: 9, label: "October"},
          {value: 10, label: "November"},
          {value: 11, label: "December"},
        ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changePeriod = (value, name) => {
    if (name === "leftCurrentMonth") {
      setLeftPeriod((prevState) => ({
        ...prevState,
        currentMonth: parseInt(value),
      }));
    } else if (name === "rightCurrentMonth") {
      setRightPeriod((prevState) => ({
        ...prevState,
        currentMonth: parseInt(value),
      }));
    } else if (name === "leftCurrentYear") {
      setLeftPeriod({
        currentYear: parseInt(value),
        currentMonth: 100,
      });
    } else if (name === "rightCurrentYear") {
      setRightPeriod({
        currentYear: parseInt(value),
        currentMonth: 100,
      });
    }
  };

  const renderYearOptions = () => {
    return yearOptions.map((year) => (
      <option value={Number.parseInt(year.value)}>{year.label}</option>
    ));
  };

  const renderLeftMonthOptions = () => {
    return leftMonthOptions.map((month) => (
      <option value={Number.parseInt(month.value)}>{month.label}</option>
    ));
  };

  const renderRightMonthOptions = () => {
    return rightMonthOptions.map((month) => (
      <option value={Number.parseInt(month.value)}>{month.label}</option>
    ));
  };

  return (
    <Col lg="12">
      <Card>
        <CardHeader className="card-category">
          <CardTitle>Compare Sales Periods</CardTitle>
        </CardHeader>
        <Row style={{margin: "30px 0"}}>
          <Col md={{size: 4, offset: 0}} style={{paddingLeft: "30px"}}>
            <Label for="leftCurrentMonth">Left Month</Label>

            <Input
              className="primary"
              name="leftCurrentMonth"
              type="select"
              value={leftPeriod.currentMonth}
              onChange={(e) => changePeriod(e.target.value, e.target.name)}
            >
              {renderLeftMonthOptions()}
            </Input>

            <Label for="leftCurrentYear" style={{marginTop: "10px"}}>
              Left Year
            </Label>

            <Input
              type="select"
              name="leftCurrentYear"
              value={leftPeriod.currentYear}
              onChange={(e) => changePeriod(e.target.value, e.target.name)}
            >
              {renderYearOptions()}
            </Input>
          </Col>

          <Col md={{size: 4, offset: 2}}>
            <Label for="rightCurrentMonth">Right Month</Label>
            <Input
              className="primary"
              name="rightCurrentMonth"
              type="select"
              value={rightPeriod.currentMonth}
              onChange={(e) => changePeriod(e.target.value, e.target.name)}
            >
              {renderRightMonthOptions()}
            </Input>

            <Label for="rightCurrentYear" style={{marginTop: "10px"}}>
              Right Year
            </Label>
            <Input
              type="select"
              name="rightCurrentYear"
              value={rightPeriod.currentYear}
              onChange={(e) => changePeriod(e.target.value, e.target.name)}
            >
              {renderYearOptions()}
            </Input>
          </Col>
        </Row>

        <CardBody>
          <Nav pills className="nav-pills-danger" style={{marginBottom: "4vh"}}>
            <NavItem>
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: activeTab === "Loan Purposes",
                })}
                onClick={() => {
                  toggle("Loan Purposes");
                }}
              >
                Loan Purposes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({active: activeTab === "Loan Types"})}
                onClick={() => {
                  toggle("Loan Types");
                }}
              >
                Loan Types
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="Loan Purposes">
              <Row>
                <AccountExecutiveMonthlyLoanPurposeCard
                  currentAccountExecutive={currentAccountExecutive}
                  currentYear={leftPeriod.currentYear}
                  currentMonth={leftPeriod.currentMonth}
                />
                <AccountExecutiveMonthlyLoanPurposeCard
                  currentAccountExecutive={currentAccountExecutive}
                  currentYear={rightPeriod.currentYear}
                  currentMonth={rightPeriod.currentMonth}
                />
              </Row>
            </TabPane>
            <TabPane tabId="Loan Types">
              <Row>
                <AccountExecutiveMonthlyLoanTypeCard
                  currentAccountExecutive={currentAccountExecutive}
                  currentYear={leftPeriod.currentYear}
                  currentMonth={leftPeriod.currentMonth}
                />
                <AccountExecutiveMonthlyLoanTypeCard
                  currentAccountExecutive={currentAccountExecutive}
                  currentYear={rightPeriod.currentYear}
                  currentMonth={rightPeriod.currentMonth}
                />
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AccountExecutiveSalesComparisonCard;
