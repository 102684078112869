import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Row,
  CardFooter,
  Button,
} from "reactstrap";
import {useHistory} from "react-router-dom";

const LoanOfficerPreviousMonthSalesHighlights = ({currentLoanOfficer}) => {
  const history = useHistory();
  const previousMonthIndex =
    new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1;

  let currentYearSales =
    currentLoanOfficer.encompassMetrics.encompassSales.find((salesObject) => {
      if (previousMonthIndex === 11) {
        return salesObject.year === new Date().getFullYear() - 1;
      } else {
        return salesObject.year === new Date().getFullYear();
      }
    });

  if (!currentYearSales) {
    currentYearSales = {
      year: new Date().getFullYear() - 1,
      annualSalesTotals: {
        purchase: {amount: 0, count: 0},
        noCashRefinance: {amount: 0, count: 0},
        cashRefinance: {amount: 0, count: 0},
        otherPurpose: {amount: 0, count: 0},
        conventional: {amount: 0, count: 0},
        fha: {amount: 0, count: 0},
        va: {amount: 0, count: 0},
        usda: {amount: 0, count: 0},
        reverse: {amount: 0, count: 0},
        brokered: {amount: 0, count: 0},
        otherType: {amount: 0, count: 0},
        total: {amount: 0, count: 0},
      },
      monthlySalesTotals: [
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
        {
          purchase: {amount: 0, count: 0},
          noCashRefinance: {amount: 0, count: 0},
          cashRefinance: {amount: 0, count: 0},
          otherPurpose: {amount: 0, count: 0},
          conventional: {amount: 0, count: 0},
          fha: {amount: 0, count: 0},
          va: {amount: 0, count: 0},
          usda: {amount: 0, count: 0},
          reverse: {amount: 0, count: 0},
          brokered: {amount: 0, count: 0},
          otherType: {amount: 0, count: 0},
          total: {amount: 0, count: 0},
        },
      ],
    };
  }

  const previousMonthSales =
    currentYearSales.monthlySalesTotals[previousMonthIndex];

  const monthlySalesTotal = previousMonthSales.total.amount;
  const monthlySalesCount = previousMonthSales.total.count;

  const previousMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][previousMonthIndex];

  const toOrdinal = (num) => {
    const j = num % 10,
      k = num % 100;
    if (j === 1 && k !== 11) {
      return num + "st";
    }
    if (j === 2 && k !== 12) {
      return num + "nd";
    }
    if (j === 3 && k !== 13) {
      return num + "rd";
    }
    return num + "th";
  };

  return (
    <Col xs={12} md={12}>
      <Card className="card-stats card-raised">
        <CardHeader className="card-category">{`${
          currentLoanOfficer.name.endsWith("s")
            ? `${currentLoanOfficer.name}'`
            : `${currentLoanOfficer.name}'s`
        } ${previousMonth} Sales`}</CardHeader>

        <CardBody>
          <Row>
            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {monthlySalesTotal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </h3>
                  <h6 className="stats-title">{`${previousMonth} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">{monthlySalesCount}</h3>
                  <h6 className="stats-title">{`${previousMonth} Sales Total`}</h6>
                </div>
              </div>
            </Col>

            <Col md="4">
              <div className="statistics">
                <div className="info">
                  <h3 className="info-title">
                    {currentLoanOfficer.rankings
                      ? toOrdinal(currentLoanOfficer.rankings.lastMonth)
                      : "-"}
                  </h3>
                  <h6 className="stats-title">{`${previousMonth} Production Ranking`}</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter
          style={{width: "100%", display: "flex", justifyContent: "flex-end"}}
        >
          <Button
            className="redirect-button"
            onClick={() => {
              history.push("/loan-officer/sales/encompass-metrics");
            }}
          >
            See My Pipeline
          </Button>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default LoanOfficerPreviousMonthSalesHighlights;
