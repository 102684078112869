import React, {useState, useEffect} from "react";
import {Row} from "reactstrap";

import LoanOfficerAdditionalCommissionsInput from "./LoanOfficerAdditionalCommissionsInput";
import Loading from "../../../components/NowUI/Loading/Loading";

const LoanOfficerAdditionalCommissions = ({
  additionalCommissions,
  setAdditionalCommissions,
  selfGeneratedCommissionPercent,
  prevYearsAnnualSalesTotals,
}) => {
  const [loading, setLoading] = useState(true);

  const renderAdditionalInputs = () => {
    return additionalCommissions.map((loanOfficer) => {
      return (
        <LoanOfficerAdditionalCommissionsInput
          key={loanOfficer.name}
          loanOfficer={loanOfficer}
          setAdditionalCommissions={setAdditionalCommissions}
          additionalCommissions={additionalCommissions}
        />
      );
    });
  };

  const renderPrevYearCommissionEstimate = () => {
    return additionalCommissions.map((additionalCommission) => {
      if (additionalCommission.commissionPercent > 0) {
        return (
          <div style={{marginBottom: "2vh"}} key={additionalCommission.email}>
            <h6>{`${additionalCommission.name}`}</h6>
            {`${additionalCommission.prevYearSalesTotal.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            )} x ${additionalCommission.commissionPercent}% = ${Number.parseInt(
              (additionalCommission.commissionPercent / 100) *
                additionalCommission.prevYearSalesTotal,
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    const fetchBranchMembersSales = async () => {
      setLoading(true);
      const emails = additionalCommissions
        .map((loanOfficer) => loanOfficer.email)
        .join();
      try {
        const response = await fetch(
          `https://us-central1-essexdashboard.cloudfunctions.net/getLoanOfficerData?emails=${emails}&year=${
            new Date().getFullYear() - 1
          }`,
        );
        const json = await response.json();
        const newArr = additionalCommissions.map((loanOfficer) => {
          const prevYearSalesObject = json.find(
            (salesObj) => salesObj.email === loanOfficer.email,
          );
          const prevYearSalesTotal = prevYearSalesObject.hasOwnProperty("sales")
            ? prevYearSalesObject.sales.annualSalesTotals.total.amount
            : 0;

          return {...loanOfficer, prevYearSalesTotal};
        });
        setAdditionalCommissions(newArr);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBranchMembersSales();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <h5 className="stats-title" style={{margin: "8vh 0 2vh"}}>
            Additional Commissions
          </h5>
          <Row>{renderAdditionalInputs()}</Row>

          <div
            style={{
              margin: "0 0 0 auto",
              textAlign: "end",
            }}
          >
            <h5>Previous Year Estimated Commission</h5>
            <h6>Commission from my loans</h6>
            <div style={{marginBottom: "2vh"}}>{`${prevYearsAnnualSalesTotals[
              prevYearsAnnualSalesTotals.length - 1
            ].amount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })} x ${selfGeneratedCommissionPercent}% = ${Number.parseInt(
              (selfGeneratedCommissionPercent / 100) *
                prevYearsAnnualSalesTotals[
                  prevYearsAnnualSalesTotals.length - 1
                ].amount,
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</div>
            {renderPrevYearCommissionEstimate()}
            <h5>{`Total:
            ${(
              additionalCommissions.reduce((prevValue, currentValue) => {
                return (
                  prevValue +
                  Number.parseInt(
                    (currentValue.commissionPercent / 100) *
                      currentValue.prevYearSalesTotal,
                  )
                );
              }, 0) +
              Number.parseInt(
                (selfGeneratedCommissionPercent / 100) *
                  prevYearsAnnualSalesTotals[
                    prevYearsAnnualSalesTotals.length - 1
                  ].amount,
              )
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`}</h5>
          </div>
        </>
      )}
    </>
  );
};

export default LoanOfficerAdditionalCommissions;
