import React from "react";
import { Row } from "reactstrap";

import LoanOfficerSalesGoalsHighlights from "../../loan-officer/components/LoanOfficerSalesGoalsHighlights";
import LoanOfficerSalesGoalCommissionBreakdown from "../../loan-officer/components/LoanOfficerSalesGoalCommissionBreakdown";
import RegionalManagerSalesGoalAdditionalCommissionsTracker from "./RegionalManagerSalesGoalAdditionalCommissionsTracker";

const RegionalManagerSalesGoalTracker = ({ currentLoanOfficer }) => {
  return (
    <>
      <Row>
        <LoanOfficerSalesGoalsHighlights
          currentLoanOfficer={currentLoanOfficer}
        />
      </Row>
      <Row>
        <LoanOfficerSalesGoalCommissionBreakdown
          currentLoanOfficer={currentLoanOfficer}
        />
      </Row>
      {currentLoanOfficer.salesGoals[new Date().getFullYear()]
        .additionalCommissions.length > 0 && (
        <Row>
          <RegionalManagerSalesGoalAdditionalCommissionsTracker
            currentLoanOfficer={currentLoanOfficer}
          />
        </Row>
      )}
    </>
  );
};

export default RegionalManagerSalesGoalTracker;
